import { useNavigate } from "react-router-dom";
import { Layout } from "../../components/Layout";
import { PrimaryButton } from "../../components/PrimaryButton";
import { AuthenticatedState, useAuthStore } from "~/stores/authStore";

export function CreateTicketSuccess() {
  // for now only TSG Global users can access tickets
  const user = useAuthStore(
    (store) => (store.state as AuthenticatedState).user
  );
  const navigate = useNavigate();

  if (!user.email.includes("@tsgglobal.com")) {
    navigate("/", {
      replace: true,
    });
  }

  return (
    <div className="Create ticket">
      <Layout>
        <div className="max-w-xl mx-auto mt-12">
          <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
            Ticket submitted
          </h2>
          <p className="mt-4 text-lg leading-6 text-gray-500">
            Your ticket has been submitted, you will get an email confirmation
            and a response from our support team soon.
          </p>

          <PrimaryButton className="mt-6" onClick={() => navigate("/")}>
            Back to home
          </PrimaryButton>
        </div>
      </Layout>
    </div>
  );
}
