// Types for dropdown items, for now they're typed in this separate file for easier reusing.
export type DropdownItem = {
  label: string;
  value: string;
};

export const trueFalseDropdownItems = [
  {
    value: "true",
    label: "True",
  },
  {
    value: "false",
    label: "False",
  },
] as const;

export type TrueFalseDropdownItemType = typeof trueFalseDropdownItems[number];
export type TrueFalseDropdownItemValueType =
  typeof trueFalseDropdownItems[number]["value"];

export const yesNoDropdownItems = [
  {
    value: "true",
    label: "True",
  },
  {
    value: "false",
    label: "False",
  },
] as const;

export type YesNoDropdownItemType = typeof yesNoDropdownItems[number];
export type YesNoDropdownItemValueType =
  typeof yesNoDropdownItems[number]["value"];

export const yesNoUnsureDropdownItems = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
  {
    value: "unsure",
    label: "Unsure",
  },
] as const;

export type YesNoUnsureDropdownItemType =
  typeof yesNoUnsureDropdownItems[number];

export type YesNoUnsureDropdownItemValueType =
  typeof yesNoUnsureDropdownItems[number]["value"];

export const anotherMessagingServiceTrafficItems = [
  {
    value: "new",
    label: "No, this is new traffic.",
  },
  {
    value: "short-code",
    label: "Yes, it was using short code traffic.",
  },
  {
    value: "long-code",
    label: "Yes, it was using long code traffic.",
  },
];

export type AnotherMessagingServiceTrafficType =
  typeof anotherMessagingServiceTrafficItems[number];

export type AnotherMessagingServiceTrafficValueType =
  typeof anotherMessagingServiceTrafficItems[number]["value"];

export const estimatedMonthlyMessageVolume = [
  { value: "<25,000", label: "<25,000" },
  { value: "25,000 - 99,000", label: "25,000 - 99,000" },
  { value: "100,000 - 999,000", label: "100,000 - 999,000" },
  { value: "1,000,000 - 5,000,000", label: "1,000,000 - 5,000,000" },
] as const;

export type EstimatedMonthlyMessageVolumeItemType =
  typeof estimatedMonthlyMessageVolume[number];
