import classNames from "classnames";
import React from "react";
import {
  Controller,
  RegisterOptions,
  useFormContext,
  useFormState,
  Ref,
} from "react-hook-form";
import { FieldError } from "../Error";
import { Label } from "../Label";

import DateTimePickerComponent from "react-datetime-picker/src/entry.nostyle.js";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

type DateTimePickerProps = {
  value: any;
  onChange: (value: any) => void;
  id?: string;
  enableClear?: boolean;
};

// React-Hook-Form's Controller
export const DateTimePicker = React.forwardRef(
  (
    { value, onChange, enableClear, ...props }: DateTimePickerProps,
    ref: React.ForwardedRef<Ref>
  ) => {
    const clearIcon = enableClear === true ? undefined : false;
    return (
      <DateTimePickerComponent
        className="DateTimePicker"
        clearIcon={clearIcon}
        onChange={onChange}
        value={value}
        {...props}
      />
    );
  }
);

type FormDateTimePickerProps = {
  label?: string;
  name: string;
  validation?: RegisterOptions;
  wrapperClassName?: string;
  // selectClassName?: string;
  value?: any;
  onChange?: (value: any) => void;
  // white?: boolean;
  noLabel?: boolean;
  tight?: boolean;
  enableClear?: boolean;
  defaultValue?: any;
};

export function FormDateTimePicker({
  validation,
  wrapperClassName,
  name,
  label,
  noLabel,
  tight,
  enableClear,
  defaultValue = null,
}: FormDateTimePickerProps) {
  const { control } = useFormContext();
  const { errors } = useFormState();

  const classes = classNames({
    "DateTimePicker-Wrapper": true,
    [`${wrapperClassName}`]: wrapperClassName,
    "DateTimePicker-No-Label": noLabel,
    "DateTimePicker-Tight": tight,
  });

  return (
    <div className={classes}>
      <Label htmlFor={name}>{label}</Label>

      <Controller
        name={name}
        control={control}
        rules={validation}
        defaultValue={defaultValue}
        render={({ field }) => (
          <DateTimePicker enableClear={enableClear} {...field} />
        )}
      />

      <FieldError errors={errors} name={name} />
    </div>
  );
}
