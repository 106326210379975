import { httpClient } from "./httpClient";
import _, { sumBy, uniq } from "lodash-es";
import parseISO from "date-fns/parseISO";
import addDays from "date-fns/addDays";
import { dlrTsgErrorMap } from "~/utils/dlrErrorCodes";

type useDeliverabilityArgs = {
  start_date: string;
  end_date: string;
};

type DeliverabilityRecord = {
  date: string;
  error_code: string;
  count: number;
};

type AccumulatedChartData = Record<string, Record<string, number>>;

export async function getDeliverabilityRecords({
  start_date,
  end_date,
}: useDeliverabilityArgs): Promise<any> {
  const response = await httpClient("/dlr-statistics", {
    method: "GET",
    parameters: {
      start_date,
      end_date,
    },
  });

  const dlrStatistics: DeliverabilityRecord[] = response.body.dlr_statistics;

  // gather all the available error codes in the dataset
  const errorCodes = uniq(dlrStatistics.map((record) => record.error_code)).map(
    (errCode) => ({
      label: errorCodeLabel(errCode),
      value: errCode,
    })
  );

  // calculate the error and non error records so we can display percentages
  const totalErrorRecords = dlrStatistics.reduce((acc, i) => {
    if (i.error_code === "000" || i.error_code === "3ec") return acc;
    else return acc + i.count;
  }, 0);

  const totalRecords = dlrStatistics.reduce((acc, i) => {
    return acc + i.count;
  }, 0);

  const totalNonErrorRecords = totalRecords - totalErrorRecords;
  const deliverabilityPercentage = totalRecords
    ? (totalNonErrorRecords / totalRecords) * 100
    : undefined;

  // transform the data so we can display it in charts
  // we'll first initialize the accumulator so we don't have any gaps in the dates/errors
  const dateRange = generateDateRange(start_date, end_date);
  let initialAccumulatorMap: AccumulatedChartData = {};
  for (const date of dateRange) {
    initialAccumulatorMap[date] = {};
    for (const error of errorCodes) {
      initialAccumulatorMap[date][error.value] = 0;
    }
  }

  // Then we'll generate the deliverability map so the chart can calculate
  // and display the data more easily.
  const deliverabilityRecords = dlrStatistics.reduce(
    (acc, curr: DeliverabilityRecord) => {
      acc[curr.date][curr.error_code] += curr.count;
      return acc;
    },
    initialAccumulatorMap
  );

  // Here we'll take the counts that we previously calculated from deliverabilityRecords
  // and convert them to ratios (so we can display them as percentages).
  const chartData = Object.keys(
    deliverabilityRecords
  ).reduce<AccumulatedChartData>((acc, date) => {
    const errorCodes = Object.keys(deliverabilityRecords[date]).filter(
      (errorCode) => !isDeliveredErrorCode(errorCode)
    );
    const totalMessagesForDate = Object.values(
      deliverabilityRecords[date]
    ).reduce((acc, c) => acc + c, 0);

    acc[date] = errorCodes.reduce<any>((innerAcc, errorCode) => {
      // zero checks ensure we don't end up with NaN values
      innerAcc[errorCode] =
        totalMessagesForDate !== 0
          ? deliverabilityRecords[date][errorCode] / totalMessagesForDate
          : 0;
      return innerAcc;
    }, {});

    const totalDeliveredMessagesForDate =
      (deliverabilityRecords[date]["000"] ?? 0) +
      (deliverabilityRecords[date]["3ec"] ?? 0);
    // zero checks ensure we don't end up with NaN values
    acc[date]["All"] =
      totalMessagesForDate !== 0
        ? totalDeliveredMessagesForDate / totalMessagesForDate
        : 0;

    return acc;
  }, {});

  const errorCodeCountMap = _.chain(dlrStatistics)
    .groupBy("error_code")
    .reduce<{ name: string; value: number }[]>((acc, records) => {
      const errorCode = records[0].error_code;
      const count = sumBy(records, (r) => r.count);
      const errorCodeValue = count / totalRecords;

      const deliverabilityIndex = acc.findIndex(
        (el) => el.name === "Delivered"
      );
      const lowPercentageErrors = acc.findIndex((el) => el.name === "Other");

      if (deliverabilityIndex === -1) {
        acc.push({ name: "Delivered", value: 0 });
      }

      if (lowPercentageErrors === -1) {
        acc.push({ name: "Other", value: 0 });
      }

      if (errorCode === "000" || errorCode === "3ec") {
        if (deliverabilityIndex !== -1) {
          acc[deliverabilityIndex].value += errorCodeValue;
        }
        return acc;
      }

      if (errorCodeValue * 100 < 1) {
        if (lowPercentageErrors !== -1) {
          acc[lowPercentageErrors].value += errorCodeValue;
        }
        return acc;
      }

      return acc.concat({
        name: errorCodeLabel(errorCode),
        value: errorCodeValue,
      });
    }, [])
    .value();

  const filteredErrorCodes = [{ label: "Delivered", value: "Delivered" }]
    .concat(errorCodes)
    .filter((code) => !isDeliveredErrorCode(code.label));

  return {
    errorCodeCountMap,
    deliverabilityPercentage,
    deliverabilityRecords: chartData,
    errorCodes: filteredErrorCodes,
  };
}

/**
 * Takes 2 dates in "YYYY-MM-DD" format and returns the list of all the dates in between
 * including the start and end date, the list is in the "YYYY-MM-DD" format.
 */
function generateDateRange(startDate: string, endDate: string): string[] {
  const start = parseISO(startDate);
  const end = parseISO(endDate);
  const dateArray = [];

  let currentDate = start;
  while (currentDate <= end) {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // getMonth returns a 0-based value
    const day = currentDate.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    dateArray.push(formattedDate);
    currentDate = addDays(currentDate, 1);
  }

  return dateArray;
}

function isDeliveredErrorCode(errorCode: string) {
  return errorCode === "000" || errorCode.toLowerCase() === "3ec";
}

function errorCodeLabel(errorCode: string) {
  // we don't have error code labels for all the errors, so we'll fallback to the error code only
  return dlrTsgErrorMap[errorCode]
    ? `${errorCode} - ${dlrTsgErrorMap[errorCode]}`
    : errorCode;
}
