import { useState } from "react";
import { Form } from "../../components/Form";
import { Input, TextArea } from "../../components/Input";
import { formatDate, formatDateTime } from "../../utils/format";
import { PrimaryButton } from "../../components/PrimaryButton";
import { createTicket } from "../../api/ticket";
import { useNavigate } from "react-router-dom";
import { FormSelect, Select } from "../../components/Select";
import {
  phoneNumberRequiredValidation,
  requiredValidation,
} from "../../services/validation";
import { FormDateTimePicker } from "../../components/DateTimePicker";
import { stringsToSelectOptions } from "../../utils/select";
import { ticketTypeToTag } from "./ticket";
import { Loader } from "../../components/Loader";
import { AuthenticatedState, useAuthStore } from "~/stores/authStore";

export function TroubleWithService() {
  const [formattedDate] = useState(() => formatDate(new Date()));
  const [formattedDateTime] = useState(() => formatDateTime(new Date()));
  const user = useAuthStore(
    (store) => (store.state as AuthenticatedState).user
  );
  const [isWorking, setIsWorking] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const account = user.company.internal_id;

  async function submitForm(data: any) {
    const subject = "Trouble with service";

    const timestring = new Date().toTimeString();
    let timezone = timestring.substring(timestring.indexOf(" ") + 1);

    const bodyPartOne = `
    <div>
      <b>Trouble with service</b> <br/>
      <br/>
      Account: ${account} <br/>
      Reporter timezone: ${timezone} <br/>
      <br/>
      <br/>
      <br/>
      Status: New <br/>
      Open datetime: ${formattedDateTime} <br/>
      Service Type: ${data.serviceType ?? "N/A"} <br/>
      Callback no.: ${data.callbackNumber ?? "N/A"} <br/>
      DID type: ${data.didType.label} <br/>
      Porting Issue: ${data.portingIssue.label} <br/>
      Description: ${data.description ?? "N/A"} <br/>
      <br/>
      <br/>
      <br/>
      <b>Test call 1</b><br/>
      <br/>
      Call type: ${data.call_1_call_type.label} <br/>
      From: ${data.call_1_from} <br/>
      To: ${data.call_1_to} <br/>
      Datetime: ${
        data.call_1_call_time ? formatDateTime(data.call_1_call_time) : "N/A"
      } <br/>
      Timezone: ${data.call_1_timezone?.label ?? "N/A"} <br/>
      Message: ${data.call_1_message || "N/A"} <br/>
    `;

    const bodyPartTwo =
      data.call_2_from && data.call_2_to && data.call_2_call_time
        ? `<br/>
    <br/>
    <b>Test call 2</b> <br/>

    Call type: ${data.call_2_call_type?.label ?? "N/A"} <br/>
    From: ${data.call_2_from} <br/>
    To: ${data.call_2_to} <br/>
    Datetime: ${
      data.call_2_call_time ? formatDateTime(data.call_2_call_time) : "N/A"
    } <br/>
    Timezone: ${data.call_2_timezone?.label ?? "N/A"} <br/>
    Message: ${data.call_2_message || "N/A"} <br/>
    `
        : "";

    const bodyPartThree =
      data.call_3_from && data.call_3_to && data.call_3_call_time
        ? `<br/>
    <br/>
    <b>Test call 3</b> <br/>
    <br/>
    Call type: ${data.call_3_call_type?.label ?? "N/A"} <br/>
    From: ${data.call_3_from} <br/>
    To: ${data.call_3_to} <br/>
    Datetime: ${
      data.call_3_call_time ? formatDateTime(data.call_3_call_time) : "N/A"
    } <br/>
    Timezone: ${data.call_3_timezone?.label ?? "N/A"} <br/>
    Message: ${data.call_3_message || "N/A"} <br/>
    `
        : "";

    const body = [bodyPartOne, bodyPartTwo, bodyPartThree].join("");

    const formData = new FormData();

    formData.append("subject", subject);
    formData.append("body", body);
    formData.append("priority", "high");
    formData.append("tags[]", "customer_portal");
    formData.append("tags[]", ticketTypeToTag("Trouble with service"));

    setIsWorking(true);

    try {
      setError(null);
      await createTicket(formData);
      navigate("/support/ticket-created");
    } catch (error: any) {
      setError("An error occurred... please try again");
      setIsWorking(false);
    }
  }

  return (
    <div className="mt-4">
      <div>
        <h2 className="">
          If you're having trouble with calls or messages use this form.
        </h2>

        <h3 className="mt-2 text-xl font-semibold">Trouble ticket</h3>

        <Form onSubmit={submitForm} defaultValues={defaultValues}>
          <div className="Trouble-MainForm mt-2 max-w-lg">
            <div className="leading-9">Entry date</div>
            <div className="h-9 leading-9">{formattedDate}</div>
            <div className="leading-9">Status</div>
            <div className="h-9 leading-9">New</div>
            <div className="leading-9">Open datetime</div>
            <div className="h-9 leading-9">{formattedDateTime}</div>
            <div className="leading-9">Service Type</div>
            <div>
              <Input noLabel tight name="serviceType" />
            </div>
            <div className="leading-9">Callback No.</div>
            <div>
              <Input noLabel tight name="callbackNumber" />
            </div>
            <div className="leading-9">DID Type</div>
            <div>
              <FormSelect
                validation={requiredValidation}
                placeholder="Select DID Type"
                noLabel
                name="didType"
                options={didTypeOptions}
              />
            </div>
            <div className="leading-9">Porting issue</div>
            <div>
              <FormSelect
                validation={requiredValidation}
                placeholder="Select Yes or No"
                noLabel
                name="portingIssue"
                options={portingIssueOptions}
              />
            </div>
            <div>Description</div>
            <div>
              <TextArea noLabel tight name="description" />
            </div>
          </div>

          <h3 className="mt-6 text-lg font-semibold">
            Long distance test information
          </h3>
          <h4 className="">
            <b>Test call 1</b> info should be provided to submit the ticket (All
            fields are mandatory).
          </h4>

          <div className="mt-6 flex flex-row gap-6">
            <div className="">
              <div className="text-md font-semibold">Test call 1</div>
              <div className="Trouble-SubForm mt-2">
                <div className="leading-9">Call type</div>
                <div>
                  <FormSelect
                    placeholder="Select Call Type"
                    validation={requiredValidation}
                    noLabel
                    name="call_1_call_type"
                    options={callTypeOptions}
                  />
                </div>
                <div className="leading-9">From #</div>
                <div>
                  <Input
                    validation={phoneNumberRequiredValidation}
                    noLabel
                    tight
                    name="call_1_from"
                  />
                </div>
                <div className="leading-9">To #</div>
                <div>
                  <Input
                    validation={phoneNumberRequiredValidation}
                    noLabel
                    tight
                    name="call_1_to"
                  />
                </div>
                <div className="leading-9">Call time</div>
                <div>
                  <FormDateTimePicker
                    noLabel
                    validation={requiredValidation}
                    name="call_1_call_time"
                  />
                </div>
                <div className="leading-9">Time zone</div>
                <div>
                  <FormSelect
                    validation={requiredValidation}
                    noLabel
                    name="call_1_timezone"
                    options={timezoneOptions}
                  />
                </div>
                <div>Message</div>
                <div>
                  <TextArea
                    validation={requiredValidation}
                    noLabel
                    tight
                    name="call_1_message"
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="text-md font-semibold">Test call 2</div>
              <div className="Trouble-SubForm mt-2">
                <div className="leading-9">Call type</div>
                <div>
                  <FormSelect
                    placeholder="Select Call Type"
                    noLabel
                    name="call_2_call_type"
                    options={callTypeOptions}
                  />
                </div>
                <div className="leading-9">From #</div>
                <div>
                  <Input noLabel tight name="call_2_from" />
                </div>
                <div className="leading-9">To #</div>
                <div>
                  <Input noLabel tight name="call_2_to" />
                </div>
                <div className="leading-9">Call time</div>
                <div>
                  <FormDateTimePicker noLabel name="call_2_call_time" />
                </div>
                <div className="leading-9">Time zone</div>
                <div>
                  <FormSelect
                    noLabel
                    name="call_2_timezone"
                    options={timezoneOptions}
                  />
                </div>
                <div>Message</div>
                <div>
                  <TextArea noLabel tight name="call_2_message" />
                </div>
              </div>
            </div>

            <div>
              <div className="text-md font-semibold">Test call 3</div>
              <div className="Trouble-SubForm mt-3">
                <div className="leading-9">Call type</div>
                <div>
                  <FormSelect
                    placeholder="Select Call Type"
                    noLabel
                    name="call_3_call_type"
                    options={callTypeOptions}
                  />
                </div>
                <div className="leading-9">From #</div>
                <div>
                  <Input noLabel tight name="call_3_from" />
                </div>
                <div className="leading-9">To #</div>
                <div>
                  <Input noLabel tight name="call_3_to" />
                </div>
                <div className="leading-9">Call time</div>
                <div>
                  <FormDateTimePicker noLabel name="call_3_call_time" />
                </div>
                <div className="leading-9">Time zone</div>
                <div>
                  <FormSelect
                    noLabel
                    name="call_3_timezone"
                    options={timezoneOptions}
                  />
                </div>
                <div>Message</div>
                <div>
                  <TextArea noLabel tight name="call_3_message" />
                </div>
              </div>
            </div>
          </div>

          <PrimaryButton
            type="submit"
            className="!w-[180px] ml-auto mr-auto mt-6 h-[42px]"
            disabled={isWorking}
          >
            {isWorking ? <Loader small /> : "Create ticket"}
          </PrimaryButton>
        </Form>
      </div>
    </div>
  );
}

const didTypeOptions = stringsToSelectOptions([
  "New assigned number",
  "Existing number",
  "Ported number",
]);

const portingIssueOptions = [
  {
    label: "No",
    value: false,
  },
  {
    label: "Yes",
    value: true,
  },
];

const callTypeOptions = stringsToSelectOptions([
  "Inbound(DID)",
  "Inbound(800#)",
  "Inbound(Flat rate port)",
  "Outbound(Domestic)",
  "Outbound(International)",
  "Outbound(911)",
]);

const timezoneOptions = stringsToSelectOptions([
  "GMT -12:00",
  "GMT -11:00",
  "GMT -10:00",
  "GMT -09:00",
  "GMT -08:00",
  "GMT -07:00",
  "GMT -06:00",
  "GMT -05:00",
  "GMT -04:30",
  "GMT -04:00",
  "GMT -03:30",
  "GMT -03:00",
  "GMT -02:00",
  "GMT -01:00",
  "GMT 00:00",
  "GMT +01:00",
  "GMT +02:00",
  "GMT +03:00",
  "GMT +04:00",
  "GMT +04:30",
  "GMT +05:00",
  "GMT +05:30",
  "GMT +05:45",
  "GMT +06:00",
  "GMT +06:30",
  "GMT +07:00",
  "GMT +08:00",
  "GMT +09:00",
  "GMT +09:30",
  "GMT +10:00",
  "GMT +11:00",
  "GMT +12:00",
]);

const defaultValues = {};
