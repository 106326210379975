import React from "react";
import { IOrderDetailsAttachments } from "./types";

export const OrderDetailsAttachments = ({
  attachments = [],
}: IOrderDetailsAttachments) => {
  // do not show until we've solved the signed URLs from S3
  return null;

  return (
    <div className="flex flex-col">
      <div className="mb-2 text-xl">
        <b>Attachments:</b>
      </div>
      <div>
        {attachments.map((attachment, i) => (
          <div key={attachment}>[Attachment #{i}]</div>
        ))}
      </div>
    </div>
  );
};
