/**
 * API for creating Zendesk tickets.
 */
import { httpClient } from "./httpClient";

export async function createTicket(formData: FormData): Promise<any> {
  const response = await httpClient(`/tickets`, {
    method: "POST",
    body: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });

  return response.body;
}
