import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getOrderDetails } from "~/api/order";
import { Layout } from "~/components/Layout";
import { Loader } from "~/components/Loader";
import { formatConstant } from "~/utils/format";
import {
  OrderDetailsCampaign,
  OrderDetailsStatus,
  IOrderDetailsBusiness,
  IOrderDetailsCampaign,
  IOrderDetailsStatus,
  OrderDetailsNumbers,
  OrderDetailsBusiness,
  OrderDetailsAttachments,
  VerifiedSenderFormCampaignDetails,
} from ".";
import { TOrderDetailsCampaign } from "./types";

export const OrderDetails = () => {
  const { id } = useParams();
  const orderId = id as string;

  const { data: order, isLoading, error, isFetching } = useOrder({ orderId });

  const orderDetailsBusiness: IOrderDetailsBusiness = {
    business_contact_first_name: order?.data.business_contact_first_name,
    business_contact_last_name: order?.data.business_contact_last_name,
    business_name: order?.data.business_name,
    business_registered_address: order?.data.business_registered_address,
    business_registered_city: order?.data.business_registered_city,
    business_registered_state: order?.data.business_registered_state,
    business_registered_zip: order?.data.business_registered_zip,
  };

  // @ts-ignore
  const orderDetailsCampaign: TOrderDetailsCampaign = order
    ? extractCampaignDetailsData(order)
    : {};

  const orderDetailsStatus: IOrderDetailsStatus = {
    status: order?.status,
    inserted_at: order?.inserted_at,
    updated_at: order?.updated_at,
    finished_at: order?.finished_at,
  };

  if (isLoading) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  if (error) {
    return <Layout>Error occured: {error.message}</Layout>;
  }

  return (
    <Layout>
      <div className="text-2xl lg:text-3xl font-bold mt-2 mb-6">
        {order?.name} ({order?.public_id})
      </div>

      <div className="flex justify-start text-xl my-5 capitalize">
        <b>Type:&nbsp;</b> {formatConstant(order?.data.type || "")}
      </div>

      <div className="flex justify-between mb-10 w-2/3">
        <OrderDetailsStatus {...orderDetailsStatus} />
        {order?.data?.numbers && (
          <OrderDetailsNumbers numbers={order?.data?.numbers} />
        )}
      </div>
      <div className="flex justify-between mb-10 w-2/3">
        <OrderDetailsBusiness {...orderDetailsBusiness} />
        <OrderDetailsCampaign {...orderDetailsCampaign} />
      </div>
      <div>
        <OrderDetailsAttachments attachments={order?.data.attachments} />
      </div>
    </Layout>
  );
};

type useOrderArgs = {
  orderId: string;
};

function useOrder({ orderId }: useOrderArgs) {
  const { data, isLoading, error, isFetching } = useQuery(
    ["orderDetails", orderId],
    () => {
      const response = getOrderDetails(orderId);
      return response;
    }
  );

  return {
    isLoading,
    isFetching,
    error: error as Error,
    data,
  };
}

function extractCampaignDetailsData(order: any) {
  if (order.data.type === "verified_sender_form") {
    return {
      type: "verified_sender_form",
      use_case: order?.data.use_case,
      sample_messages: order?.data.sample_messages,
      how_do_consumers_opt_in: order?.data.how_do_consumers_opt_in,
      commercial_messages: order?.data.commercial_messages,
      number_published_location: order?.data.number_published_location,
      fortune_500_or_1000_company: order?.data.fortune_500_or_1000_company,
      estimated_monthly_message_volume:
        order?.data.estimated_monthly_message_volume,
      canadian_traffic_mostly: order?.data.canadian_traffic_mostly,
      covid_19_related: order?.data.covid_19_related,
      previously_another_messaging_service_traffic:
        order?.data.previously_another_messaging_service_traffic,
      previously_another_messaging_service_content:
        order?.data.previously_another_messaging_service_content,
      previously_another_messaging_service_numbers:
        order?.data.previously_another_messaging_service_numbers,
    };
  } else if (order.data.type === "shortcode_form") {
    return {
      type: "shortcode_form",
      use_case: order?.data.use_case,
      terms_and_conditions_website: order.data.terms_and_conditions_website,
      privacy_policy_website: order.data.privacy_policy_website,
      call_to_action_locations: order.data.call_to_action_locations,
      call_to_action_language: order.data.call_to_action_language,
      opt_in_keywords: order.data.opt_in_keywords,
      opt_in_confirmation: order.data.opt_in_confirmation,
      welcome_message: order.data.welcome_message,
      example_alerts: order.data.example_alerts,
      help_response_text: order.data.help_response_text,
      stop_response_text: order.data.stop_response_text,
      customer_support_contact_email: order.data.customer_support_contact_email,
      customer_support_contact_phone: order.data.customer_support_contact_phone,
      customer_support_url: order.data.customer_support_url,
    };
  } else {
    return {};
  }
}
