import { Layout } from "../../components/Layout";
import { Input, RadioInputGroup } from "../../components/Input";
import { Form } from "../../components/Form";
import {
  phoneNumberPriorityValidation,
  phoneNumberValidation,
  requiredValidation,
} from "../../services/validation";
import { useQuery } from "react-query";
import {
  PhoneNumber,
  createDestination,
  createSmsEnablement,
  deleteDestination,
  getPhoneNumber,
  getPhoneNumberDestinations,
  saveDestination,
} from "../../api/phoneNumbers";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { Loader } from "../../components/Loader";
import { Button } from "../../components/Button";
import { BinIcon, PlusIcon } from "../../components/Icons";
import { Toast } from "../../components/Toast";
import { PrimaryButton } from "../../components/PrimaryButton";
import { PhoneNumberDestination, TelephoneNumberInfo } from "../../types";
import { FieldError } from "../../components/Error";
import { Label } from "~/components/Label";
import { FormSelect } from "~/components/Select";
import { useAccountData } from "~/hooks/useAccountData";
import { AuthenticatedState, useAuthStore } from "~/stores/authStore";
import classNames from "classnames";
import { formatConstant } from "~/utils/format";
import { useTelephoneNumberInfo } from "~/hooks/useTelephoneNumberInfo";
import { getAccountDefaults } from "~/api/account";

const TWO_MINUTES_IN_MS = 1000 * 60 * 2;

export function EditPhoneNumber() {
  const [isWorking, setIsWorking] = useState(false);
  const { id: phoneNumberString } = useParams();

  const user = useAuthStore(
    (store) => (store.state as AuthenticatedState).user
  );
  const companyId = user.company.id;
  const {
    data,
    isLoading: isLoadingAccountData,
    error: accountDataLoadingError,
  } = useAccountData(companyId);

  const {
    error: phoneNumberLoadingError,
    isLoading: isLoadingNumberInfo,
    isFetching: isFetchingNumberInfo,
    data: phoneNumber,
    refetch: reloadNumberInfo,
  } = useTelephoneNumberInfo(data?.tsg_api_service_api_key, phoneNumberString);

  const {
    isLoading: isLoadingDestinations,
    error: errorDestinations,
    data: destinations,
    isFetching: isFetchingDestinations,
    refetch: refetchDestinations,
  } = useQuery(
    [`phoneNumber/${phoneNumberString}/destinations`, phoneNumberString],
    async ({}) => {
      const { destinations } = await getPhoneNumberDestinations(
        phoneNumberString as string
      );

      // prepare destinations
      const preparedDestinations = destinations.map((d: any) => ({
        priority: d.priority,
        type: d.voip_type,
        // ptsn for some reason saves the telephone number inside of the destination property
        // we'll use did in the form so the values are handled in a saner way when you switch between pstn and voip
        did: d.voip_type === "voip" ? d.did : d.destination,
        destination: d.voip_type === "voip" ? d.destination : null,
        // the id already gets taken due to the useFieldArray
        _id: d.id,
      }));

      return preparedDestinations;
    },
    {
      keepPreviousData: true,
      staleTime: TWO_MINUTES_IN_MS,
    }
  );

  async function saveChanges(data: any) {
    // The destinations are saved using the "priority" value (the ordinal number) instead of the ID,
    // that's why we'll create/update/delete destinations based on their "priority" number.

    // We'll determine the existing priorities in the system
    const existingPriorities: number[] = destinations.map(
      (d: any) => d.priority
    );
    // ...and the priorities we need to create.
    const newPriorities: number[] = data.destinations.map(
      (d: any) => d.priority
    );

    const promises = data.destinations
      .map((destination: any) => {
        return {
          id: destination._id,
          priority: destination.priority,
          type: destination.type,
          did: destination.type === "voip" ? destination.did : null,
          // we do the reverse from what we do for defaultValues
          destination:
            destination.type === "voip"
              ? destination.destination
              : destination.did,
        };
      })
      .map((destination: any) => {
        // based on the priority, we'll either save (update) or create the destinations
        if (existingPriorities.includes(destination.priority)) {
          return saveDestination(phoneNumberString!, destination);
        } else {
          return createDestination(phoneNumberString!, destination);
        }
      });

    // and we'll determine the destinations we need to remove
    existingPriorities.forEach((p) => {
      if (!newPriorities.includes(p)) {
        const promise = deleteDestination(phoneNumberString!, p);
        promises.push(promise);
      }
    });

    try {
      setIsWorking(true);
      // we've grouped all the calls into the "promises" array and will await until everything is sorted out
      await Promise.all(promises);
      await refetchDestinations();
      setIsWorking(false);
      Toast({ type: "Success", title: "Destinations updated!" });
    } catch (error) {
      console.error(error);
      setIsWorking(false);
      Toast({ type: "Error", title: "An error occurred, please try again..." });

      // refetch if an error occured
      await refetchDestinations();
    }
  }

  if (isLoadingNumberInfo || isLoadingAccountData) {
    return (
      <Layout className="Phone-Numbers">
        <h2 className="text-2xl lg:text-3xl font-bold mt-2 mb-6">
          Phone number - {phoneNumberString}
        </h2>

        <div className="relative top-16">
          <Loader />
        </div>
      </Layout>
    );
  }

  if (phoneNumberLoadingError || accountDataLoadingError) {
    return (
      <Layout className="Phone-Numbers">
        <h2 className="text-2xl lg:text-3xl font-bold mt-2 mb-6">
          Phone number - {phoneNumberString}
        </h2>

        <div className="relative top-16 text-2xl text-red-500">
          Couldn't load info for {phoneNumberString}, please try refreshing the
          page...
        </div>
      </Layout>
    );
  }

  return (
    <Layout className="Phone-Numbers">
      <h2 className="text-2xl lg:text-3xl font-bold mt-2 mb-6">
        Phone number - {phoneNumberString}
      </h2>

      <div className="grid lg:grid-cols-2 gap-10">
        <div className="lg:order-1 lg:col-span-1">
          <div className="mb-8 lg:mb-16">
            <PhoneNumberMessagingConfig
              phoneNumber={phoneNumber!}
              reloadData={reloadNumberInfo}
              isLoadingData={isLoadingNumberInfo || isFetchingNumberInfo}
            />
          </div>
        </div>
        <div>
          <div className="mb-8 lg:mb-16">
            <EditPhoneNumberForm phoneNumber={phoneNumber!} />
          </div>

          {isLoadingDestinations ? (
            <Loader />
          ) : (
            <>
              <EditDestinationsForm
                isWorking={isWorking}
                saveChanges={saveChanges}
                destinations={destinations}
              />
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}

const defaultValues = {
  smsEnabled: "true",
  mmsEnabled: "false",
  delivery: null,
  webhookUrl: "",
  xApiKeyHeader: "",
  campaignId: "",
  mmsUrl: "",
};

const deliveryOptions = [
  {
    label: "SMPP",
    value: "smpp",
  },
  {
    label: "HTTP(S) postv1",
    value: "postv1",
  },
  {
    label: "HTTP(S) postv2",
    value: "postv2",
  },
  {
    label: "HTTP(s) post3jam",
    value: "post3jam",
  },
];
const httpsBasedDeliveries = ["postv1", "postv2", "post3jam"];

type EditPhoneNumberFormProps = {
  phoneNumber: TelephoneNumberInfo;
};

function EditPhoneNumberForm({ phoneNumber }: EditPhoneNumberFormProps) {
  const { id: phoneNumberString } = useParams();

  const user = useAuthStore(
    (store) => (store.state as AuthenticatedState).user
  );

  const {
    data: accountData,
    isLoading: isLoadingAccountData,
    error: accountDataError,
  } = useAccountData(user.company_id);

  const [defaultValues] = useState(() => {
    const smsEnabled = String(phoneNumber.sms_enabled) || "false";
    const mmsEnabled = String(phoneNumber.mms_enabled) || "false";
    const deliveryType: string = phoneNumber.sms_post_ver;
    const delivery = deliveryOptions.find((d) => d.value === deliveryType);
    const webhookUrl = phoneNumber.sms_uri;
    const xApiKeyHeader = phoneNumber.x_api_key_header;
    const campaignId = phoneNumber.campaign_id;
    const mmsUrl = phoneNumber.mms_uri;

    return {
      smsEnabled,
      mmsEnabled,
      delivery,
      webhookUrl,
      xApiKeyHeader,
      campaignId,
      mmsUrl,
    };
  });

  const formMethods = useForm({
    defaultValues,
  });

  const smsEnabled = formMethods.watch("smsEnabled");
  const mmsEnabled = formMethods.watch("mmsEnabled");
  const delivery = formMethods.watch("delivery");

  async function submitForm(values: typeof defaultValues) {
    const payload = generatePayload(values);

    try {
      const result = await createSmsEnablement(
        accountData?.tsg_api_service_api_key!,
        payload
      );

      Toast({ type: "Success", title: "Request enqueued", timeout: 3000 });
    } catch (error) {
      console.error("error", error);
      Toast({ type: "Error", title: error as string, timeout: 7500 });
    }
  }

  function generatePayload(values: typeof defaultValues) {
    if (values.smsEnabled === "false") {
      return {
        action: "delete",
        did: phoneNumber.number,
      };
    }

    const webhookUrl =
      values.delivery?.value !== "smpp" ? values.webhookUrl : null;
    const xApiKeyHeader =
      values.delivery?.value !== "smpp" ? values.xApiKeyHeader : null;
    const mmsUrl = values.mmsEnabled === "true" ? values.mmsUrl : null;

    const action = defaultValues.smsEnabled === "true" ? "update" : "add";

    return {
      action: action,
      did: phoneNumber.number,
      // api service takes only stringified booleans as props
      sms_enable: values.smsEnabled === "true" ? "true" : "false",
      webhook_url: webhookUrl,
      webhook_type: values.delivery?.value,
      x_api_key_header: xApiKeyHeader,
      mms_enable: values.mmsEnabled === "true" ? "true" : "false",
      mms_routing: mmsUrl,
      campaign_id: values.campaignId,
    };
  }

  async function loadAccountDefaults() {
    try {
      const accountDefaults = await getAccountDefaults(user.company_id);

      formMethods.setValue(
        "smsEnabled",
        accountDefaults.sms_enabled !== undefined
          ? String(accountDefaults.sms_enabled)
          : "true"
      );

      const delivery = deliveryOptions.find(
        (d) => d.value === accountDefaults.webhook_type
      );

      if (delivery) {
        formMethods.setValue("delivery", delivery);
      }

      formMethods.setValue(
        "webhookUrl",
        accountDefaults.webhook_url !== undefined
          ? accountDefaults.webhook_url
          : null
      );
      formMethods.setValue(
        "mmsEnabled",
        accountDefaults.mms_enabled !== undefined
          ? String(accountDefaults.mms_enabled)
          : "false"
      );
      formMethods.setValue("mmsUrl", accountDefaults.mms_uri || null);
      formMethods.setValue(
        "xApiKeyHeader",
        accountDefaults.x_api_key_header || null
      );
    } catch (error) {
      console.error("Failed to load account defaults", error);

      Toast({
        type: "Error",
        title: "Failed to load account defaults...",
        timeout: 7500,
      });
    }
  }

  return (
    <div>
      <Form
        onSubmit={submitForm}
        useFormMethods={formMethods}
        defaultValues={defaultValues}
      >
        <div>
          <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="uppercase text-slate-600 text-lg font-bold">
                Update number settings
              </div>
            </div>
            <div className="px-4 py-5 sm:p-6">
              <DetailTitle title="SMS">
                <Button type="button" onClick={loadAccountDefaults}>
                  Load Account Defaults
                </Button>
              </DetailTitle>

              <div className="mt-4">
                <RadioInputGroup
                  label="SMS Enabled"
                  name="smsEnabled"
                  options={[
                    {
                      label: "Yes",
                      value: "true",
                    },
                    {
                      label: "No",
                      value: "false",
                    },
                  ]}
                />
              </div>

              {smsEnabled === "true" ? (
                <>
                  <div className="mt-4">
                    <div className="w-[300px]">
                      <FormSelect
                        label="SMS delivery type"
                        name="delivery"
                        options={deliveryOptions}
                      />
                    </div>
                  </div>

                  {/* Webhook URLs are provided for HTTP(S) based delivery types */}
                  {httpsBasedDeliveries.includes(delivery?.value as string) ? (
                    <div className="mt-4">
                      <div className="w-[550px]">
                        <Input
                          white
                          label="SMS webhook url"
                          name={`webhookUrl`}
                          disabled={false}
                          validation={webhookUrlValidation}
                        />
                      </div>
                    </div>
                  ) : null}

                  {httpsBasedDeliveries.includes(delivery?.value as string) ? (
                    <div className="mt-4">
                      <div className="w-[550px]">
                        <Input
                          white
                          label="SMS webhook x-api-key header"
                          name={`xApiKeyHeader`}
                          disabled={false}
                        />
                      </div>
                    </div>
                  ) : null}

                  <div className="mt-8">
                    <DetailTitle title="MMS" />
                  </div>

                  <div className="mt-4">
                    <RadioInputGroup
                      label="MMS Enabled"
                      name="mmsEnabled"
                      options={[
                        {
                          label: "Yes",
                          value: true,
                        },
                        {
                          label: "No",
                          value: false,
                        },
                      ]}
                    />
                  </div>

                  {mmsEnabled === "true" ? (
                    <div className="mt-4">
                      <div className="w-[550px]">
                        <Input
                          white
                          label="MMS delivery url"
                          name={`mmsUrl`}
                          validation={mmsUrlValidation}
                        />
                      </div>
                    </div>
                  ) : null}

                  {phoneNumber.number_type === "long-code" ? (
                    <div className="mt-8">
                      <DetailTitle title="10DLC" />

                      <div className="w-[300px]">
                        <Input
                          white
                          label="Campaign ID"
                          name={`campaignId`}
                          disabled={false}
                        />
                      </div>
                    </div>
                  ) : null}

                  {phoneNumber.number_type === "toll-free" &&
                  phoneNumber.compliance_status === "non_compliant" ? (
                    <div className="mt-8">
                      <DetailTitle title="Verified sender form" />

                      <div className="inline-block">
                        Verified sender form not submitted <br />
                        <Link
                          className="underline"
                          to={`/tf-verification-form?numbers=${phoneNumberString}`}
                        >
                          Submit Verified Sender form
                        </Link>
                      </div>
                    </div>
                  ) : null}

                  {phoneNumber.number_type === "toll-free" &&
                  phoneNumber.compliance_status === "in_progress" ? (
                    <div className="mt-8">
                      <DetailTitle title="Verified sender form" />

                      <div className="inline-block">
                        Phone number is submitted for verification
                      </div>
                    </div>
                  ) : null}

                  {phoneNumber.number_type === "toll-free" &&
                  phoneNumber.compliance_status === "compliant" ? (
                    <div className="mt-8">
                      <DetailTitle title="Verified sender form" />

                      <div className="inline-block">
                        Phone number is compliant
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
            <div className="px-4 py-4 sm:px-6">
              <div className="flex gap-8 items-center">
                <PrimaryButton type="submit" className="!w-[240px]">
                  Submit changes
                </PrimaryButton>

                <Link className="underline" to="/phone-numbers">
                  Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

const webhookUrlRegex = /^https?:\/\/.+\..+$/;

const webhookUrlInvalidValueMessage =
  'Please enter a valid URL, it has to have the protocol prefix as well, e.g. "http://" or "https://"';
export const webhookUrlValidation = {
  validate: (val: any) => {
    if (val == "" || val == null || val == undefined) {
      return webhookUrlInvalidValueMessage;
    }

    if (webhookUrlRegex.test(val) === false) {
      return webhookUrlInvalidValueMessage;
    }

    return true;
  },
};

const mmsUrlRegex = /^(https?|mm4):\/\/.+\..+$/;
const mmsUrlInvalidValueMessage =
  'Please enter a valid URL, it has to have the protocol prefix as well, e.g. "http://", "https://", "mm4://" or "mm4s://"';
export const mmsUrlValidation = {
  validate: (val: any) => {
    if (val == "" || val == null || val == undefined) {
      return mmsUrlInvalidValueMessage;
    }

    if (mmsUrlRegex.test(val) === false) {
      return mmsUrlInvalidValueMessage;
    }

    return true;
  },
};

type EditDestinationsFormProps = {
  isWorking: boolean;
  saveChanges: (data: any) => void;
  destinations: any[];
};

function EditDestinationsForm({
  isWorking,
  saveChanges,
  destinations,
}: EditDestinationsFormProps) {
  return (
    <div className="EditPhoneNumber-Form-Wrapper">
      <Form
        onSubmit={saveChanges}
        defaultValues={{
          destinations: destinations,
        }}
      >
        <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:px-6">
            <div className="uppercase text-slate-600 text-lg font-bold">
              Update destinations
            </div>
          </div>

          <div className="px-4 py-5 sm:p-6">
            <DestinationFields inputDestinations={destinations} />
          </div>

          <div className="px-4 py-4 sm:px-6">
            <PrimaryButton
              type="submit"
              disabled={isWorking}
              className="!w-[240px]"
            >
              {isWorking ? <Loader small /> : "Save destinations"}
            </PrimaryButton>
          </div>
        </div>
      </Form>
    </div>
  );
}

type DestinationFieldsProps = {
  inputDestinations: PhoneNumberDestination[];
};

// this must be adescendant of the <Form /> component so we can call useFieldArray
function DestinationFields({ inputDestinations }: DestinationFieldsProps) {
  const destinations: PhoneNumberDestination[] = useWatch({
    name: "destinations",
  });
  const { formState, reset } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: "destinations",
  });

  useEffect(() => {
    reset({ destinations: inputDestinations });
  }, [inputDestinations]);

  return (
    <div>
      <ul>
        {fields.map((item, index) => (
          <li
            key={item.id}
            className="flex flex-row flex-wrap items-start mb-4"
          >
            <div className="flex flex-row flex-wrap gap-4 flex-1">
              <div className="w-[80px]">
                <Input
                  white
                  label="Priority"
                  name={`destinations[${index}].priority`}
                  validation={phoneNumberPriorityValidation}
                  defaultValue="1"
                  placeholder="1"
                  disabled={false}
                />
              </div>

              <div>
                <RadioInputGroup
                  label="Type"
                  name={`destinations[${index}].type`}
                  options={[
                    {
                      label: "VoIP",
                      value: "voip",
                    },
                    {
                      label: "PSTN",
                      value: "pstn",
                    },
                  ]}
                />
              </div>

              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                <div className="flex flex-row items-start">
                  {destinations[index]?.type === "voip" && (
                    <>
                      <Input
                        white
                        label="Destination"
                        placeholder="1234567910"
                        name={`destinations[${index}].did`}
                        validation={phoneNumberValidation}
                        defaultValue=""
                        disabled={false}
                      />

                      <div className="mt-8 ml-2 mr-2">@</div>

                      <Input
                        white
                        // &nbsp; is invisible "non-breaking space", used to keep the label but not show it so the inputs remain aligned
                        label="&nbsp;"
                        name={`destinations[${index}].destination`}
                        validation={requiredValidation}
                        defaultValue=""
                        placeholder="192.168.0.1"
                        disabled={false}
                      />
                    </>
                  )}

                  {destinations[index]?.type === "pstn" && (
                    <Input
                      white
                      label="Destination"
                      name={`destinations[${index}].did`}
                      placeholder="1234567910"
                      validation={phoneNumberValidation}
                      defaultValue=""
                      disabled={false}
                    />
                  )}
                </div>
              </div>
            </div>

            <Button
              disabled={fields.length === 1}
              type="button"
              className="h-[42px] flex items-center sm:mt-[25px]"
              onClick={() => remove(index)}
            >
              <BinIcon />
              <span className="ml-1">Delete destination</span>
            </Button>
          </li>
        ))}
      </ul>

      <FieldError errors={formState.errors} name={"destinationPriorities"} />

      <Button
        type="button"
        className="h-[42px] mt-6 mb-12 flex items-center gap-1"
        onClick={() =>
          append({
            priority: destinations.length + 1,
            type: "voip",
            did: "",
            destination: "",
          })
        }
      >
        <PlusIcon />
        Add destination
      </Button>
    </div>
  );
}

type PhoneNumberMessagingConfigProps = {
  phoneNumber: TelephoneNumberInfo;
  reloadData: () => void;
  isLoadingData: boolean;
};

function PhoneNumberMessagingConfig({
  phoneNumber,
  reloadData,
  isLoadingData,
}: PhoneNumberMessagingConfigProps) {
  const smsRelatedFields = (
    <div>
      <DetailItem label="SMS Delivery Type" value={phoneNumber.sms_post_ver} />

      {httpsBasedDeliveries.includes(phoneNumber.sms_post_ver as string) ? (
        <DetailItem
          label="SMS Webhook URL"
          value={phoneNumber.sms_uri}
          keepCase
        />
      ) : null}

      {httpsBasedDeliveries.includes(phoneNumber.sms_post_ver as string) ? (
        <DetailItem
          label="SMS Webhook X-API-Key header"
          value={phoneNumber.x_api_key_header}
          keepCase
        />
      ) : null}

      <DetailTitle title="MMS" />

      <DetailItem label="MMS Enabled" value={phoneNumber.mms_enabled} />
      <DetailItem
        label="MMS Delivery URL"
        value={phoneNumber.mms_uri}
        keepCase
      />

      {phoneNumber.number_type === "long-code" ? (
        <>
          <DetailTitle title="10DLC" />

          <DetailItem
            label="10DLC registered?"
            value={phoneNumber.ten_dlc_registered}
          />
          <DetailItem
            label="T-Mobile 10DLC Registered?"
            value={phoneNumber.tmobile_ten_dlc_registered}
          />
          <DetailItem label="Campaign ID" value={phoneNumber.campaign_id} />

          <DetailItem label="Use-case type" value={phoneNumber.use_case_type} />
        </>
      ) : null}

      {/*
        The data is inconsistent, and the number_type field is empty, that's why we'll check the state as well.
        Eventually this should be fixed.
      */}
      {phoneNumber.number_type === "toll-free" ? (
        <>
          <DetailTitle title="Verified sender form" />
          <DetailItem
            label="Compliancy status"
            value={formatConstant(phoneNumber.compliance_status)}
          />
        </>
      ) : null}
    </div>
  );

  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:px-6">
        <div className="uppercase text-slate-600 text-lg font-bold mb-6">
          Current number settings
        </div>
      </div>

      <div className="px-4 py-5 sm:p-6">
        {phoneNumber.activated !== undefined ? (
          <DetailItem
            label="Number status"
            value={phoneNumber.activated ? "ACTIVE" : "INACTIVE"}
          />
        ) : null}

        <DetailItem label="Number type" value={phoneNumber.number_type} />

        <DetailTitle title="SMS" />

        <DetailItem label="SMS enabled" value={phoneNumber.sms_enabled} />

        {phoneNumber.sms_enabled ? smsRelatedFields : null}

        <div className="flex items-center gap-6 mt-4">
          <Button
            className=""
            onClick={() => reloadData()}
            disabled={isLoadingData}
          >
            Refresh data
          </Button>

          {isLoadingData ? <Loader smallInline /> : null}
        </div>
      </div>
    </div>
  );
}

type DetailItemProps = {
  label: string;
  value: string | null | undefined | boolean | number;
  keepCase?: boolean;
};

function DetailItem({ label, value, keepCase = false }: DetailItemProps) {
  let formattedValue = value;
  if (typeof value === "boolean") {
    formattedValue = value === true ? "Yes" : "No";
  } else if (value === "" || value === null || value === undefined) {
    formattedValue = "-";
  }

  const classes = classNames({
    "flex align-bottom items-start": true,
    uppercase: keepCase === false,
  });

  return (
    <div className={classes}>
      <Label>{label}</Label>
      <div className="mb-2 ml-2 text-xs">{formattedValue}</div>
    </div>
  );
}

function DetailTitle({
  title,
  children,
}: {
  title: React.ReactNode;
  children?: React.ReactNode;
}) {
  if (children) {
    return (
      <div className="mt-4 flex justify-between">
        <div className="uppercase text-slate-600 text-sm font-bold mb-4 mt-4">
          {title}
        </div>
        {children}
      </div>
    );
  }

  return (
    <div className="uppercase text-slate-600 text-sm font-bold mb-4 mt-4">
      {title}
    </div>
  );
}
