import "./global/polyfills";
import "./global/sweet_alert.css";
import "./global/tooltip.css";
import "./global/recharts.css";
import React from "react";
import "./index.css";
import App from "./App";
import { createRoot } from "react-dom/client";
import { bugsnagApiKey } from "./config";
import { Bugsnag } from "./components/Bugsnag";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Bugsnag apiKey={bugsnagApiKey}>
      <App />
    </Bugsnag>
  </React.StrictMode>
);
