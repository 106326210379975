import request from "graphql-request";
import { formatGraphQlError, isGraphQlError } from "../utils/error";
import { graphqlEndpoint } from "./base";
import { useAuthStore } from "~/stores/authStore";

export async function graphqlClient(
  query: string,
  data: any = {}
): Promise<any> {
  const token = useAuthStore.getState().getToken();

  const headers = { "Content-Type": "application/json" };
  if (token) {
    // @ts-ignore
    headers.Authorization = `Bearer ${token}`;
  }

  try {
    const response = await request(graphqlEndpoint, query, data, headers);

    return response;
  } catch (errorObj) {
    const error = (errorObj as any).response;
    if (isGraphQlError(error)) {
      throw new Error(formatGraphQlError(error));
    }

    const realError = JSON.parse(JSON.stringify(errorObj, null, 2));
    throw new Error(extractError(realError));
  }
}

function extractError(errorResponse: any) {
  return (
    errorResponse?.response?.errors?.[0]?.err ||
    errorResponse?.response?.errors?.[0]?.message ||
    errorResponse?.response?.message ||
    "Unknown error occured, please try again..."
  );
}
