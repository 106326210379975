import { useQuery } from "react-query";
import {
  listAllPhoneNumbersAsCsv,
  listPhoneNumbers,
  PhoneNumber,
} from "../../api/phoneNumbers";
import { Loader, LoaderOverlay } from "../../components/Loader";
import { useEffect, useState } from "react";
import { Layout } from "../../components/Layout";
import { Pagination } from "../../components/Pagination";

import { Input } from "../../components/Input";
import { Form } from "../../components/Form";
import { phoneNumberValidation } from "../../services/validation";
import { PrimaryButton } from "../../components/PrimaryButton";
import { FormSelect } from "../../components/Select";
import { SortableColumn } from "../../components/SortByChevron";
import { Link } from "react-router-dom";
import { enableVoice } from "../../config";
import { downloadCsv } from "~/utils/download";
import { CheckCircleIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { AuthenticatedState, useAuthStore } from "~/stores/authStore";

export function PhoneNumbers() {
  const user = useAuthStore(
    (store) => (store.state as AuthenticatedState).user
  );
  const companyId = user.company.id;
  const [phoneNumber, setPhoneNumber] = useState<Array<string>>([]);
  const [orderId, setOrderId] = useState("");
  const [market, setMarket] = useState("");
  const [sortBy, setSortBy] = useState<string>();

  const { data, error, isFetching, isLoading, changePage } = usePhoneNumbers({
    orderId,
    market,
    sortBy,
    did: phoneNumber,
    companyId,
  });

  function triggerSearch(data: any) {
    let phoneNumbers = preparePhoneNumbers(data.phoneNumber);

    setPhoneNumber(phoneNumbers);
    setOrderId(data.orderId);
    setMarket(data?.market?.value);
  }

  async function exportCsv() {
    const content = await listAllPhoneNumbersAsCsv({
      market,
      orderId,
      sortBy,
      did: phoneNumber,
    });

    downloadCsv("phone_numbers.csv", content);
  }

  if (isLoading) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  if (error) {
    return <Layout>Error occured: {error.message}</Layout>;
  }

  return (
    <Layout className="Phone-Numbers">
      <h2 className="text-2xl lg:text-3xl font-bold mt-2 mb-6 flex">
        Phone numbers
        <ExportLink
          className="ml-auto p-1 text-sm font-medium"
          onClick={exportCsv}
        />
      </h2>

      <div>
        <div className="flex flex-col">
          <div className="-my-2 sm:-mx-6 lg:-mx-8">
            <div className="py-2 mb-4 align-middle inline-block min-w-full sm:px-6 lg:px-8 relative">
              <div className="flex flex-row items-center flex-wrap w-full">
                <Form onSubmit={triggerSearch} className="w-full">
                  <div className="flex flex-row">
                    <Input
                      white
                      label="Phone Number"
                      name="phoneNumber"
                      validation={phoneNumberValidation}
                      defaultValue=""
                      placeholder="19512111234, 19512111235, 19512111236"
                      disabled={false}
                      wrapperClassName="w-full mb-2"
                    />
                  </div>
                  <div className="flex flex-row">
                    <Input
                      wrapperClassName=""
                      white
                      label="Order ID"
                      name="orderId"
                      defaultValue=""
                      placeholder="Order No."
                      disabled={false}
                    />

                    <FormSelect
                      wrapperClassName="ml-8"
                      name="market"
                      label="Market"
                      defaultValue={"ppm"}
                      options={marketOptions}
                    />

                    <div className="flex self-end ml-auto">
                      <PrimaryButton
                        type="submit"
                        className="flex rounded-sm h-[42px]"
                      >
                        Search <SearchIcon className="ml-2" />
                      </PrimaryButton>
                    </div>
                  </div>
                </Form>
              </div>
            </div>

            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 relative">
              <LoaderOverlay show={isFetching} />
              <div className="shadow overflow-x-auto overflow-y-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        <SortableColumn
                          columnKey="number"
                          setSortByKey={setSortBy}
                          sortBy={sortBy}
                        >
                          Number
                        </SortableColumn>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Features
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Market
                      </th>

                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Order ID
                      </th>
                      {enableVoice && (
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        ></th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {data!.phoneNumbers.map((phoneNumber) => (
                      <tr key={phoneNumber.number}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <Link
                            to={`/phone-number/${phoneNumber.number}`}
                            className="flex items-center gap-1 text-indigo-600 hover:text-indigo-900"
                          >
                            {phoneNumber.number}
                            <CompliancyStatusIcon phoneNumber={phoneNumber} />
                          </Link>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <PhoneNumberFeatureIcons phoneNumber={phoneNumber} />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatMarket(phoneNumber.market)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {phoneNumber.origin_order_id}
                        </td>
                        {enableVoice && (
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <Link
                              to={`/phone-number/${phoneNumber.number}`}
                              className="inline-block text-indigo-600 hover:text-indigo-900"
                              data-tooltip="Edit"
                            >
                              <PencilSquareIcon className="w-5 h-5" />
                            </Link>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  totalPages={data.totalPages}
                  total={data.total}
                  currentPage={data.currentPage}
                  perPage={data.perPage}
                  onPageChange={(newPage) => changePage(newPage)}
                  type="numbers"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

type usePhoneNumbersArgs = {
  did: Array<string>;
  market: string;
  orderId: string;
  sortBy?: string;
  companyId: string;
};

type usePhoneNumbersReturn = {
  isLoading: boolean;
  isFetching: boolean;
  data: {
    phoneNumbers: PhoneNumber[];
    totalPages: number;
    total: number;
    currentPage: number;
    perPage: number;
  };
  changePage: (newPage: number) => void;
  error: Error;
};

const TWO_MINUTES_IN_MS = 1000 * 60 * 2;

function usePhoneNumbers({
  did,
  market,
  orderId,
  sortBy,
  companyId,
}: usePhoneNumbersArgs): usePhoneNumbersReturn {
  const [page, setPage] = useState(1);

  // whenever the filters change, reset to the 1st page
  useEffect(() => {
    setPage(1);
  }, [did, market, orderId, sortBy]);

  const { isLoading, error, data, isFetching } = useQuery(
    ["phoneNumbers", page, did, market, orderId, sortBy, companyId],
    async ({}) => {
      const { phoneNumbers, currentPage, perPage, total, totalPages } =
        await listPhoneNumbers({
          page,
          did,
          market,
          orderId,
          sortBy,
          companyId,
        });
      return {
        phoneNumbers,
        currentPage,
        perPage,
        total,
        totalPages,
      };
    },
    {
      keepPreviousData: true,
      staleTime: TWO_MINUTES_IN_MS,
    }
  );

  return {
    isLoading,
    isFetching,
    error: error as Error,
    data: {
      phoneNumbers: data?.phoneNumbers ?? [],
      currentPage: data?.currentPage ?? 1,
      perPage: data?.perPage ?? 10,
      total: data?.total ?? 0,
      totalPages: data?.totalPages ?? 0,
    },
    changePage: setPage,
  };
}

function PhoneNumberFeatureIcons({
  phoneNumber,
}: {
  phoneNumber: PhoneNumber;
}) {
  return (
    <div className="flex flex-row">
      <div
        data-tooltip={
          phoneNumber.voice.enabled ? "Voice enabled" : "Voice not enabled"
        }
      >
        <PhoneIcon enabled={phoneNumber.voice.enabled} />
      </div>
      <div
        className="ml-1"
        data-tooltip={
          phoneNumber.sms.enabled ? "SMS enabled" : "SMS not enabled"
        }
      >
        <MessageIcon enabled={phoneNumber.sms.enabled} />
      </div>
      <div
        className="ml-1"
        data-tooltip={
          phoneNumber.mms.enabled ? "MMS enabled" : "MMS not enabled"
        }
      >
        <PictureIcon enabled={phoneNumber.mms.enabled} />
      </div>
      <div
        data-tooltip={
          phoneNumber.cnam.inbound_lookup
            ? "CNAM Inbound Dip enabled"
            : "CNAM Inbound Dip not enabled"
        }
        data-placement="top"
        open-delay="250"
      >
        <UserIcon enabled={phoneNumber.cnam.inbound_lookup} />
      </div>
    </div>
  );
}

type IconProps = {
  enabled?: boolean;
};

function PhoneIcon({ enabled }: IconProps) {
  const className = featureIconClasses(enabled);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
      />
    </svg>
  );
}

function MessageIcon({ enabled }: IconProps) {
  const className = featureIconClasses(enabled);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
      />
    </svg>
  );
}

function PictureIcon({ enabled }: IconProps) {
  const className = featureIconClasses(enabled);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
    </svg>
  );
}

function ListingIcon({ enabled }: IconProps) {
  const className = featureIconClasses(enabled);
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      className={className}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M464 64c8.823 0 16 7.178 16 16v352c0 8.822-7.177 16-16 16H48c-8.823 0-16-7.178-16-16V80c0-8.822 7.177-16 16-16h416m0-32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm-336 96c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32zm0 96c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32zm0 96c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32zm288-148v-24a6 6 0 0 0-6-6H198a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6h212a6 6 0 0 0 6-6zm0 96v-24a6 6 0 0 0-6-6H198a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6h212a6 6 0 0 0 6-6zm0 96v-24a6 6 0 0 0-6-6H198a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6h212a6 6 0 0 0 6-6z"
      ></path>
    </svg>
  );
}

function EmergencyIcon({ enabled }: IconProps) {
  const className = featureIconClasses(enabled);
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="siren"
      className={className}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M416,320h-1.88L390.88,134.08A80.13,80.13,0,0,0,311.5,64h-175a80.13,80.13,0,0,0-79.38,70.08L33.88,320H32A32,32,0,0,0,0,352v64a32,32,0,0,0,32,32H416a32,32,0,0,0,32-32V352A32,32,0,0,0,416,320ZM88.88,138.05A48.07,48.07,0,0,1,136.5,96h175a48.07,48.07,0,0,1,47.62,42.05l22.76,182H135.82l24.11-180.82a8,8,0,0,0-6.87-9l-15.86-2.13a8,8,0,0,0-9,6.87L103.54,320H66.12ZM416,416H32V352H416Z"
      />
    </svg>
  );
}

function InfoIcon({ enabled }: IconProps) {
  const className = featureIconClasses(enabled);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
}

function UserIcon({ enabled }: IconProps) {
  const className = featureIconClasses(enabled);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
      />
    </svg>
  );
}

function featureIconClasses(enabled: boolean = true) {
  if (enabled) {
    return "h-6 w-6";
  } else {
    return "h-6 w-6 Icon-Disabled";
  }
}

function formatMarket(phoneNumberMarket: string) {
  switch (phoneNumberMarket) {
    case "ppm":
      return "Pay per Minute";
    case "sms_only":
      return "SMS Only";
    case "tf":
      return "Toll-free";
    case "NA":
      return "NA";
    case "SC":
      return "SC";
    case "flat_rate_us_1":
      return "Flat Rate (US)";
    case "flat_rate_canada":
      return "Flat Rate (Canada)";
    case "COCN":
      return "COCN";
    // There seems to be a cutoff at the DB level,
    // so we'll cover both scenarios for now.
    case "flat_rate_internation":
    case "flat_rate_international":
      return "Flat Rate (Intl.)";
    default:
      return phoneNumberMarket;
  }
}

function SearchIcon({ className }: { className: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${className ? className : ""} h-6 w-6`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
      />
    </svg>
  );
}

const marketOptions = [
  { value: null, label: "All" },
  { value: "ppm", label: formatMarket("ppm") },
  { value: "sms_only", label: formatMarket("sms_only") },
  { value: "tf", label: formatMarket("tf") },
  { value: "NA", label: formatMarket("NA") },
  { value: "SC", label: formatMarket("SC") },
  { value: "flat_rate_us_1", label: formatMarket("flat_rate_us_1") },
  { value: "flat_rate_canada", label: formatMarket("flat_rate_canada") },
  { value: "COCN", label: formatMarket("COCN") },
  {
    value: "flat_rate_internation",
    label: formatMarket("flat_rate_internation"),
  },
];

type ExportLinkProps = {
  onClick: () => void;
  className: string;
};

function ExportLink({ onClick, className }: ExportLinkProps) {
  return (
    <div
      className={`${className} flex items-center cursor-pointer`}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
      </svg>
      Export all as CSV
    </div>
  );
}

function CompliancyStatusIcon({ phoneNumber }: { phoneNumber: PhoneNumber }) {
  let classes = classNames({
    "w-6 h-6 inline-block": true,
    "text-slate-600":
      phoneNumber.compliance_status === "non_compliant" ||
      !phoneNumber.compliance_status,
    "text-yellow-400": phoneNumber.compliance_status === "in_progress",
    "text-green-400": phoneNumber.compliance_status === "compliant",
  });

  const tooltip = complianceTooltipText(phoneNumber);

  return (
    <span data-tooltip={tooltip}>
      <CheckCircleIcon className={classes} />
    </span>
  );
}

function complianceTooltipText(phoneNumber: PhoneNumber) {
  switch (phoneNumber.compliance_status) {
    case "non_compliant":
      return "Number not compliant";
    case "in_progress":
      return "Compliancy in progress";
    case "compliant":
      return "Fully compliant";
    default:
      return "Number not compliant";
  }
}

function preparePhoneNumbers(phoneNumbers: string) {
  let preparedPhoneNumbers = phoneNumbers
    .split(",")
    .map((str: string) => str.trim())
    .filter((str: string) => str);

  // if we're searching for multiple numbers or there's no filtering,
  // just skip additional processing
  if (preparedPhoneNumbers.length !== 1) {
    return preparedPhoneNumbers;
  }

  // if there's only a single number, then prepare wildcard search

  // replace asterisk with % to accomodate SQL
  if (preparedPhoneNumbers[0].includes("*")) {
    preparedPhoneNumbers[0] = preparedPhoneNumbers[0].replaceAll("*", "%");
  }
  // if % is missing at the end and the input isn't a full number,
  // add the %
  if (
    preparedPhoneNumbers[0].length < 11 &&
    preparedPhoneNumbers[0].includes("%") == false
  ) {
    preparedPhoneNumbers[0] = preparedPhoneNumbers[0] + "%";
  }

  return preparedPhoneNumbers;
}
