import { mapKeys, snakeCase } from "lodash-es";
import { httpClient } from "./httpClient";

type ListOrdersParams = {
  page?: number;
  // page_size?: number;
  addedStartDate?: string;
  addedEndDate?: string;
  status?: string;
  type?: string;
  public_id?: string;
  sortBy?: string;
};

export type ListOrdersResponse = {
  data: {
    orders: Order[];
    page: number;
    page_size: number;
  };
};

export async function listOrders({
  page = 1,
  type,
  status,
  public_id,
}: ListOrdersParams): Promise<ListOrdersResponse> {
  const response = await httpClient("/new_orders", {
    method: "GET",
    parameters: {
      page,
      type,
      status,
      public_id,
    },
  });

  return response.body;
}

export type CountOrdersResponse = {
  data: { count: number };
};

export async function countOrders({
  type,
  status,
  public_id,
}: ListOrdersParams): Promise<CountOrdersResponse> {
  const response = await httpClient("/new_orders/count", {
    method: "GET",
    parameters: {
      type,
      status,
      public_id,
    },
  });

  return response.body;
}

export async function createOrder(orderData: any): Promise<Order> {
  // convert to casing that API consumes
  const order = mapKeys(orderData, (_val, key) => {
    return snakeCase(key);
  });

  const response = await httpClient("/new_orders", {
    method: "POST",
    body: { order },
  });

  return response.body.data;
}

export async function getOrderDetails(orderId: string): Promise<Order> {
  const response = await httpClient(`/new_orders/${orderId}`, {
    method: "GET",
  });

  return response.body.data;
}

export interface Order {
  id: string;
  public_id: string;
  name: string;
  status: string;
  status_note: string | null;
  data: Data;
  organization_id: string;
  created_by_id: string;
  inserted_at: string;
  updated_at: string;
  finished_at: string | null;
}

// for now this can only contain verified form sender data
export interface Data {
  attachments: string[];
  business_contact_email: string;
  business_contact_first_name: string;
  business_contact_last_name: string;
  business_contact_phone: string;
  business_name: string;
  business_registered_address: string;
  business_registered_city: string;
  business_registered_state: string;
  business_registered_zip: string;
  call_to_action: string;
  canadian_traffic_mostly: string;
  commercial_messages: string;
  covid_19_related: string;
  estimated_monthly_message_volume: string;
  fortune_500_or_1000_company: string;
  help_message_contains_opt_out_instructions: string;
  how_do_consumers_opt_in: string;
  message_sender_website: string;
  messaging_provider: string;
  number_published_location: string;
  numbers: Number[];
  numbers_explanation?: string;
  previously_another_messaging_service_content?: string;
  previously_another_messaging_service_numbers: string[];
  previously_another_messaging_service_traffic: string;
  sample_messages: string[];
  stop_keyword_is_supported_as_an_opt_out_method: string;
  terms_and_privacy: string;
  type: string;
  use_case: string;
}

export interface Number {
  note: string | null;
  number: string;
  status: string;
}
