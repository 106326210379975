import { ReactNode, InputHTMLAttributes, HTMLProps } from "react";
import classNames from "classnames";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  small?: boolean;
  light?: boolean;
  className?: string;
}

export function Button({
  children,
  className = "",
  small,
  disabled,
  light,
  ...props
}: ButtonProps) {
  const buttonClass = classNames({
    Button: true,
    "Button-Small": small,
    "Button-Green": !light,
    "Button-Light": light,
    [className]: !!className,
  });

  return (
    <button className={buttonClass} disabled={disabled} {...props}>
      {children}
    </button>
  );
}
