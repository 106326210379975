import "./App.css";
import { Sidebar } from "./components/Sidebar";

import { Routes, Route } from "react-router-dom";
import { CdrList } from "~/pages/CdrList";
import { NotFound } from "~/pages/NotFound";
import { Account } from "~/pages/Accounts";
import { PhoneNumbers } from "~/pages/PhoneNumbers";
import { Analytics } from "~/pages/Analytics";
import { EditPhoneNumber } from "~/pages/EditPhoneNumber";
import { Support } from "~/pages/Support";
import { CreateTicket, CreateTicketSuccess } from "~/pages/CreateTicket";
import { LogoutLink } from "./components/LogoutLink";
import { enableSupportPage, enableVoice } from "./config";
import { BatchUpdateNumbers } from "~/pages/BatchUpdateNumbers";
import { TollFreeVerificationForm } from "~/pages/TollFreeVerificationForm";
import { Orders } from "./pages/Orders";
import { OrderDetails } from "./pages/OrderDetails";
import { TerminationIPs } from "./pages/TerminationIPs";
import { PurchaseNumbers } from "./pages/PurchaseNumbers";
import { Deliverability } from "./pages/Deliverability";
import { PurchaseNumbersComplete } from "./pages/PurchaseNumbers/PurchaseNumbersComplete";
import { useAccountData } from "./hooks/useAccountData";
import { AuthenticatedState, useAuthStore } from "./stores/authStore";
import { Loader } from "./components/Loader";

function AuthenticatedApp() {
  const user = useAuthStore(
    (store) => (store.state as AuthenticatedState).user
  );
  const isWorking = useAuthStore((store) => store.isWorking);
  const companyId = user.company.id;
  // trigger the account preload cause it's needed in some parts of the app
  const { data: _data } = useAccountData(companyId);

  // Use this code to enable TSG-only members to use certain routes.
  // This is a simple implementation of a feature flag.
  //
  // const user = useAuthStore(store => (store.state as AuthenticatedState).user);
  // const userEmail = sessionInfo!.user!.email;
  // const onlyForTsgMembers =
  //   userEmail?.includes("@tsgglobal.com") ||
  //   userEmail?.includes("@example.com");

  // If the user is being loaded, changed/impersonated or something
  // show the loader to avoid in-between states or display issues.
  if (isWorking) {
    return <Loader />;
  }

  return (
    <>
      <LogoutLink />
      <Sidebar />
      <main className="main main--authenticated bg-gray-100 min-h-screen">
        <Routes>
          <Route path="/" element={<CdrList />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/account" element={<Account />} />
          <Route path="/phone-numbers" element={<PhoneNumbers />} />
          <Route path="/purchase-phone-numbers" element={<PurchaseNumbers />} />
          <Route
            path="/purchase-phone-numbers-success"
            element={<PurchaseNumbersComplete />}
          />
          <Route path="/batch-update" element={<BatchUpdateNumbers />} />
          {enableVoice && (
            <Route path="/phone-number/:id" element={<EditPhoneNumber />} />
          )}
          {enableSupportPage && <Route path="/support" element={<Support />} />}
          {enableSupportPage && (
            <Route path="/support/new-ticket" element={<CreateTicket />} />
          )}
          {enableSupportPage && (
            <Route
              path="/support/ticket-created"
              element={<CreateTicketSuccess />}
            />
          )}

          <Route
            path="/tf-verification-form"
            element={<TollFreeVerificationForm />}
          />

          <Route path="/deliverability" element={<Deliverability />} />

          <Route path="/orders" element={<Orders />} />
          <Route path="/orders/:id" element={<OrderDetails />} />
          <Route path="/termination-ips" element={<TerminationIPs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
    </>
  );
}

export default AuthenticatedApp;
