// import { DevTool } from "@hookform/devtools";
import React, { FormEvent } from "react";
import { FormProvider, useForm, ValidationMode } from "react-hook-form";

interface FormProps {
  defaultValues?: { [key: string]: any };
  children: React.ReactNode;
  onSubmit: (data: any) => void;
  className?: string;
  nativeFormSubmit?: boolean;
  validationMode?: keyof ValidationMode;
  // if you want to initialize form in a parent component,
  // so you can reuse the methods from the useForm hook in the parent.
  useFormMethods?: any;
}

export const Form = React.forwardRef<HTMLFormElement, FormProps>(
  (
    {
      className = "",
      defaultValues,
      children,
      onSubmit,
      // if true, the form will be submitted in a Native HTML way
      nativeFormSubmit = false,
      validationMode = "onBlur",
      useFormMethods,
    },
    ref
  ) => {
    const methods =
      useFormMethods ||
      useForm({ defaultValues, mode: validationMode, shouldFocusError: true });
    const {
      handleSubmit,
      // control,
    } = methods;

    function submitHandler(event: FormEvent) {
      if (nativeFormSubmit == false) {
        handleSubmit(onSubmit)(event);
      }
    }

    return (
      <FormProvider {...methods}>
        <form
          ref={ref}
          onSubmit={submitHandler}
          className={className}
          autoComplete="off"
        >
          {children}
          {/* <DevTool control={control} /> */}
        </form>
      </FormProvider>
    );
  }
);
