import { httpClient } from "./httpClient";

export type CompanyName = {
  id: string;
  name: string;
  internal_id: string;
};

export async function getAllCompanyNames(
  config?: object
): Promise<{ data: CompanyName[] }> {
  const response = await httpClient("/companies/names", {
    parameters: {
      active: true,
    },
    ...config,
  });
  return response.body;
}
