import {
  ClipboardDocumentIcon,
  LifebuoyIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
// import { BeakerIcon } from "@heroicons/react/24/outline"
import { NavLink } from "react-router-dom";
import { appEnvironment, enableSupportPage, portalLogo } from "~/config";
import { ImpersonateAccount } from "../ImpersonateAccount";

// Dynamically load Logo...
const logos = import.meta.globEager(`/src/assets/*_logo.png`);
const logoSrc = logos[portalLogo].default;

// Only show the yellow sidebar for STAGING,
// DEVELOPMENT and PRODUCTION don't need this.
const sidebarClasses =
  appEnvironment === "STAGING" ? "Sidebar px-4 !bg-amber-200" : "Sidebar px-4";

export function Sidebar() {
  return (
    <div className={sidebarClasses}>
      <EnvironmentRibbon />
      <div className="Sidebar-Header">
        <img style={{ width: 180 }} src={logoSrc} />
      </div>
      <nav className="Sidebar-Content">
        <ul className="flex flex-col h-full">
          {/* Hide until we get a home/dashboard page - currently it breaks look with the active styling */}
          {/* <li className="mb-3">
            <NavLink
              className={(navData) =>
                navData.isActive
                  ? "Sidebar-Link Sidebar-Link-Active"
                  : "Sidebar-Link"
              }
              to="/"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>
              Home
            </NavLink>
          </li> */}
          <div className="w-full mb-4">
            <ImpersonateAccount />
          </div>

          <li className="mb-3">
            <NavLink
              className={(navData) =>
                navData.isActive
                  ? "Sidebar-Link Sidebar-Link-Active"
                  : "Sidebar-Link"
              }
              to="/"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                />
              </svg>
              CDRs
            </NavLink>
          </li>
          <li className="mb-3">
            <NavLink
              className={(navData) =>
                navData.isActive
                  ? "Sidebar-Link Sidebar-Link-Active"
                  : "Sidebar-Link"
              }
              to="/analytics"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                />
              </svg>
              Analytics
            </NavLink>

            <div className="Sidebar-Indented-Link">
              <NavLink
                className={(navData) => (navData.isActive ? "font-bold" : "")}
                to="/deliverability"
              >
                Deliverability
              </NavLink>
            </div>
          </li>

          <li className="mb-3">
            <NavLink
              className={(navData) =>
                navData.isActive
                  ? "Sidebar-Link Sidebar-Link-Active"
                  : "Sidebar-Link"
              }
              to="/phone-numbers"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>
              Numbers
            </NavLink>
            <div className="Sidebar-Indented-Link">
              <NavLink
                className={(navData) => (navData.isActive ? "font-bold" : "")}
                to="/phone-numbers"
              >
                Active numbers
              </NavLink>
            </div>

            <div className="Sidebar-Indented-Link">
              <NavLink
                className={(navData) => (navData.isActive ? "font-bold" : "")}
                to="/purchase-phone-numbers"
              >
                Purchase numbers
              </NavLink>
            </div>
            <div className="Sidebar-Indented-Link">
              <NavLink
                className={(navData) => (navData.isActive ? "font-bold" : "")}
                to="/batch-update"
              >
                Voice Batch Update
              </NavLink>
            </div>
            {/* This is disabled for now due to Bandwidth's new pricing.

              <div className="Sidebar-Indented-Link">
              <NavLink
                className={(navData) => (navData.isActive ? "font-bold" : "")}
                to="/e911-registration"
              >
                Register E911
              </NavLink>
            </div>
            */}
          </li>

          <li className="mb-3">
            <NavLink
              className={(navData) =>
                navData.isActive
                  ? "Sidebar-Link Sidebar-Link-Active"
                  : "Sidebar-Link"
              }
              to="/orders"
            >
              <ClipboardDocumentIcon className="h-6 w-6 mr-2" />
              Orders
            </NavLink>

            <div className="Sidebar-Indented-Link">
              <NavLink
                className={(navData) => (navData.isActive ? "font-bold" : "")}
                to="/tf-verification-form"
              >
                Toll-Free VSF
              </NavLink>
            </div>
          </li>

          <li className="mb-3">
            <NavLink
              className={(navData) =>
                navData.isActive
                  ? "Sidebar-Link Sidebar-Link-Active"
                  : "Sidebar-Link"
              }
              to="/account"
            >
              <UserIcon className="h-6 w-6 mr-2" />
              Account
            </NavLink>

            <div className="Sidebar-Indented-Link">
              <NavLink
                className={(navData) => (navData.isActive ? "font-bold" : "")}
                to="/termination-ips"
              >
                Termination IPs
              </NavLink>
            </div>
          </li>

          {enableSupportPage && (
            <li className="mb-3 mt-auto">
              <NavLink
                className={(navData) =>
                  navData.isActive
                    ? "Sidebar-Link Sidebar-Link-Active"
                    : "Sidebar-Link"
                }
                to="/support"
              >
                <LifebuoyIcon className="h-6 w-6 mr-2" />
                Support
              </NavLink>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}

/**
 * Show the EnvironmentRibbon for STAGING and DEVELOPMENT, but not for PRODUCTION.
 */
function EnvironmentRibbon() {
  if (appEnvironment === "PRODUCTION") return null;

  return (
    <div className="h-8">
      <div className="h-8 bg-yellow-500 w-full absolute top-0 left-0 right-0 flex justify-center items-center">
        <p className="text-black font-bold">{appEnvironment}</p>
      </div>
    </div>
  );
}
