import { useMemo, useRef, useState } from "react";
import { Form } from "../../components/Form";
import { FormFileInput, Input, TextArea } from "../../components/Input";
import { formatDate } from "../../utils/format";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Button } from "../../components/Button";
import { Alert } from "../../components/Alert";
import { PrimaryButton } from "../../components/PrimaryButton";
import { PlusSmallIcon } from "@heroicons/react/24/solid";
import { createTicket } from "../../api/ticket";
import { useNavigate } from "react-router-dom";
import { FormSelect } from "../../components/Select";
import { Label } from "../../components/Label";
import { stringsToSelectOptions } from "../../utils/select";
import { requiredValidation } from "../../services/validation";
import { filter } from "lodash";
import { Loader } from "../../components/Loader";
import { ticketTypeToTag } from "./ticket";
import { AuthenticatedState, useAuthStore } from "~/stores/authStore";

export function TollFreePortIn() {
  const [formattedDate] = useState(() => formatDate(new Date()));
  const user = useAuthStore(
    (store) => (store.state as AuthenticatedState).user
  );
  const [isWorking, setIsWorking] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const account = user.company.internal_id;
  const companyName = user.company.name;

  async function submitForm(data: { records: PortInRecord[] }) {
    const { records } = data;

    const title = `tollfree_incoming_portin_${account}_${formattedDate}.csv`;
    const subject = "Toll-Free Incoming Port-In Request";
    const body = `
      <div>
        <b>Toll-Free Incoming Port-In Request</b> <br/>
        <br/>
        Account: ${account} <br/>
        Company: ${companyName} <br/>
        <br/>
        <br/>
        Attached is the CSV file of the request and the LOA/COB file.
      </div>
    `;

    const formData = new FormData();

    // workaround for multiple attachments - FormData for some reason doesn't support a FileList object (which you get with <input type="file" multiple ... />)
    formData.delete("attachments[]");

    const csvHeaders = `"CustomerName","8xxNumber","AreaOfService","DidDestinationIP","CustomerNotes"`;
    const recordsAsCsv = records
      .filter(isRecordFilled)
      .map(formatRecordAsCsv)
      .join("\n");

    const csvFile = `${csvHeaders}\n${recordsAsCsv}`;

    const fileBlob = new Blob([csvFile], { type: "text/csv" });

    formData.append("attachments[]", fileBlob, title);

    const files = (fileInputRef.current as any).files;
    [...files].forEach((file: File) => formData.append("attachments[]", file));

    formData.append("subject", subject);
    formData.append("body", body);
    formData.append("tags[]", "customer_portal");
    formData.append("tags[]", ticketTypeToTag("Toll-free port-in"));

    setIsWorking(true);

    try {
      setError(null);
      await createTicket(formData);
      navigate("/support/ticket-created");
    } catch (error: any) {
      setError("An error occurred... please try again");
      setIsWorking(false);
    }
  }

  return (
    <div>
      <div>
        <Alert
          className="mt-6 mb-8  max-w-3xl"
          title="Instructions"
          text={
            <>
              <b>Instructions:</b> Please submit only one order at a time.
              Orders should always be separated by ResporgID and Customer.
              Customer Name, spelling of Customer Name, and abbreviation/format
              of address information MUST match customers information listed on
              their Customer Service Record (CSR). If you are unsure of this
              information, please contact the local carrier prior to placing
              your order. The bill copy needs to meet the following criteria to
              be considered valid:
              <br />
              1) Dated within the past 45 days
              <br />
              2) Provide bill page with customer name and address (should match
              the LOA info)
              <br />
              3) Provide bill page(s) showing the TFN(s)
              <br />
              <br />
              <b>Attention:</b> All LOAs must now be signed and dated within 30
              days of the request being submitted to TSG.
            </>
          }
        />

        <div>
          <b>Instructions:</b> To submit a Toll-Free Port-In Request please
          click here (have a link that will DL the form) please only complete
          the 2nd and 3rd tab of this form, the 1st tab is for TSG use only. A
          copy of the end users bill is required in order to submit, this must
          be dated within the last 60 days.
        </div>

        <div className="mt-4">
          To submit a Toll-Free LNP Request please{" "}
          <a
            href="https://support.tsgglobal.com/hc/en-us/article_attachments/15718321755419/CUSTOMER_NAME_Toll_Free_LNP_Request_Form_MM-DD-YYYY.xls"
            download="CUSTOMER_NAME_Toll_Free_LNP_Request_Form_MM-DD-YYYY.xls"
            className="underline"
            target="_blank"
          >
            click here
          </a>
          . Please save this file each time you are submitting a request.
        </div>

        <Form
          ref={formRef}
          onSubmit={submitForm}
          defaultValues={defaultFormValues}
          className="mt-8 max-w-3xl"
          validationMode="onSubmit"
        >
          <div className="flex flex-wrap mt-2">
            <div className="w-full">
              <div className="">
                <FormFileInput
                  name="attachments"
                  multiple
                  validation={requiredValidation}
                  ref={fileInputRef}
                />
              </div>
              <div className="mt-1 text-sm">
                Please follow the proper naming convention:
                CUSTOMER_NAME_Toll_Free_LNP_Request_Form_MM-DD-YYYY.xls
              </div>
            </div>
          </div>

          <TableInput submitInProgress={isWorking} />
        </Form>
      </div>
    </div>
  );
}

function TableInput({ submitInProgress }: { submitInProgress: boolean }) {
  const { fields, append } = useFieldArray({
    name: "records",
  });

  return (
    <div className="mt-10">
      <table className="text-xs table-fixed">
        <thead>
          <tr>
            <th colSpan={5} className="text-center pb-4">
              To be completed by requestor
            </th>
          </tr>
          <tr>
            <th className="">Customer name</th>
            <th className="w-[95px]">8xx Number</th>
            <th className="">Area of Service</th>
            <th>DID destination IP</th>
            <th>Customer notes</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((item, index) => (
            <TableInputRow key={item.id} index={index} />
          ))}
        </tbody>
      </table>

      <div className="flex flex-row justify-between items-start mt-2">
        <Button
          type="button"
          small
          className="!w-fit flex align-center gap-2"
          onClick={() =>
            append({
              product: "",
              customerName: "",
              npa: "",
              nxx: "",
              dnis: "",
              serviceAddress: "",
              btn: "",
              loaDate: "",
              requestedPortDate: "",
              t38Required: null,
              didDestinationIp: "",
              customerNotes: "",
            })
          }
          disabled={submitInProgress}
        >
          <PlusSmallIcon className="w-5 h-5" />
          Add record
        </Button>
        <PrimaryButton
          type="submit"
          className="h-[42px] !w-fit flex align-center gap-2"
          disabled={submitInProgress}
        >
          {submitInProgress ? <Loader small /> : "Create request"}
        </PrimaryButton>
      </div>
    </div>
  );
}

type TableInputRowProps = {
  // item: Record<"id", string>;
  index: number;
};

function TableInputRow({ index }: TableInputRowProps) {
  const methods = useFormContext();

  const recordValidation = useMemo(
    () => ({
      validate: (value: any) => {
        const record = methods.getValues(`records[${index}]`);

        // all rows but the first can be empty, no need to validate those
        if (index !== 0 && isRecordEmpty(record)) return true;

        const emptyProps = unfilledRequiredFields(record);
        if (emptyProps.length === 0) return true;

        emptyProps.forEach((prop) => {
          const name = `records[${index}].${prop}`;
          methods.setError(name, {
            type: "required",
            message: "Field required",
          });
        });

        return value === "" || value === null ? "Field required" : true;
      },
    }),
    []
  );

  return (
    <tr>
      <td className="align-top">
        <Input
          validation={recordValidation}
          tight
          noLabel
          name={`records[${index}].customerName`}
        />
      </td>
      <td className="align-top">
        <Input
          validation={recordValidation}
          tight
          noLabel
          name={`records[${index}].number`}
        />
      </td>
      <td className="align-top">
        <FormSelect
          validation={recordValidation}
          wrapperClassName=""
          className=""
          noLabel
          tight
          name={`records[${index}].areaOfService`}
          options={areaOfServiceOptions}
          isClearable
        />
      </td>
      <td className="align-top w-[200px]">
        <TextArea
          validation={recordValidation}
          tight
          noLabel
          name={`records[${index}].didDestinationIp`}
        />
      </td>
      <td className="align-top w-[200px]">
        <TextArea
          validation={recordValidation}
          tight
          noLabel
          name={`records[${index}].customerNotes`}
        />
      </td>
    </tr>
  );
}

type PortInRecord = {
  customerName: string;
  number: string;
  areaOfService: { value: string; label: string };
  didDestinationIp: string;
  customerNotes: string;
};

const areaOfServiceOptions = [
  ...stringsToSelectOptions([
    "US - 50 States",
    "XA - 50 States and Canada",
    "XB - 50 States and Carribean",
    "XC - 50 States, Canada, and Carribean",
  ]),
];

const defaultFormValues = {
  records: new Array(10).fill({
    customerName: "",
    number: "",
    areaOfService: null,
    didDestinationIp: "",
    customerNotes: "",
  }),
};

function isRecordFilled(record: PortInRecord) {
  return (
    record.customerName !== "" &&
    record.number !== "" &&
    record.areaOfService !== null &&
    record.didDestinationIp !== ""
  );
}

function isRecordEmpty(record: PortInRecord) {
  return (
    record.customerName === "" &&
    record.number === "" &&
    record.areaOfService === null &&
    record.didDestinationIp === ""
  );
}

function unfilledRequiredFields(record: PortInRecord) {
  return [
    ["customerName", record.customerName === ""],
    ["number", record.number === ""],
    ["areaOfService", record.areaOfService === null],
    ["didDestinationIp", record.didDestinationIp === ""],
  ]
    .filter(([_prop, isEmpty]) => isEmpty === true)
    .map(([prop, _isEmpty]) => prop);
}

function formatRecordAsCsv(record: PortInRecord) {
  const str = `"${record.customerName}","${record.number}","${record.areaOfService?.label}","${record.didDestinationIp}","${record.customerNotes}"`;
  return str;
}
