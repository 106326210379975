import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/solid";

type SortableColumnArgs = {
  sortBy?: string;
  columnKey: string;
  setSortByKey: (columnKey: string) => void;
  children: React.ReactNode;
};

/**
 * Usage:
 *    const [sortBy, setSortBy] = useState<string>();
 *
 *    ...
 *
 *    <SortableColumn columnKey="number" setSortByKey={setSortBy} sortBy={sortBy}>
 *      Number
 *    </SortableColumn>
 * @returns
 */
export function SortableColumn({
  children,
  sortBy,
  columnKey,
  setSortByKey,
}: SortableColumnArgs) {
  const isActiveAsc = sortBy === columnKey;
  const isActiveDesc = sortBy === `-${columnKey}`;
  const isActive = isActiveAsc || isActiveDesc;
  const activeClasses = isActive
    ? ""
    : "invisible group-hover:visible group-focus:visible";
  const classes = `ml-2 flex-none rounded text-gray-400 ${activeClasses}`;

  function toggleSortBy() {
    const nextKey = sortBy === `-${columnKey}` ? columnKey : `-${columnKey}`;
    setSortByKey(nextKey);
  }

  if (isActiveAsc) {
    return (
      <div
        className="group inline-flex cursor-pointer items-center"
        onClick={toggleSortBy}
      >
        {children}
        <span className={classes} data-tooltip="Asc">
          <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
        </span>
      </div>
    );
  } else {
    return (
      <div
        className="group inline-flex cursor-pointer items-center"
        onClick={toggleSortBy}
      >
        {children}
        <span className={classes} data-tooltip="Desc">
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </span>
      </div>
    );
  }
}
