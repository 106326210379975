type ExportLinkProps = {
  exportLink?: string;
  onExportClick?: () => void;
  className?: string;
  text?: string;
};

export function ExportLink({
  exportLink,
  onExportClick,
  className,
  text = "Export",
}: ExportLinkProps) {
  const classes = `flex ExportLink ${className ?? ""}`;

  const content = (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
      </svg>
      {text}
    </>
  );

  if (exportLink) {
    return (
      <a className={classes} href={exportLink} target="_blank">
        {content}
      </a>
    );
  } else {
    return (
      <span className={classes} onClick={onExportClick}>
        {content}
      </span>
    );
  }
}
