export type TicketType =
  | "Billing Credit Request"
  | "411 Directory Listing"
  | "Create port request"
  | "Trouble with service"
  | "IP termination request"
  | "Local incoming port-in"
  | "Toll-free port-in"
  | "Local incoming bulk port-in"
  | "Support / Other";

// for the ReactSelect dropdown we can't use regular values
export type TicketTypeOption = {
  label: TicketType;
  value: TicketType;
};

export function ticketTypeToTag(ticketType: TicketType) {
  switch (ticketType) {
    case "Billing Credit Request":
      return "billing_credit_request";
    case "411 Directory Listing":
      return "411_directory_listing_request";
    case "Create port request":
      return "create_port_request";
    case "Trouble with service":
      return "trouble_with_service";
    case "IP termination request":
      return "ip_termination_request";
    case "Local incoming port-in":
      return "local_incoming_port_in_request";
    case "Toll-free port-in":
      return "tollfree_port_in_request";
    case "Local incoming bulk port-in":
      return "local_incoming_bulk_port_in_request";
    case "Support / Other":
      return "other_request";
  }
}
