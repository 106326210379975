import { getMessagingApiKeys } from "../../api/account";
import { useQuery } from "react-query";
import { Loader } from "../../components/Loader";
import { RevealSecret } from "../../components/RevealSecret";
import { enableApiKeys } from "../../config";
import { useAccountData } from "~/hooks/useAccountData";
import { AuthenticatedState, useAuthStore } from "~/stores/authStore";

const TWO_MINUTES_IN_MS = 1000 * 60 * 2;

type AlarmFormValues = {
  enableAlarm: string;
  amount: string;
};

export function Account() {
  const user = useAuthStore(
    (store) => (store.state as AuthenticatedState).user
  );

  // there seems to be a bug or something where user doesn't get populated on time sometimes.
  // need to track that down.
  const companyId = user.company?.id!;

  const { isLoading, error, data } = useAccountData(companyId);

  if (isLoading) {
    return (
      <div className="Account">
        <div className="py-6 sm:py-8 lg:py-12">
          <Loader />
        </div>
      </div>
    );
  }

  if (error || !user) {
    return (
      <div className="Account">
        <div className="py-6 sm:py-8 lg:py-12">
          <h2>Some error occurred...</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="Account">
      <div className="py-6 sm:py-8 lg:py-12">
        <div className="max-w-screen-2xl px-4 md:px-8 mx-auto">
          <div className="flex flex-col items-center">
            <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
              <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                <section aria-labelledby="applicant-information-title">
                  <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                      <h2
                        id="applicant-information-title"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Account
                      </h2>
                      <p className="mt-1 max-w-2xl text-sm text-gray-500">
                        Information about your account
                      </p>
                    </div>

                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 mb-8">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            User
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {user?.first_name} {user?.last_name}
                          </dd>
                        </div>
                        <div className="sm:col-span-2">
                          <dt className="text-sm font-medium text-gray-500">
                            Email address
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {user?.email}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Company
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {user?.company.name}
                          </dd>
                        </div>
                        <div className="sm:col-span-2">
                          <dt className="text-sm font-medium text-gray-500">
                            Phone
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {user?.phone_number}
                          </dd>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {enableApiKeys && (
                  <ApiKeys
                    phpApiKey={data!.tsg_api_service_api_key!}
                    companyId={companyId}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

type ApiKeysProps = {
  phpApiKey: string;
  companyId: string;
};

function ApiKeys({ phpApiKey, companyId }: ApiKeysProps) {
  const {
    isLoading,
    error,
    data: messagingKeys,
  } = useQuery(
    ["messagingApiKeys", companyId],
    async ({}) => {
      const messagingKeys = await getMessagingApiKeys();
      return messagingKeys;
    },
    {
      keepPreviousData: true,
      staleTime: TWO_MINUTES_IN_MS,
    }
  );

  const smsKeys = messagingKeys || [];

  if (isLoading) {
    return (
      <div className="py-6 sm:py-8 lg:py-12">
        <Loader small />
      </div>
    );
  }

  if (error) {
    return (
      <div className="py-6 sm:py-8 lg:py-12">
        <h2>Some error occurred loading SMS keys...</h2>
      </div>
    );
  }

  return (
    <div className="bg-white shadow sm:rounded-lg pb-4">
      <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
        <h2
          id="applicant-information-title"
          className="text-lg leading-6 font-medium text-gray-900"
        >
          Keys & Secrets
        </h2>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Your API keys for different TSG systems. API Documentation:{" "}
          <a
            target="_blank"
            rel="noreferrer noopener"
            className="underline hover:opacity-75 transition-opacity ease-out duration-150"
            href="https://docs.tsgglobal.com"
          >
            docs.tsgglobal.com
          </a>
          .
        </p>
      </div>

      <div className="Account-Secret-Table-Container">
        <div className="Account-Secret-Table-Title">API Keys</div>

        <div className="Account-Secret-Table">
          <div className="Account-Secret-Header-Col1">
            <span className="text-sm font-medium text-gray-500 py-2">URL</span>
          </div>
          <div className="Account-Secret-Header-Col2">
            <span className="text-sm font-medium text-gray-500">Key</span>
          </div>

          <div className="Account-Secret-Header-Row">
            <div className="Account-Secret-Header-Row-Col1">
              <span className="Account-Secret-Header-Row-Label">URL: </span>
              https://api-service.tsgglobal.world
            </div>
            <div className="Account-Secret-Header-Row-Col2">
              <RevealSecret secret={phpApiKey} />
            </div>
          </div>
        </div>
      </div>

      <div className="Account-Secret-Table-Container pb-1">
        <div className="Account-Secret-Table-Title">Messaging Keys (SMPP)</div>

        <div className="Account-Secret-Table">
          <div className="Account-Secret-Header-Col1">
            <span className="text-sm font-medium text-gray-500">System ID</span>
          </div>
          <div className="Account-Secret-Header-Col2">
            <span className="text-sm font-medium text-gray-500">Key</span>
          </div>

          {smsKeys.map((key) => (
            <div className="Account-Secret-Header-Row" key={key.system_id}>
              <div className="Account-Secret-Header-Row-Col1">
                <span className="Account-Secret-Header-Row-Label">
                  System ID:{" "}
                </span>
                {key.system_id}
              </div>
              <div className="Account-Secret-Header-Row-Col2">
                {/*
                  The SMPP key is stored in `dex.api_keys.encrypted_password` and can't be shown/decrypted.
                  It's the same table that keeps the `system_id` and the `key` (messaging API key).
                */}
                The key is encrypted and cannot be shown.
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="Account-Secret-Table-Container pb-1">
        <div className="Account-Secret-Table-Title">Messaging Keys (API)</div>

        <div className="Account-Secret-Table">
          <div className="Account-Secret-Header-Col2">
            <span className="text-sm font-medium text-gray-500">Key</span>
          </div>

          {smsKeys.map((key) => (
            <div
              className="Account-Secret-Header-Row w-full !flex"
              key={key.system_id}
            >
              <div className="Account-Secret-Header-Row-Col2 w-full">
                <RevealSecret secret={key.key} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
