import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { Form } from "../../components/Form";
import { Input } from "../../components/Input";
import { PrimaryButton } from "../../components/PrimaryButton";
import { Toast } from "../../components/Toast";
import { Link, useParams } from "react-router-dom";
import { passwordValidation } from "../../services/validation";
import { confirmAndSetPasswordApi } from "../../api/portalLogin";
import { Loader } from "../../components/Loader";
import { portalName } from "../../config";
import Bugsnag, { NotifiableError } from "@bugsnag/js";

type Inputs = {
  code: string;
  password: string;
};

export function OnboardNewUser() {
  const [isPasswordSet, setPasswordSet] = useState(false);

  return (
    <div className="ForgotPassword pt-20 sm:pt-28 md:pt-40">
      <h2 className="text-white text-2xl lg:text-2xl font-bold text-center md:mb-8 mb-12">
        {portalName}
      </h2>

      {isPasswordSet ? (
        <PasswordSet />
      ) : (
        <SetPasswordForm setPasswordSet={setPasswordSet} />
      )}
    </div>
  );
}

function SetPasswordForm({
  setPasswordSet,
}: {
  setPasswordSet: (v: boolean) => void;
}) {
  const { confirmationToken } = useParams();
  let [error, setError] = useState<string | null>(null);
  let [working, setWorking] = useState(false);

  const submitPassword: SubmitHandler<Inputs> = async (data) => {
    setError(null);
    setWorking(true);

    try {
      setWorking(true);
      await confirmAndSetPasswordApi(
        data.password,
        confirmationToken as string
      );
      setPasswordSet(true);
      setWorking(false);
    } catch (error) {
      Bugsnag.notify(error as NotifiableError);

      if ((error as Error).message === "Not found") {
        Toast({
          type: "Error",
          title:
            "The link has expired, contact your admin to invite you again.",
        });
      } else {
        Toast({
          type: "Error",
          title: "An error occurred, please try again...",
        });
      }
      console.error("error", error);
      setWorking(false);
    }
  };

  return (
    <div className="LogIn">
      <div className="LogIn-FormWrapper">
        <h3 className="text-gray-800 text-2xl lg:text-3xl font-bold text-center my-4 md:my-8">
          Set password
        </h3>

        <Form
          onSubmit={submitPassword}
          className="flex flex-col gap-4 p-4 md:p-8"
        >
          <Input
            label="New password"
            name="password"
            validation={passwordValidation}
            type="password"
          />

          <PrimaryButton type="submit" disabled={working}>
            {working ? <Loader small /> : "Reset password"}
          </PrimaryButton>

          {error && (
            <div className="text-red-500 py-8 w-full bg-gray-200 rounded-lg text-center">
              {error}
            </div>
          )}

          <Link to="/">Back to login</Link>
        </Form>
      </div>
    </div>
  );
}

function PasswordSet() {
  return (
    <div className="LogIn">
      <div className="LogIn-FormWrapper px-8 pt-6 pb-8">
        <h3 className="text-gray-800 text-2xl lg:text-3xl font-bold my-4 md:my-6">
          Password set successfuly
        </h3>

        <p className="text-gray-800 my-4">
          Your password has been set and you're confirmed, please go back to the
          login page and log in.
        </p>

        <Link className="underline mt-4" to="/">
          Back to login
        </Link>
      </div>
    </div>
  );
}
