import { frontendUrl, portalApiBaseUrl } from "../../config";

export function SocialLogin() {
  return (
    <div className="p-6 pt-0">
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-white text-gray-500">OR</span>
        </div>
      </div>

      <div className="mt-6">
        <div className="mb-4 tooltip-wide">
          <a
            href={`${portalApiBaseUrl}/auth/google?redirect_url=${frontendUrl}`}
            className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            <span className="sr-only">Sign in with Google</span>

            <svg
              role="img"
              viewBox="0 0 24 24"
              className="w-6 h-6 ml-[-14px]"
              aria-hidden="true"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Google</title>
              <path d="M12.48 10.92v3.28h7.84c-.24 1.84-.853 3.187-1.787 4.133-1.147 1.147-2.933 2.4-6.053 2.4-4.827 0-8.6-3.893-8.6-8.72s3.773-8.72 8.6-8.72c2.6 0 4.507 1.027 5.907 2.347l2.307-2.307C18.747 1.44 16.133 0 12.48 0 5.867 0 .307 5.387.307 12s5.56 12 12.173 12c3.573 0 6.267-1.173 8.373-3.36 2.16-2.16 2.84-5.213 2.84-7.667 0-.76-.053-1.467-.173-2.053H12.48z" />
            </svg>

            <span className="ml-4">Sign in with Google</span>
          </a>
        </div>

        <div className="tooltip-wide">
          <a
            href={`${portalApiBaseUrl}/auth/microsoft?redirect_url=${frontendUrl}`}
            className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            <svg
              role="img"
              viewBox="0 0 24 24"
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Microsoft</title>
              <path d="M0 0v11.408h11.408V0zm12.594 0v11.408H24V0zM0 12.594V24h11.408V12.594zm12.594 0V24H24V12.594z" />
            </svg>

            <span className="ml-4">Sign in with Microsoft</span>
          </a>
        </div>
      </div>
    </div>
  );
}
