// validations for the forms
export const emailValidation = {
  required: "Email is required",
  pattern: {
    value: /^\S+@\S+\.\S+$/i,
    message: "Please enter a valid email address",
  },
};

export const resetPasswordCodeValidation = {
  required: "Code is required",
};

export const passwordValidation = {
  required: "Password is required",
};

export const newPasswordValidation = {
  required: "Password is required",
  minLength: {
    value: 8,
    message: "Password must be at least 8 characters long",
  },
  validate: (passwordValue: string) => {
    const characters = passwordValue.split("");

    // string has to contain at least one uppercase, one lowercase, and one number
    const valid =
      characters.find((char) => char.match(/[a-z]/i)) &&
      characters.find((char) => char.match(/[A-Z]/i)) &&
      characters.find((char) => char.match(/[0-9]/i));

    return !!valid;
  },
  // validate: {

  // }
};

export const moneyAmountValidation = {
  required: "Money amount is required",
  min: {
    value: 0,
    message: "You can't pay less than 0",
  },
  max: {
    value: 10000000,
    message: "You can't pay more than $10,000,000.00 at once",
  },
  validate: (amount: string) => {
    const [_beforeDecimal, afterDecimal] = String(amount).split(".");
    if (afterDecimal && afterDecimal.length > 2) {
      return "Cannot add amounts with more than 2 decimal places";
    }

    return true;
  },
};

export const negativeOrPositiveMoneyAmountValidation = {
  required: "Money amount is required",
  min: {
    value: -10000000,
    message: "Invalid value, please set a higher value than -$10000000",
  },
  max: {
    value: 10000000,
    message: "You can't pay more than $10,000,000.00 at once",
  },
  validate: (amount: string) => {
    const [_beforeDecimal, afterDecimal] = String(amount).split(".");
    if (afterDecimal && afterDecimal.length > 2) {
      return "Cannot add amounts with more than 2 decimal places";
    }

    return true;
  },
};

export const phoneNumberValidation = {
  validate: (phoneNumber: string) => {
    // if the input is empty don't validate it
    if (!phoneNumber) {
      return true;
    }

    if (phoneNumber.includes("+")) {
      return 'No need to prefix numbers with a "+"';
    }

    const numberDigits = parseInt(phoneNumber);
    if (Number.isNaN(numberDigits)) {
      return "Should contain only digits";
    }

    return true;
  },
};

export const phoneNumberRequiredValidation = {
  ...phoneNumberValidation,
  required: "Phone number is required",
};

export const phoneNumberPriorityValidation = {
  validate: (priority: string) => {
    const priorityVal = parseInt(priority);
    if (Number.isNaN(priorityVal)) {
      return "Priority has to be a valid number between 1 and 20";
    }

    if (priorityVal < 1 || priorityVal > 20) {
      return "Priority has to be a valid number between 1 and 20";
    }

    return true;
  },
};

export const quantityValidation = {
  validate: (quantity: string) => {
    const quantityVal = parseInt(quantity, 10);
    if (Number.isNaN(quantityVal)) {
      return "Quantity has to be a valid, positive round number.";
    }

    if (quantityVal < 1 || quantityVal > 20) {
      return "Quantity has to be a valid, positive round number.";
    }

    return true;
  },
};

export const requiredValidation = {
  validate: (val: any) => {
    if (val == "" || val == null || val == undefined) {
      return "Field is required.";
    }

    return true;
  },
};

export const telephoneSeparatorRegEx = /\ |,|\n/;
export const multiplePhoneNumbersValidation = {
  validate: (val: string) => {
    if (val == "" || val == null || val == undefined) {
      return "Field is required.";
    }

    if (val.includes("+")) {
      return 'No need to prefix numbers with a "+"';
    }

    const numbers = val
      .split(telephoneSeparatorRegEx)
      // remove any empty values
      .filter((val) => val);

    const anyInvalidNumbers = numbers.some((number) => {
      const numberDigits = parseInt(number);
      return Number.isNaN(numberDigits);
    });

    if (anyInvalidNumbers) {
      return 'Only valid values are digits and separators (" ", "," or new lines)';
    }

    return true;
  },
};

export const multipleTollFreePhoneNumbersValidation = {
  validate: (val: string) => {
    const validationResult = multiplePhoneNumbersValidation.validate(val);

    if (validationResult === true) {
      const invalidNumbers = val
        .split(telephoneSeparatorRegEx)
        // remove any empty values
        .filter((val) => val)
        .filter((number) => {
          const prefix = number.slice(0, 4);
          return !tollfreePrefixes.includes(prefix);
        });

      console.log("invalidNumbers", invalidNumbers);

      return invalidNumbers.length === 0
        ? true
        : `Some of the submitted numbers aren't toll-free: ${invalidNumbers.join(
            ", "
          )}`;
    }

    return validationResult;
  },
};

const tollfreePrefixes = [
  "1800",
  "1833",
  "1844",
  "1855",
  "1866",
  "1877",
  "1888",
];
