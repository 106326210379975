import React from "react";

/* Workaround to handle multiple attachments. FormData for some reason doesn't support a FileList object (which you get with <input type="file" multiple ... />)

  Example usage in a component:

  const formRef = useRef<HTMLFormElement>(null);

  function submitForm(event: FormEvent) {
    event.preventDefault();

    const formData = new FormData(formRef.current!);
    ...
    appendMultipleFiles(formData, formRef, "attachments[]")
  }

  return (
    <form id="ticketForm" ref={formRef}>
      ...

      <div className="flex flex-wrap mt-6">
        <div className="w-full">
          <div className="mb-12">
            <Label htmlFor="attachment">Add attachments</Label>
            <FileInput id="attachment" name="attachments[]" multiple />
          </div>
        </div>
      </div>

      ...
    </form>
  )
*/
export function appendMultipleFiles(
  formData: FormData,
  fileInputReference: React.RefObject<HTMLInputElement>,
  fileName: string
) {
  // we need to ensure we've cleaned up the past entries in case of multiple submit attempts
  formData.delete(fileName);

  // add all of the files
  const files = fileInputReference.current?.files ?? [];
  [...files].forEach((file: File) => formData.append(fileName, file));

  return formData;
}

export async function readFileInputAsBase64(
  fileInputReference: React.RefObject<HTMLInputElement>
): Promise<string[]> {
  const files = [...(fileInputReference.current?.files ?? [])];

  const base64EncodedFiles = await Promise.all(
    files.map((file) => convertFileToBase64String(file))
  );

  return base64EncodedFiles;
}

// converts a file blob to base64 so it's easier to upload and send files via "application/json" requests
export function convertFileToBase64String(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader!.result!.toString();
      resolve(encoded as string);
    };
    reader.onerror = (error) => reject(error);
  });
}
