type InfoTooltipProps = {
  className?: string;
  children: React.ReactNode;
};

export function InfoTooltip({ className = "", children }: InfoTooltipProps) {
  const classes = `InfoTooltip ${className}`;
  return (
    <span className={classes}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>

      <div className="InfoTooltip-Content">
        <span className="InfoTooltip-Arrow"></span>
        {children}
      </div>
    </span>
  );
}
