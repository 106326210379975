import { join } from "~/utils/url";
import { portalApiBaseUrl } from "~/config";
import {
  formatApiServiceError,
  formatServerError,
  isApiServiceError,
  isChangesetError,
  isErrorWithMessage,
  isNotFoundResponse,
  isPointerBasedChangesetError,
  isPortalFormattedError,
  isServerErrorResponse,
  isSimpleError,
  ServerValidationError,
} from "../utils/error";
import { useAuthStore } from "~/stores/authStore";

type HttpClientConfig = {
  body?: any;
  // url parameters like ?param1=value1&param2=value2
  parameters?: UrlParameters;
  method?: string;
  [key: string]: any;
};

type UrlParameters = {
  [key: string]: string | string[] | number | number[] | boolean | undefined;
};

type HttpClientReturn = Promise<{
  headers?: any;
  body?: any;
}>;

export async function httpClient(
  endpoint: string,
  { body, parameters, ...customConfig }: HttpClientConfig = {}
): HttpClientReturn {
  const token = useAuthStore.getState().getToken();

  let headers = { "Content-Type": "application/json" };
  if (token) {
    // @ts-ignore
    headers = buildAuthorizationHeader(token, headers);
  }

  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      // @ts-ignore
      ...customConfig.headers,
    },
  };

  if (body && body instanceof FormData) {
    // If the body is FormData that means we're sending a "multipart/form-data" request,
    // so we'll remove the explicit "content-type" header because the browser will set one
    // and add the multipartBoundary which is required for the request to be parsed properly.
    delete config.headers["Content-Type"];
    // @ts-ignore
    config.body = body;
  } else if (body && config.headers["Content-Type"] === "application/json") {
    // @ts-ignore
    config.body = JSON.stringify(body);
  }

  const url = prepareUrl(
    customConfig.baseUrl || portalApiBaseUrl,
    endpoint,
    parameters
  );

  const response = await window.fetch(url, config);

  // if response is invalid
  if (!response.ok) {
    // this will always throw, no need to worry about returns
    await transformHttpErrorResponse(response);
  }

  // if response is successful
  return {
    headers: readHeaders(response.headers),
    body: await readBody(response),
  };
}

function readBody(response: Response): Promise<any> {
  // there's no body for 204 responses
  if (response.status === 204) {
    return Promise.resolve({
      headers: readHeaders(response.headers),
      body: null,
    });
  }

  // if there was an error,try getting the json response, or just a text response
  const headers = readHeaders(response.headers);
  const responseContentType = headers["content-type"];

  if (responseContentType?.includes("application/json")) {
    return response.json();
  } else {
    return response.text();
  }
}

function prepareUrl(
  baseUrl: string,
  path: string,
  parameters?: UrlParameters
): string {
  const stringifiedParams = parameters
    ? `?${convertToUrlParams(parameters)}`
    : "";

  return `${join(baseUrl, path)}${stringifiedParams}`;
}

function convertToUrlParams(urlParamsObject: any) {
  return (
    Object.keys(urlParamsObject)
      // remove undefined/null values
      .filter(
        (key) =>
          urlParamsObject[key] != undefined &&
          urlParamsObject[key] != null &&
          urlParamsObject[key] != ""
      )
      .map((key) => {
        const value = urlParamsObject[key];
        if (Array.isArray(value)) {
          // If the value is an array of values we have to encode it like:
          // "foo[]=bar&foo[]=baz" so Phoenix parses it properly.
          return value
            .map((val) => encodeQueryKeyAndValue(`${key}[]`, val))
            .join("&");
        } else {
          return encodeQueryKeyAndValue(key, value);
        }
      })
      .join("&")
  );
}

function encodeQueryKeyAndValue(key: string, value: string | number | boolean) {
  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
}

/**
 * Transforms HTTP Error responses into client side errors.
 */
async function transformHttpErrorResponse(errorResponse: Response) {
  const errorBody = await readBody(errorResponse);

  if (isNotFoundResponse(errorResponse)) {
    throw new Error("Not found");
  }

  if (isServerErrorResponse(errorResponse)) {
    throw new Error(
      "An unknown problem occurred, please try again, if the problem persists please contact support@tsgglobal.com."
    );
  }

  if (isErrorWithMessage(errorBody)) {
    const errorMessage = formatServerError(errorBody);
    throw new Error(errorMessage);
  }

  if (isPointerBasedChangesetError(errorBody?.errors)) {
    // this is usually the Ecto.Changeset error response
    const errorMessage = formatServerError(errorBody?.errors);
    const err = new ServerValidationError(errorMessage, errorBody);
    throw err;
  }

  if (isChangesetError(errorBody?.errors)) {
    // this is usually the Ecto.Changeset error response
    const errorMessage = formatServerError(errorBody?.errors);
    const err = new ServerValidationError(errorMessage, errorBody);
    throw err;
  }

  if (isPortalFormattedError(errorBody?.errors)) {
    const errorMessage = formatServerError(errorBody?.errors);
    throw new Error(errorMessage);
  }

  if (isSimpleError(errorBody)) {
    throw new Error(errorBody.error);
  }

  if (isApiServiceError(errorBody)) {
    const errorMessage = formatApiServiceError(errorBody);
    throw new Error(errorMessage);
  }

  // if we didn't get back json errors, we'll parse the text
  throw new Error(errorBody);
}

function readHeaders(response: any): Record<string, any> {
  return Object.fromEntries(response);
}

export function buildAuthorizationHeader(token: string, headers: object = {}) {
  return {
    ...headers,
    Authorization: `Bearer ${token}`,
  };
}
