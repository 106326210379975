import React, { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { deleteTerminationIP } from "~/api/terminationIPs";
import { Loader } from "~/components/Loader";
import { Toast } from "~/components/Toast";

export const DeleteTerminationIP = ({ id }: { id: string }) => {
  const { isLoading, mutate: deleteTerminationIPMutation } =
    useDeleteTerminationIP();

  async function deleteTerminationIP(ip: string) {
    const swal = await Toast({
      type: "Question",
      title: "Delete IP?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      showConfirmButton: true,
    });
    if (swal.isConfirmed) {
      deleteTerminationIPMutation(ip);
    }
  }

  return (
    <div>
      {isLoading ? (
        <Loader small />
      ) : (
        <span
          onClick={() => deleteTerminationIP(id)}
          className="text-red-500 px-6 py-3 text-left text-xs font-medium tracking-wider cursor-pointer"
        >
          Delete
        </span>
      )}
    </div>
  );
};

function useDeleteTerminationIP(): any {
  const queryClient = useQueryClient();
  const { isLoading, mutate, isSuccess } = useMutation(
    ["deleteTerminationIP"],
    async (id: string) => {
      await deleteTerminationIP(id);
    },
    {
      onSuccess: () => {
        Toast({ type: "Success", title: "IP Deleted" });
        queryClient.invalidateQueries(["terminationIPs"]);
      },
      onError: (error: Error) => Toast({ type: "Error", title: error.message }),
    }
  );

  return {
    isLoading,
    mutate,
    isSuccess,
  };
}
