import { ErrorMessage } from "@hookform/error-message";
import { ErrorOption, FieldErrors } from "react-hook-form";
import { ServerValidationError } from "../../utils/error";

interface ErrorProps {
  error?: Error | ErrorOption | string;
  className?: string;
}

// old error component
export function ErrorComponent({ error, className }: ErrorProps) {
  if (!error) return null;
  let errorMessage;

  if (typeof error === "string") {
    errorMessage = error;
  } else if (error instanceof ServerValidationError) {
    errorMessage = `Data validation error: ${error.message}`;
  } else {
    errorMessage = error?.message;
  }

  const classes = `Error ${className}`;

  return (
    <div>{errorMessage && <div className={classes}>{errorMessage}</div>}</div>
  );
}

interface FieldErrorProps {
  errors?: FieldErrors;
  name: string;
}

export function FieldError({ errors, name }: FieldErrorProps) {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      as={<div className="Error mt-1" />}
    />
  );
}
