import format from "date-fns/format";
import { capitalize } from "lodash-es";

// for currencies
const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
// for round numbers / integers
const integerNumberFormatter = new Intl.NumberFormat("en-US", {
  // style: "currency",
  // currency: "USD",
});

export function formatMoney(amount: string | number) {
  if (!amount) {
    return "$0.00";
  }

  if (typeof amount === "string") {
    amount = Number(amount);
  }
  return currencyFormatter.format(amount);
}

export function formatIntegerNumber(amount: string | number) {
  if (!amount) {
    return "0";
  }

  if (typeof amount === "string") {
    amount = Number(amount);
  }
  return integerNumberFormatter.format(amount);
}

export function formatDate(dateString: string | Date): string {
  return format(new Date(dateString), "MM/dd/yyyy");
}

export function formatDateTime(dateString: string | Date): string {
  return format(new Date(dateString), "MM/dd/yyyy HH:mm:ss");
}

export function formatDateAsMonthDay(dateString: string): string {
  return format(new Date(dateString), "MMM do");
}

// this ignores the timezone
export function formatDateToISO(date: Date): string {
  return format(new Date(date), "yyyy-MM-dd");
}

/**
 * Formats a constant to a human readable format.
 * E.g. "verified_sender_form" -> "Verified sender form"
 */
export function formatConstant(constant: string) {
  if (typeof constant === "string") {
    return capitalize(constant).replaceAll("_", " ");
  } else {
    // Leave a fallback just in case, it's better to not properly format something than to crash the app.
    return constant;
  }
}

export function formatNumberToPercentage(amount: string | number) {
  if (!amount) {
    return "0.00%";
  }
  if (typeof amount === "string") {
    amount = Number(amount);
  }
  return `${(amount * 100).toFixed(2)}%`;
}
