import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { Form } from "../../components/Form";
import { Input } from "../../components/Input";
import { PrimaryButton } from "../../components/PrimaryButton";
import { Link } from "react-router-dom";
import { emailValidation } from "../../services/validation";
import { requestPasswordResetApi } from "../../api/portalLogin";
import { portalName } from "../../config";
import Bugsnag, { NotifiableError } from "@bugsnag/js";

type Inputs = {
  email: string;
  code: string;
  password: string;
};

export function PortalForgotPassword() {
  let [isResetRequested, setIsResetRequested] = useState<boolean>(false);

  return (
    <div className="ForgotPassword pt-20 sm:pt-28 md:pt-40">
      <h2 className="text-white text-2xl lg:text-2xl font-bold text-center md:mb-8 mb-12">
        {portalName}
      </h2>

      <div className="ForgotPassword-FormWrapper">
        {isResetRequested ? (
          <PasswordResetRequested />
        ) : (
          <ForgotPasswordForm setIsResetRequested={setIsResetRequested} />
        )}
      </div>
    </div>
  );
}

interface ForgotPasswordFormProps {
  setIsResetRequested: (isPasswordResetRequested: boolean) => void;
}

function ForgotPasswordForm({ setIsResetRequested }: ForgotPasswordFormProps) {
  let [error, setError] = useState<string | null>(null);
  let [working, setWorking] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    requestPasswordReset(data.email);
  };

  async function requestPasswordReset(email: string) {
    if (!email) {
      setError("Email is required");
      return;
    }

    setError(null);
    setWorking(true);

    try {
      const response = await requestPasswordResetApi(email);
      setIsResetRequested(true);
    } catch (error) {
      Bugsnag.notify(error as NotifiableError);
      console.error("Error", error);
      setError((error as Error).message || "Failed to reset password");
      setWorking(false);
    }
  }

  return (
    <>
      <h3 className="text-gray-800 text-2xl lg:text-3xl font-bold text-center my-4 md:my-8">
        Forgot password
      </h3>
      <Form onSubmit={onSubmit} className="flex flex-col gap-4 p-4 md:p-8">
        <Input
          label="Email"
          name="email"
          disabled={working}
          validation={emailValidation}
          defaultValue=""
          placeholder="john.doe@example.com"
        />

        <PrimaryButton type="submit" disabled={working}>
          Request password change
        </PrimaryButton>

        {error && (
          <div className="text-red-500 py-8 w-full bg-gray-200 rounded-lg text-center">
            {error}
          </div>
        )}

        <Link to="/">Back to login</Link>
      </Form>
    </>
  );
}

function PasswordResetRequested() {
  return (
    <div className="flex flex-col gap-4 p-4 md:p-8">
      <h3 className="text-gray-800 text-2xl lg:text-3xl font-bold text-center my-4 md:mt-8 md:mb-4">
        Password reset email sent!
      </h3>

      <p>
        Your password request email has been sent, please check your email
        inbox.
      </p>

      <div>
        <Link className="underline" to="/">
          Return to login
        </Link>
        .
      </div>
    </div>
  );
}
