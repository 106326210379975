import { useQuery } from "react-query";
import { Loader, LoaderOverlay } from "../../components/Loader";
import { formatConstant, formatDateTime } from "../../utils/format";
import { useEffect, useState } from "react";
import { Layout } from "../../components/Layout";
import { Pagination } from "../../components/Pagination";
import { Input } from "../../components/Input";
import { Form } from "../../components/Form";
import { phoneNumberValidation } from "../../services/validation";
import { Label } from "../../components/Label";
import { DateRangePicker } from "../../components/DateRangePicker";
import { PrimaryButton } from "../../components/PrimaryButton";
import { FormSelect } from "../../components/Select";
import { SortableColumn } from "../../components/SortByChevron";
import { listOrders, countOrders, Order } from "~/api/order";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { divide } from "lodash";

export function Orders() {
  const [dates, setDates] = useState<Date[] | [null, null]>(() => [null, null]);
  const [publicId, setPublicId] = useState("");
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [sortBy, setSortBy] = useState<string>();

  // we'll track if we got redirected here from order creation,
  // in that case we'll highlight the created order and show a corresponding message
  const [searchParams] = useSearchParams();
  const createdOrderId = searchParams.get("created_order");

  const { data, error, isFetching, isLoading, changePage } = useOrders({
    status,
    publicId,
    type,
    addedStartDate: dates[0] ? dates[0].toISOString() : undefined,
    addedEndDate: dates[1] ? dates[1].toISOString() : undefined,
    sortBy,
  });

  const {
    data: countData,
    error: countError,
    isFetching: countIsFetching,
    isLoading: countIsLoading,
  } = useOrdersCount({
    status,
    publicId,
    type,
    addedStartDate: dates[0] ? dates[0].toISOString() : undefined,
    addedEndDate: dates[1] ? dates[1].toISOString() : undefined,
    sortBy,
  });

  function triggerSearch(data: any) {
    setType(data.type?.value);
    setPublicId(data.publicId);
    setStatus(data?.status?.value);
  }

  if (isLoading) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  if (error) {
    return <Layout>Error occured: {error.message}</Layout>;
  }

  return (
    <Layout className="Phone-Numbers">
      <h2 className="text-2xl lg:text-3xl font-bold mt-2 mb-6">Orders</h2>

      <div>
        <div className="flex flex-col">
          <div className="-my-2 sm:-mx-6 lg:-mx-8">
            <div className="py-2 mb-4 align-middle inline-block min-w-full sm:px-6 lg:px-8 relative">
              <div className="flex flex-row items-center flex-wrap w-full">
                <Form onSubmit={triggerSearch} className="w-full">
                  {/* <div className="flex flex-row">
                    <Input
                      white
                      label="Phone Number"
                      name="phoneNumber"
                      validation={phoneNumberValidation}
                      defaultValue=""
                      placeholder="19512111234, 19512111235, 19512111236"
                      disabled={false}
                      wrapperClassName="w-full mb-2"
                    />
                  </div> */}
                  <div className="flex flex-row">
                    <Input
                      wrapperClassName=""
                      white
                      label="Order ID"
                      name="publicId"
                      defaultValue=""
                      placeholder="VSF-HF00OZ671F"
                      disabled={false}
                    />

                    <FormSelect
                      wrapperClassName="ml-8"
                      name="type"
                      label="Type"
                      defaultValue={null}
                      options={orderOptions}
                    />

                    <div className="flex flex-col items-start ml-8">
                      <Label>Added date</Label>
                      <DateRangePicker
                        enableClear
                        onChange={setDates}
                        // @ts-ignore
                        value={dates}
                      />
                    </div>

                    <div className="flex self-end ml-auto">
                      <PrimaryButton
                        type="submit"
                        className="flex rounded-sm h-[42px] !w-[140px] justify-center"
                      >
                        Search <MagnifyingGlassIcon className="ml-2" />
                      </PrimaryButton>
                    </div>
                  </div>
                </Form>
              </div>
            </div>

            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 relative">
              <LoaderOverlay show={isFetching} />
              <div className="shadow overflow-x-auto overflow-y-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {/* <SortableColumn
                          columnKey="number"
                          setSortByKey={setSortBy}
                          sortBy={sortBy}
                        > */}
                        Name
                        {/* </SortableColumn> */}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Order ID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Type
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Numbers
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        <SortableColumn
                          columnKey="provisioned_date"
                          setSortByKey={setSortBy}
                          sortBy={sortBy}
                        >
                          Added date
                        </SortableColumn>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {data!.orders.map((order) => (
                      <OrderItem
                        order={order}
                        key={order.id}
                        createdOrderId={createdOrderId}
                      />
                    ))}
                  </tbody>
                </table>
                <Pagination
                  totalPages={countData.totalPages}
                  total={countData.total}
                  currentPage={data.page}
                  perPage={data.perPage}
                  onPageChange={(newPage) => changePage(newPage)}
                  type="orders"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

function OrderItem({
  order,
  createdOrderId,
}: {
  order: Order;
  createdOrderId?: string | null;
}) {
  const navigate = useNavigate();
  const isJustCreated = createdOrderId === order.id;
  const itemClasses = classNames({
    "cursor-pointer hover:bg-gray-100 transition-bg ease-out duration-150":
      true,
    "bg-indigo-50": isJustCreated,
  });
  const nameClasses = classNames({
    "px-6 py-4 whitespace-nowrap text-sm underline hover:opacity-75 transition-opacity ease-out duration-150":
      true,
    "font-bold text-indigo-600": isJustCreated,
    "font-medium text-gray-900": !isJustCreated,
  });

  const formattedNumbers = order?.data?.numbers
    ? order.data?.numbers
        .map((n) => n.number)
        .slice(0, 5)
        .join(", ")
    : "N/A";

  return (
    <tr
      key={order.id}
      className={itemClasses}
      onClick={() => navigate(`/orders/${order.id}`)}
    >
      <td className={nameClasses}>
        <Link to={`/orders/${order.id}`}>
          {order.name} {isJustCreated && "[NEWLY CREATED]"}
        </Link>
      </td>
      <td className={nameClasses}>{order.public_id}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {formatConstant(order.data.type)}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {formattedNumbers}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {formatDateTime(order.inserted_at)}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {formatConstant(order.status)}
      </td>
    </tr>
  );
}

type useOrdersArgs = {
  addedStartDate?: string;
  addedEndDate?: string;
  type: string;
  publicId: string;
  status: string;
  sortBy?: string;
};

type useOrdersReturn = {
  isLoading: boolean;
  isFetching: boolean;
  data: {
    orders: Order[];
    page: number;
    perPage: number;
  };
  changePage: (newPage: number) => void;
  error: Error;
};

const TWO_MINUTES_IN_MS = 1000 * 60 * 2;
const ORDERS_PER_PAGE = 20;

function useOrders({
  addedStartDate,
  addedEndDate,
  type,
  status,
  publicId,
  sortBy,
}: useOrdersArgs): useOrdersReturn {
  const [currentPage, setCurrentPage] = useState(1);

  // whenever the filters change, reset to the 1st page
  useEffect(() => {
    setCurrentPage(1);
  }, [addedStartDate, addedEndDate, type, status, publicId, sortBy]);

  const { isLoading, error, data, isFetching } = useQuery(
    [
      "orders",
      currentPage,
      addedStartDate,
      addedEndDate,
      type,
      status,
      publicId,
      sortBy,
    ],
    async ({}) => {
      const response = await listOrders({
        page: currentPage,
        addedStartDate,
        addedEndDate,
        type,
        status,
        public_id: publicId,
        sortBy,
      });
      return {
        orders: response.data.orders,
        page: response.data.page,
        page_size: response.data.page_size,
      };
    },
    {
      keepPreviousData: true,
      staleTime: TWO_MINUTES_IN_MS,
    }
  );

  return {
    isLoading,
    isFetching,
    error: error as Error,
    data: {
      orders: data?.orders ?? [],
      page: data?.page ?? 1,
      perPage: data?.page_size ?? ORDERS_PER_PAGE,
    },
    changePage: setCurrentPage,
  };
}

type useOrdersCountReturn = {
  isLoading: boolean;
  isFetching: boolean;
  data: {
    total: number;
    totalPages: number;
  };
  error: Error;
};

function useOrdersCount({
  addedStartDate,
  addedEndDate,
  type,
  status,
  publicId,
  sortBy,
}: useOrdersArgs): useOrdersCountReturn {
  const { isLoading, error, data, isFetching } = useQuery(
    [
      "ordersCount",
      addedStartDate,
      addedEndDate,
      type,
      status,
      publicId,
      sortBy,
    ],
    async ({}) => {
      const { data } = await countOrders({
        addedStartDate,
        addedEndDate,
        type,
        status,
        public_id: publicId,
      });

      return {
        count: data.count,
      };
    },
    {
      keepPreviousData: true,
      staleTime: TWO_MINUTES_IN_MS,
    }
  );

  const totalPages = data?.count ? Math.ceil(data.count / ORDERS_PER_PAGE) : 0;

  return {
    isLoading,
    isFetching,
    error: error as Error,
    data: {
      total: data?.count ?? 0,
      totalPages: totalPages ?? 0,
    },
  };
}

const orderOptions = [
  { value: null, label: "All" },
  { value: "verified_sender_form", label: "Verified sender form" },
  // { value: "sms_only", label: formatMarket("sms_only") },
  // { value: "tf", label: formatMarket("tf") },
  // { value: "NA", label: formatMarket("NA") },
  // { value: "SC", label: formatMarket("SC") },
  // { value: "flat_rate_us_1", label: formatMarket("flat_rate_us_1") },
  // { value: "flat_rate_canada", label: formatMarket("flat_rate_canada") },
  // { value: "COCN", label: formatMarket("COCN") },
  // {
  //   value: "flat_rate_internation",
  //   label: formatMarket("flat_rate_internation"),
  // },
];
