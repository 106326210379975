import { AccountDefaults, AccountStatus, MessagingApiKey } from "../types";
import { httpClient } from "./httpClient";

export async function getAccountData(
  companyId: string
): Promise<AccountStatus> {
  const response = await httpClient(`/companies/${companyId}/account_status`, {
    method: "GET",
  });

  return response.body;
}

export async function getAccountDefaults(
  companyId: string
): Promise<AccountDefaults> {
  const response = await httpClient(
    `/companies/${companyId}/account_defaults`,
    {
      method: "GET",
    }
  );

  return response.body;
}

export async function getMessagingApiKeys(/*companyId: string*/): Promise<
  MessagingApiKey[]
> {
  // company ID is implicitly fetched based on the user
  const response = await httpClient(`/messaging_api_keys`, {
    method: "GET",
  });

  return response.body;
}
