import React from "react";
import { IOrderDetailsNumbers } from "./types";

export const OrderDetailsNumbers = ({ numbers }: IOrderDetailsNumbers) => (
  <div className="flex flex-col justify-start w-76">
    <div className="mb-1 text-xl">
      <b>Numbers:</b>{" "}
      {!numbers?.length && <span className="font-normal">N/A</span>}
    </div>
    <ul className="flex flex-col">
      {numbers?.map(({ number, status }, i) => (
        <li key={`${number}${i}`} className="flex justify-between">
          - {number}
        </li>
      ))}
    </ul>
  </div>
);
