import { useState } from "react";
import { Form } from "../../components/Form";
import { Input, TextArea } from "../../components/Input";
import { formatDate } from "../../utils/format";
import { PrimaryButton } from "../../components/PrimaryButton";
import { createTicket } from "../../api/ticket";
import { useNavigate } from "react-router-dom";
import { FormSelect } from "../../components/Select";
import { requiredValidation } from "../../services/validation";
import { FormDatePicker } from "../../components/DatePicker";
import { Loader } from "../../components/Loader";
import { ticketTypeToTag } from "./ticket";
import { AuthenticatedState, useAuthStore } from "~/stores/authStore";

export function IpTermination() {
  const user = useAuthStore(
    (store) => (store.state as AuthenticatedState).user
  );
  const [isWorking, setIsWorking] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const account = user.company.internal_id;
  const companyName = user.company.name;

  async function submitForm(data: any) {
    const subject = "IP Termination request";

    const body = `
      <div>
        <b>IP Termination request</b> <br/> <br/>
        Account: ${account} <br/>
        Company: ${companyName} <br/>
        <br/>
        <br/>
        Type of request: ${data.requestType?.label} <br/>
        Expected date of implementation: ${
          data.expectedDate ? formatDate(data.expectedDate) : "N/A"
        } <br/>
        IP Address: <br/>
        ${data.ipAddress}
        <br/>
        Device: ${data.device || "N/A"} <br/>
        Protocol: ${data.protocol || "N/A"} <br/>
        Notes/instructions: <br/>
        ${data.notes}
        <br/>
      </div>
    `;

    const formData = new FormData();

    formData.append("subject", subject);
    formData.append("body", body);
    formData.append("tags[]", "customer_portal");
    formData.append("tags[]", ticketTypeToTag("IP termination request"));

    setIsWorking(true);

    try {
      setError(null);
      await createTicket(formData);
      navigate("/support/ticket-created");
    } catch (error: any) {
      setError("An error occurred... please try again");
      setIsWorking(false);
    }
  }

  return (
    <div className="mt-2">
      <div>
        <h2 className="">
          Want to add/remove a termination IP? Use this form.
        </h2>

        <h3 className="mt-4 text-xl font-semibold">IP termination request</h3>

        <Form onSubmit={submitForm} defaultValues={defaultValues}>
          <div className="IpAddress-MainForm mt-2 max-w-lg">
            <div className="leading-9">Customer no.:</div>
            <div className="leading-9">{account}</div>
            <div className="leading-9">Company</div>
            <div className="leading-9">{companyName}</div>
            <div className="leading-9">Type of request</div>
            <div>
              <FormSelect
                validation={requiredValidation}
                noLabel
                name="requestType"
                options={requestTypeOptions}
              />
            </div>
            <div>Expected date of implementation</div>
            <div>
              {/* <Input noLabel name="expectedDate" /> */}
              <FormDatePicker noLabel name="expectedDate" />
            </div>
            <div className="leading-9">IP Address</div>
            <div>
              <TextArea
                validation={requiredValidation}
                noLabel
                tight
                name="ipAddress"
              />
              <div className="text-sm">
                Please list all IP addresses without commas, press Enter after
                each IP address without any letters
              </div>
            </div>
            <div className="leading-9">Device</div>
            <div>
              <Input noLabel tight name="device" />
            </div>
            <div className="leading-9">Protocol</div>
            <div>
              <Input noLabel tight name="protocol" />
            </div>
            <div className="leading-9">Notes/instructions</div>
            <div>
              <TextArea noLabel tight name="notes" />
            </div>
          </div>

          <PrimaryButton
            type="submit"
            className="!w-[180px] ml-auto mr-auto mt-6 h-[42px]"
            disabled={isWorking}
          >
            {isWorking ? <Loader small /> : "Create request"}
          </PrimaryButton>
        </Form>
      </div>
    </div>
  );
}

const requestTypeOptions = [
  {
    label: "Add IP Address",
    value: "Add IP Address",
  },
  {
    label: "Remove IP Address",
    value: "Remove IP Address",
  },
];

const defaultValues = {};
