import { emailFrom, frontendUrl } from "../config";
import { httpClient } from "./httpClient";

export async function loginApi(email: string, password: string) {
  const response = await httpClient("/account/login", {
    method: "POST",
    body: {
      email,
      password,
      // this is probably not needed
      otp: null,
    },
  });

  // @ts-ignore
  const { token, user } = response.body;

  return { token, user };
}

export async function requestPasswordResetApi(email: string) {
  const response = await httpClient("/account/password", {
    method: "POST",
    body: {
      email,
      frontend_url: frontendUrl,
      from: emailFrom,
    },
  });

  return response.body;
}

export async function resetPasswordApi(password: string, token: string) {
  const response = await httpClient(`/account/password/${token}`, {
    method: "PUT",
    body: {
      password: password,
      password_confirmation: password,
      origin: "customer-portal",
    },
  });

  return response.body;
}

export async function confirmAndSetPasswordApi(
  password: string,
  token: string
) {
  const response = await httpClient(`/account/confirm-password/${token}`, {
    method: "PUT",
    body: {
      password: password,
      password_confirmation: password,
      origin: "customer-portal",
    },
  });

  return response.body;
}

export async function getUserInfo(options?: object) {
  const response = await httpClient("/account/user", options);
  return response.body;
}
