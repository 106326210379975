import React, { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createTerminationIP } from "~/api/terminationIPs";
import { Button } from "~/components/Button";
import { Form } from "~/components/Form";
import { Input } from "~/components/Input";
import { Loader } from "~/components/Loader";
import { Toast } from "~/components/Toast";

export const CreateTerminationIP = () => {
  const { isLoading, mutate: createTerminationIPMutation } =
    useCreateTerminationIP();

  function createTerminationIP(formData: { ip: string }) {
    createTerminationIPMutation(formData.ip);
  }

  return (
    <>
      <div>Add new termination IP</div>
      <Form
        onSubmit={createTerminationIP}
        className="flex justify-start h-10 mb-10 mt-2"
      >
        <div className="h-full flex flex-col justify-center mr-5">IP</div>
        <Input
          inputClassName="h-full"
          name="ip"
          validation={{ required: true }}
          noLabel
        />
        <Button
          disabled={isLoading}
          light
          className="bg-green-500 hover:bg-green-600 text-white h-full ml-5 w-24"
        >
          {isLoading ? <Loader small /> : "Add"}
        </Button>
      </Form>
    </>
  );
};

function useCreateTerminationIP(): any {
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(
    ["createTerminationIP"],
    async (ip: string) => {
      await createTerminationIP(ip);
    },
    {
      onSuccess: () => {
        Toast({ type: "Success", title: "IP Created" });
        queryClient.invalidateQueries(["terminationIPs"]);
      },
      onError: (error: Error) => Toast({ type: "Error", title: error.message }),
    }
  );

  return {
    isLoading,
    mutate,
  };
}
