import { groupBy } from "lodash-es";
import { formatConstant } from "./format";
import { UseFormReturn } from "react-hook-form";

// extendable error so we can extend and more easily differentiate between them
// taken from: https://stackoverflow.com/questions/31089801/extending-error-in-javascript-with-es6-syntax-babel
class ExtendableError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

type FieldErrorObject = {
  [key: string]: string;
};

type PointerBasedErrorResponse = {
  errors: PointerBasedErrorItem[];
};

type PointerBasedErrorItem = {
  id: string;
  message: string;
  source: {
    pointer: string;
  };
  title: string;
};

type ChangesetErrorResponse = {
  errors: Record<string, string[] | Record<string, string[]>>;
};

type PortalServerErrorResponse = {
  errors: { id: string; message: string }[];
};

type ChangesetError = Record<string, string[] | Record<string, string[]>>;

// custom type guards to differentiate error responses
function isChangesetErrorResponse(error: any): error is ChangesetErrorResponse {
  const keys = Object.keys(error.errors);
  return (
    (keys.length > 0 &&
      Array.isArray(error.errors[keys[0]]) &&
      error.errors[keys[0]].length >= 1) ||
    typeof error.errors[keys[0]] === "object"
  );
}

export const isPointerBasedErrorResponse = (
  error: any
): error is PointerBasedErrorResponse =>
  (error as PointerBasedErrorResponse).errors.length !== undefined;

export const isChangesetError = (error: any): error is ChangesetError => {
  if (!error) return false;
  if (error.length) return false;

  const keys = Object.keys(error as ChangesetError);
  // can be:
  //   {errors: {id: ["can't be blank"]}},
  //   or
  //   {errors: {data: {attachments: ["can't be blank"]}}}
  return (
    typeof keys[0] === "string" &&
    (error[keys[0]].length >= 1 || typeof error[keys[0]] === "object")
  );
};

export const isPortalServerErrorResponse = (
  error: any
): error is PortalServerErrorResponse => {
  return (
    typeof error[0]?.id === "string" && typeof error[0]?.message === "string"
  );
};

// Example pointer based error response:
//
// {
//   errors: [
//     {
//       id: "invalid_params",
//       message: "is invalid",
//       source: { pointer: "/destination" },
//       title: "Invalid parameter.",
//     },
//     {
//       id: "invalid_params",
//       message: "is invalid",
//       source: { pointer: "/numbers" },
//       title: "Invalid parameter.",
//     },
//   ],
// };

function pointerErrorToFieldError(error: PointerBasedErrorResponse) {
  const errorFields = error.errors.map((error) => ({
    field: error.source.pointer.replace("/", ""),
    message: error.message,
  }));

  const groupedErrorFields = groupBy(
    errorFields,
    (errorField) => errorField.field
  );

  return Object.keys(groupedErrorFields).reduce((acc, key) => {
    const errors = groupedErrorFields[key].map((f) => f.message);
    acc[key] = `${key} ${errors.join(", ")}`;
    return acc;
  }, {} as FieldErrorObject);
}

function portalServerErrorsToFieldError(error: PortalServerErrorResponse) {
  const errorFields = error.errors.reduce((acc, error) => {
    acc[error.id] = error.message;
    return acc;
  }, {} as FieldErrorObject);

  return errorFields;
}

export class ServerValidationError extends ExtendableError {
  errors: FieldErrorObject;
  message: string;

  constructor(
    message: string,
    error: PointerBasedErrorResponse | ChangesetErrorResponse
  ) {
    super(message);
    this.message = message;

    if (isPointerBasedErrorResponse(error)) {
      this.errors = pointerErrorToFieldError(error);
    } else if (isChangesetErrorResponse(error)) {
      // @ts-ignore
      this.errors = !!error?.errors?.data ? error.errors.data : error.errors;
    } else if (isPortalServerErrorResponse(error)) {
      this.errors = portalServerErrorsToFieldError(error);
    } else {
      // this shouldn't happen
      this.errors = { unknown: "An unknown error occured" };
    }
  }
}

// With the ExtendableError this works:
//
// const myerror = new ExtendableError("ext error");
// console.log(myerror.message);
// console.log(myerror instanceof Error);
// console.log(myerror.name);
// console.log(myerror.stack);

export function formatServerError(error: any) {
  if (isRegularServerError(error)) {
    return error.message;
  } else if (isPointerBasedChangesetError(error)) {
    return error
      .map(
        (error: any) =>
          `${error.source.pointer.replace("/", "")} ${error.message}`
      )
      .join(", ");
  } else if (isPortalFormattedError(error)) {
    return error.map((error: any) => error.message).join(" \n");
  } else if (isChangesetError(error)) {
    const err = !!error?.data ? (error.data as ErrorMap) : error;
    return formatChangesetError(err);
  } else if (error.message) {
    return error.message;
  } else {
    return error;
  }
}

// {
//   "errors": {
//     "data": { "numbers": [{ "number": ["not a toll-free number"] }] }
//   }
// }

type ErrorMap = { [key: string]: string[] | ErrorMap };

function formatChangesetError(
  errorMap: ErrorMap,
  parentKey: string = ""
): string {
  return Object.keys(errorMap)
    .map((key) => {
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
      const value = errorMap[key];

      if (Array.isArray(value)) {
        return value
          .map((item, index) => {
            if (typeof item === "object" && !Array.isArray(item)) {
              return formatChangesetError(item, `${fullKey}[${index}]`);
            } else {
              return `${fullKey} ${item}`;
            }
          })
          .join(", \n");
      } else if (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
      ) {
        return formatChangesetError(value, fullKey);
      } else {
        return `${fullKey} ${value}`;
      }
    })
    .join(", \n");
}

export function formatApiServiceError(error: any) {
  if (error.error_message) {
    return error.error_message;
  }

  if (error.reason) {
    return formatConstant(error.reason);
  }

  return "Unknown error occured";
}

// some weird way Portal encodes errors
export function isPointerBasedChangesetError(errors: any): boolean {
  if (!errors?.length) return false;

  return (
    errors[0].message && errors[0].source && errors[0].id && errors[0].title
  );
}

export function isRegularServerError(error: any): boolean {
  return typeof error.message === "string" && Object.keys(error).length === 1;
}

export function isPortalFormattedError(errors: any): boolean {
  if (!errors) return false;
  if (!errors.length) return false;
  return errors[0].message && errors[0].id;
}

export function isErrorWithMessage(error: any): boolean {
  return !!error.message;
}

export function isApiServiceError(error: any): boolean {
  // Example of an API Service error
  //
  // {
  //   "error_info":{"number":"12003004001","action":"add"},
  //   "reason":"number_already_enabled",
  //   "error_message":null,
  //   "error_code":null
  // }
  return error.error_info !== undefined && error.reason != undefined;
}

export function isSimpleError(error: any): boolean {
  return typeof error?.error === "string";
}

export function isNotFoundResponse(response: Response) {
  return response.status === 404;
}

export function isServerErrorResponse(response: Response) {
  return response.status >= 500 && response.status < 600;
}

export function isGraphQlError(error: any): boolean {
  return typeof error?.data === "object" && typeof error?.errors === "object";
}
export function formatGraphQlError(error: any): string {
  return error.errors
    .reduce((acc: any, err: any) => {
      let errors;
      if (typeof err.meta === "object" && err.meta !== null) {
        const keys = Object.keys(err.meta);
        errors = keys.map((key) => `${key} ${err.meta[key]}`).join("\n");
      } else if (err.meta === null) {
        errors = err.message;
      } else {
        errors = err.meta;
      }

      return acc.concat(errors);
    }, [])
    .join("\n");
}

// TODO - convert all errors to this type and handle them
// export type ServerError = {
//   statusCode: number;
//   message: string;
//   error?: any;
// }

/**
 * Function applies the error to the react-hook-form,
 * it takes the errors map object and the setError function
 * from `useForm`.
 *
 * No TS types were added since that'd be too complex to type
 * and wouldn't add much.
 */
export function setErrorsOnForm(
  errors: object,
  methods: UseFormReturn<any>,
  replacementKeys?: Record<string, string>
) {
  Object.keys(errors).forEach((field) => {
    // @ts-ignore
    if (Array.isArray(errors[field]) && typeof errors[field][0] !== "string") {
      // @ts-ignore
      errors[field].forEach((subFieldError, index) => {
        Object.keys(subFieldError).forEach((subField) => {
          const defaultKey = `${field}[${index}].${subField}`;
          const key =
            replacementKeys && defaultKey in replacementKeys
              ? replacementKeys[defaultKey]
              : defaultKey;

          methods.setError(key, {
            type: "manual",
            message: formatErrorMessage(subFieldError[subField]),
          });
        });
      });
    } else {
      const key =
        replacementKeys && field in replacementKeys
          ? replacementKeys[field]
          : field;
      methods.setError(key, {
        type: "manual",
        // @ts-ignore
        message: formatErrorMessage(errors[field]),
      });
    }
  });

  // scroll to the first error
  const firstError = Object.keys(methods.formState.errors)[0];
  methods.setFocus(firstError);
}

function formatErrorMessage(errorMessage: string[]): string {
  const msg = errorMessage.join(", ");
  return msg[0].toLocaleUpperCase() + msg.slice(1);
}
