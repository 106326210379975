import classNames from "classnames";
import React from "react";
import {
  Controller,
  RegisterOptions,
  useFormContext,
  useFormState,
  Ref,
} from "react-hook-form";
import { FieldError } from "../Error";
import { Label } from "../Label";

import DatePickerComponent from "react-date-picker/dist/entry.nostyle";
import "react-date-picker/dist/DatePicker.css";
// import "react-calendar/dist/Calendar.css";
// import "react-clock/dist/Clock.css";

type DatePickerProps = {
  value: any;
  onChange: (value: any) => void;
  id?: string;
  enableClear?: boolean;
};

// React-Hook-Form's Controller
export const DatePicker = React.forwardRef(
  (
    { value, onChange, enableClear, ...props }: DatePickerProps,
    ref: React.ForwardedRef<Ref>
  ) => {
    const clearIcon = enableClear === true ? undefined : null;
    return (
      <DatePickerComponent
        className="DatePicker"
        clearIcon={clearIcon}
        onChange={onChange}
        value={value}
        {...props}
      />
    );
  }
);

type FormDatePickerProps = {
  label?: string;
  name: string;
  validation?: RegisterOptions;
  wrapperClassName?: string;
  // selectClassName?: string;
  value?: any;
  onChange?: (value: any) => void;
  // white?: boolean;
  noLabel?: boolean;
  tight?: boolean;
  enableClear?: boolean;
  defaultValue?: any;
};

export function FormDatePicker({
  validation,
  wrapperClassName,
  name,
  label,
  noLabel,
  tight,
  enableClear,
  defaultValue = null,
}: FormDatePickerProps) {
  const { control } = useFormContext();
  const { errors } = useFormState();

  const classes = classNames({
    "DatePicker-Wrapper": true,
    [`${wrapperClassName}`]: wrapperClassName,
    "DatePicker-No-Label": noLabel,
    "DatePicker-Tight": tight,
  });

  return (
    <div className={classes}>
      <Label htmlFor={name}>{label}</Label>

      <Controller
        name={name}
        control={control}
        rules={validation}
        defaultValue={defaultValue}
        render={({ field }) => (
          <DatePicker enableClear={enableClear} {...field} />
        )}
      />

      <FieldError errors={errors} name={name} />
    </div>
  );
}
