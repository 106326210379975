import { RecordType } from "../../types";
import { formatIntegerNumber, formatMoney } from "../../utils/format";

type StatsProps = {
  type: RecordType;
  data?: any;
};

export function Stats({ type, data }: StatsProps) {
  const totalUnits = data?.usageData.reduce((acc: number, entry: any) => {
    return acc + entry["all"];
  }, 0);
  // const totalUnitsInbound = data?.usageData.reduce((acc: number, entry: any) => {
  //   return acc + entry['INBOUND']
  // }, 0)
  // const totalUnitsOutbound = data?.usageData.reduce((acc: number, entry: any) => {
  //   return acc + entry['OUTBOUND']
  // }, 0)
  const totalSpent = data?.billData.reduce((acc: number, entry: any) => {
    return acc + entry["all"];
  }, 0);

  let duration = null;
  if (type === "VOICE") {
    duration = data?.durationData.reduce((acc: number, entry: any) => {
      return acc + entry["all"];
    }, 0);
  }

  const unit = unitsMap[type] || "units";

  return (
    <div>
      <dl className="flex flex-row flex-wrap gap-6">
        <div
          key="total-spent"
          className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-4 sm:pl-6 sm:pr-10"
        >
          <dt className="text-sm font-medium text-gray-500 truncate">
            Total spent
          </dt>
          <dd className="mt-0 text-xl font-semibold text-gray-900">
            {formatMoney(totalSpent)}
          </dd>
        </div>

        <div
          key="total-units"
          className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-4 sm:pl-6 sm:pr-10"
        >
          <dt className="text-sm font-medium text-gray-500 truncate">
            Total {unit}
          </dt>
          <dd className="mt-0 text-xl font-semibold text-gray-900">
            {formatIntegerNumber(totalUnits)}
          </dd>
        </div>

        {/* <div key="traffic-ration" className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-4 sm:pl-6 sm:pr-10">
          <dt className="text-sm font-medium text-gray-500 truncate">Inbound / Outbound units</dt>
          <dd className="mt-0 text-xl font-semibold text-gray-900">{formatIntegerNumber(totalUnitsInbound)} / {formatIntegerNumber(totalUnitsOutbound)}</dd>
        </div> */}

        {type === "VOICE" && (
          <div
            key="total-duration"
            className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-4 sm:pl-6 sm:pr-10"
          >
            <dt className="text-sm font-medium text-gray-500 truncate">
              Total duration (sec)
            </dt>
            <dd className="mt-0 text-xl font-semibold text-gray-900">
              {formatIntegerNumber(duration)}
            </dd>
          </div>
        )}
      </dl>
    </div>
  );
}

const unitsMap = {
  VOICE: "calls",
  SMS: "SMSes",
  MMS: "MMSes",
  API: "API calls",
};
