import { PeriodType, RecordType } from "../types";
import { httpClient } from "./httpClient";

type getAnalyticsRecordsArgs = {
  periodType: PeriodType;
  recordType: RecordType;
  startDate?: string;
  endDate?: string;
};

export async function getAnalyticsRecords({
  periodType,
  recordType,
  startDate,
  endDate,
}: getAnalyticsRecordsArgs) {
  const recordTypeParam = recordType.toLocaleLowerCase();
  const periodTypeParam = periodType.toLocaleLowerCase();
  const url = `/analytics/${recordTypeParam}/${periodTypeParam}`;
  const response = await httpClient(url, {
    method: "GET",
    parameters: {
      start_date: startDate,
      end_date: endDate,
    },
  });

  return response.body;
}
