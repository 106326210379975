import { httpClient } from "./httpClient";

type AutocompleteRecord = {
  user_id: string;
  user_first_name: string;
  user_last_name: string;
  user_email: string;
  company_id: string;
  company_name: string;
  company_internal_id: string;
};

export async function autocompleteUsersAndCompanies(
  query: string,
  config?: object
): Promise<AutocompleteRecord[]> {
  const response = await httpClient("/users/names_and_companies", {
    parameters: {
      query,
    },
    ...config,
  });

  return response.body.results;
}

export async function getImpersonificationToken(
  companyId: string,
  config: object = {}
): Promise<string> {
  const response = await httpClient(
    `/companies/${companyId}/impersonation_token`,
    {
      body: {
        company_id: companyId,
      },
      ...config,
    }
  );
  return response.body.token;
}
