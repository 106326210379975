import classNames from "classnames";
import React from "react";
import {
  Controller,
  RegisterOptions,
  useFormContext,
  useFormState,
} from "react-hook-form";
import SelectComponent, { components, InputProps } from "react-select";
import { FieldError } from "../Error";
import { Required } from "../Input/Required";
import { Label } from "../Label";

type SelectProps = {
  value: any;
  defaultValue?: any;
  onChange: (value: any) => void;
  readonly options: SelectOptions;
  id?: string;
  className?: string;
  placeholder?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
};

// We have to mark the types as readonly on every level
// to stop the typescript compiler from complaining.
type SelectOptions = readonly SelectOption[];

type SelectOption = {
  readonly label: string;
  readonly value: any;
};

// Use this to override the default hidden input and prevent Lastpass from breaking the styles
const CustomInput = (props: InputProps<any, false>) => (
  <components.Input {...props} data-lpignore="true" />
);

const customComponents = {
  Input: CustomInput,
};

export const Select = React.forwardRef<HTMLInputElement, SelectProps>(
  ({ value, onChange, options, className, ...props }, ref) => {
    const classes = classNames({
      Select: true,
      [`${className}`]: className,
    });

    return (
      <SelectComponent
        classNamePrefix="Select"
        className={classes}
        value={value}
        options={options}
        onChange={onChange}
        defaultValue={props.defaultValue}
        components={customComponents}
        {...props}
        // the compiler is complaining here, but it might be to bad Typescript types in the underlying lib or something
        // so let's just ignore it
        ref={ref as any}
      />
    );
  }
);

type FormFieldProps = {
  label?: string;
  name: string;
  validation?: RegisterOptions;
  wrapperClassName?: string;
  value?: any;
  white?: boolean;
  noLabel?: boolean;
  tight?: boolean;
  required?: boolean;
  isDisabled?: boolean;
};

type FormSelectProps = FormFieldProps & Omit<SelectProps, "value" | "onChange">;

export function FormSelect({
  defaultValue = null,
  validation,
  wrapperClassName,
  className,
  name,
  label,
  noLabel,
  options,
  tight,
  placeholder,
  isClearable,
  required,
  isDisabled,
}: FormSelectProps) {
  const { control } = useFormContext();
  const { errors } = useFormState();

  const classes = classNames({
    "Select-Wrapper": true,
    [`${wrapperClassName}`]: wrapperClassName,
    "Select-No-Label": noLabel,
    "Select-Tight": tight,
  });

  return (
    <div className={classes}>
      <Label htmlFor={name}>
        {label}
        <Required required={required} />
      </Label>

      <Controller
        name={name}
        control={control}
        rules={validation}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Select
            {...field}
            placeholder={placeholder}
            defaultValue={defaultValue}
            options={options}
            className={className}
            isClearable={isClearable}
            isDisabled={isDisabled}
          />
        )}
      />

      <FieldError errors={errors} name={name} />
    </div>
  );
}
