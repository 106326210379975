import { useState } from "react";
import { useQuery } from "react-query";
import {
  useLocation,
  Link,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { replaceCodeWithToken } from "../../api/token";
import { Loader } from "../../components/Loader";
import { portalName } from "../../config";
import { useAuthStore } from "~/stores/authStore";

export function AuthCallback() {
  const loginViaToken = useAuthStore((store) => store.loginViaToken);

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const [error, setError] = useState(() => {
    const errorMessage = searchParams.get("error");
    if (errorMessage) {
      return errorMessage;
    } else if (location.pathname.includes("error")) {
      return "Unknown error, failed to log you in.";
    } else if (!code) {
      return "The authentication code is missing.";
    }

    return null;
  });

  useQuery(
    ["code", code],
    async () => {
      const { token } = await replaceCodeWithToken(code!);
      await loginViaToken(token);
      navigate("/", { replace: true });
    },
    {
      retry: false,
      onError(error) {
        setError((error as Error).message);
      },
      enabled: !!code,
    }
  );

  if (error) {
    return (
      <div className="LogIn py-40">
        <div className="ForgotPassword pt-20 sm:pt-28 md:pt-40">
          <h2 className="text-white text-2xl lg:text-2xl font-bold text-center md:mb-8 mb-12">
            {portalName}
          </h2>

          <div className="ForgotPassword-FormWrapper">
            <h3 className="text-gray-800 text-2xl lg:text-3xl font-bold text-center my-4 md:my-8">
              Authentication failed
            </h3>
            <div className="px-4 md:px-8">
              The authentication didn't succeed, please go to log in and try
              again or use a different login if it doesn't work.
              <br />
              <br />
              <b>Error info:</b> {error}
            </div>

            <Link className="text-center p-4 py-8 underline" to="/">
              Back to login
            </Link>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="LogIn py-40">
        <div className="ForgotPassword pt-20 sm:pt-28 md:pt-40">
          <h2 className="text-white text-2xl lg:text-2xl font-bold text-center md:mb-8 mb-12">
            {portalName}
          </h2>

          <div className="ForgotPassword-FormWrapper">
            <h3 className="text-gray-800 text-2xl lg:text-3xl font-bold text-center my-4 md:my-8">
              Redirecting to app...
            </h3>
            <div className="p-4 pb-12">
              <Loader medium />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
