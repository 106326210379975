import { range } from "lodash-es";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  total: number;
  perPage: number;
  onPageChange: (pageNumber: number) => void;
  type?: string;
};

export function Pagination({
  currentPage,
  totalPages,
  total,
  perPage,
  onPageChange,
  type = "items",
}: PaginationProps) {
  const from = Math.min((currentPage - 1) * perPage + 1, total);
  const to = Math.min(currentPage * perPage, total);
  const isPreviousDisabled = currentPage <= 1;
  const isNextDisabled = currentPage >= totalPages;

  const isReadyToRender = from >= 0 && to >= 0 && total >= 0;

  function previousPage() {
    onPageChange(currentPage - 1);
  }

  function nextPage() {
    onPageChange(currentPage + 1);
  }

  const pages = visiblePages(currentPage, totalPages, 5);

  if (!isReadyToRender) return null;

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          disabled={isPreviousDisabled}
          onClick={previousPage}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Previous
        </button>
        <button
          disabled={isNextDisabled}
          onClick={nextPage}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{from}</span> to{" "}
            <span className="font-medium">{to}</span> of{" "}
            <span className="font-medium">{total}</span> {type}
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              disabled={isPreviousDisabled}
              onClick={previousPage}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {pages.map(({ pageNumber, key, enabled }) =>
              enabled ? (
                <button
                  key={key}
                  onClick={() => onPageChange(pageNumber)}
                  aria-current="page"
                  className={`${
                    pageNumber == currentPage
                      ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative "
                      : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                  } relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                >
                  {pageNumber}
                </button>
              ) : (
                <span
                  key={key}
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                >
                  ...
                </span>
              )
            )}
            <button
              disabled={isNextDisabled}
              onClick={nextPage}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

/**
 * Generates visible pages and "truncated" buttons.
 *
 * This will generate Pagination structures depending on where the active page is
 * and how many pages are there.
 * Number of visible pages should always be odd, otherwise the calcualtions are off and everything looks weird.
 */
function visiblePages(
  currentPage: number,
  totalPages: number,
  numberOfVisiblePages: number = 5
): VisiblePage[] {
  let startPage = 1;
  let endPage = 1;

  // represents the visible page offset from the center,
  // e.g. if you have 5 visible pages and the current page is 4
  // then the offset is (5 - 1) / 2 = 2, then the lowest visible page is 4 - 2 = 2
  // and the highest visible page is 4 + 2 = 6
  const visiblePageOffset = (numberOfVisiblePages - 1) / 2;

  // if the active page is on the start/left side, e.g. if page 1 is active
  // we'll generate the following buttons: [1], [2], [3], [4], [5], [...]
  if (currentPage <= Math.ceil(numberOfVisiblePages / 2)) {
    startPage = 1;
    endPage = Math.min(numberOfVisiblePages, totalPages);

    // if the active page is on the end/right side, e.g. page 10 is active and we have 10 pages,
    // we'll generate the following buttons: [...], [6], [7], [8], [9], [10]
  } else if (currentPage > totalPages - numberOfVisiblePages) {
    startPage = Math.max(totalPages - numberOfVisiblePages, 1);
    endPage = totalPages;

    // if the active page is somewhere in the middle, e.g. we have 10 pages and page 5 is active:
    // [...], [3], [4], [5], [6], [7], [...]
  } else {
    startPage = currentPage - visiblePageOffset;
    endPage = Math.min(currentPage + visiblePageOffset, totalPages);
  }

  let pages: VisiblePage[] = range(startPage, endPage + 1).map((page) => ({
    pageNumber: page,
    key: page,
    enabled: true,
  }));

  // if startPage > Math.ceil(numberOfVisiblePages / 2)
  if (startPage > 1) {
    pages = [leftTruncatedPage, ...pages];
  }

  if (endPage < totalPages) {
    pages = [...pages, rightTruncatedPage];
  }

  return pages;
}

type VisiblePage = NavigatablePage | TruncatedPage;

type NavigatablePage = {
  pageNumber: number;
  key: number | string;
  enabled: true;
};

type TruncatedPage = {
  pageNumber: "...";
  key: "-..." | "+...";
  enabled: false;
};

const leftTruncatedPage: TruncatedPage = {
  pageNumber: "...",
  key: "-...",
  enabled: false,
} as const;

const rightTruncatedPage: TruncatedPage = {
  pageNumber: "...",
  key: "+...",
  enabled: false,
} as const;
