import React from 'react';
import { format } from 'date-fns';
import { IOrderDetailsStatus } from './types';

export const OrderDetailsStatus = ({
  status,
  inserted_at,
  updated_at,
  finished_at,
}: IOrderDetailsStatus) => (
  <div className="flex flex-col justify-start mb-10 w-96">
    <div className="mb-2 text-xl capitalize">
      <b>Status:</b> {status}
    </div>
    <div className="flex flex-col">
      {inserted_at && (
        <div className="flex justify-between">
          <b>Submitted on:</b>{' '}
          <span>{format(new Date(inserted_at), 'MM/dd/yyyy hh:mm:ss')}</span>
        </div>
      )}
      {updated_at && (
        <div className="flex justify-between">
          <b>Last updated on:</b>{' '}
          <span>{format(new Date(updated_at), 'MM/dd/yyyy hh:mm:ss')}</span>
        </div>
      )}
      {finished_at && (
        <div className="flex justify-between">
          <b>Completed on:</b>{' '}
          <span>{format(new Date(finished_at), 'MM/dd/yyyy hh:mm:ss')}</span>
        </div>
      )}
    </div>
  </div>
);
