import { Layout } from "../../components/Layout";
import { Input, TextArea } from "../../components/Input";
import { Form } from "../../components/Form";
import {
  multiplePhoneNumbersValidation,
  requiredValidation,
} from "../../services/validation";
import { PrimaryButton } from "../../components/PrimaryButton";
import { useMutation } from "react-query";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { batchUpdateDestination } from "../../api/phoneNumbers";
import { groupBy, trim } from "lodash-es";
import { Loader } from "../../components/Loader";
import { ErrorComponent } from "../../components/Error";
import { ServerValidationError } from "../../utils/error";
import { useState } from "react";

type formValues = {
  destination: string;
  numbers: string;
};

type requestValues = {
  destination: string;
  numbers: string[];
};

type UpdatedNumberSuccessType = string;
type UpdatedNumberErrorType = { [key: string]: string };

export function BatchUpdateNumbers() {
  const methods = useForm<formValues>({ mode: "onSubmit" });
  const [updatedNumbers, setUpdatedNumbers] =
    useState<UpdatedNumberSuccessType>();
  const [numberUpdateErrors, setNumberUpdateErrors] =
    useState<UpdatedNumberErrorType>();

  // @ts-ignore
  const mutation = useMutation(
    (formData: requestValues) => {
      setUpdatedNumbers(undefined);
      setNumberUpdateErrors(undefined);

      return batchUpdateDestination(formData.destination, formData.numbers);
    },
    {
      onError: (error, _variables, _ctx) => {
        if (error instanceof ServerValidationError) {
          for (const field in error.errors) {
            if (field === "numbers" || field === "destination") {
              methods.setError(field, { message: error.errors[field] });
            }
          }
        }
      },
      onSuccess: (data, _variables, _ctx) => {
        const updatedNumbers = data.success.join(", ");
        const failedToUpdateNumbersGroup = groupBy(
          data.error,
          ([_number, reason]) => reason
        );

        const numberUpdateErrors = Object.keys(
          failedToUpdateNumbersGroup
        ).reduce((acc, key) => {
          const numbers = failedToUpdateNumbersGroup[key].map(
            ([number, _reason]) => number
          );
          acc[key] = `${numbers.join(", ")}`;
          return acc;
        }, {} as UpdatedNumberErrorType);

        setUpdatedNumbers(updatedNumbers);
        setNumberUpdateErrors(numberUpdateErrors);
      },
    }
  );

  const submitNumbers: SubmitHandler<formValues> = (data) => {
    const numbers = data.numbers.split(/\ |,|\n/).map((str) => trim(str));

    mutation.mutate({
      destination: data.destination,
      numbers,
    });
  };

  const showResults = methods.formState.isSubmitted && !mutation.isLoading;

  return (
    <Layout className="Phone-Numbers">
      <h2 className="text-2xl lg:text-3xl font-bold mt-2">
        Voice batch update numbers
      </h2>
      <div className="mb-6">
        You can update multiple numbers to a desired destination here, this is
        for VoIP calls.
      </div>

      <div className="max-w-2xl">
        <ErrorComponent error={mutation.error as Error} />

        {showResults && (
          <BatchUpdateStatus
            updatedNumbers={updatedNumbers}
            numberUpdateErrors={numberUpdateErrors}
          />
        )}

        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(submitNumbers)}
            autoComplete="off"
          >
            <Input
              required
              validation={requiredValidation}
              name="destination"
              label="Destination"
              wrapperClassName="mt-12"
            />
            <TextArea
              required
              name="numbers"
              label="Phone numbers"
              wrapperClassName="mt-8"
              inputClassName="min-h-[200px]"
              validation={multiplePhoneNumbersValidation}
            />
            <div className="text-sm text-slate-600 mt-2">
              <b>Note:</b> Input your numbers as 11-digit, comma or new-line
              separated values , e.g. "12024561234, 12024561235, 12024561236".
            </div>

            <PrimaryButton
              type="submit"
              className="!w-[15rem] min-w-0 mt-10"
              disabled={mutation.isLoading}
            >
              {mutation.isLoading ? <Loader small /> : "Submit"}
            </PrimaryButton>
          </form>
        </FormProvider>
      </div>
    </Layout>
  );
}

type BatchUpdateStatusProps = {
  updatedNumbers?: UpdatedNumberSuccessType;
  numberUpdateErrors?: UpdatedNumberErrorType;
};

function BatchUpdateStatus({
  updatedNumbers,
  numberUpdateErrors,
}: BatchUpdateStatusProps) {
  return (
    <>
      <h3 className="text-xl font-bold pb-4">Batch update results</h3>

      <div className="pb-4">
        <span className="font-bold">Numbers updated:</span>
        <br />
        {updatedNumbers || "None"}
      </div>

      {numberUpdateErrors &&
        Object.keys(numberUpdateErrors).map((reason) => (
          <div key={reason} className="pb-4">
            <span className="font-bold">{reason}:</span>
            <br />
            {numberUpdateErrors[reason]}
          </div>
        ))}
    </>
  );
}
