import { useMemo, useRef, useState } from "react";
import { Form } from "../../components/Form";
import { FormFileInput, Input, TextArea } from "../../components/Input";
import { formatDate } from "../../utils/format";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Button } from "../../components/Button";
import { Alert } from "../../components/Alert";
import { PrimaryButton } from "../../components/PrimaryButton";
import { PlusSmallIcon } from "@heroicons/react/24/solid";
import { createTicket } from "../../api/ticket";
import { useNavigate } from "react-router-dom";
import { FormSelect } from "../../components/Select";
import { requiredValidation } from "../../services/validation";
import { Loader } from "../../components/Loader";
import { ticketTypeToTag } from "./ticket";
import { AuthenticatedState, useAuthStore } from "~/stores/authStore";

export function LocalIncomingPortIn() {
  const [formattedDate] = useState(() => formatDate(new Date()));
  const user = useAuthStore(
    (store) => (store.state as AuthenticatedState).user
  );
  const [isWorking, setIsWorking] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const account = user.company.internal_id;
  const companyName = user.company.name;

  async function submitForm({ records }: { records: PortInRecord[] }) {
    const title = `local_incoming_portin_${account}_${formattedDate}.csv`;
    const subject = "Local Incoming Port-In";
    const body = `
      <div>
        <b>Local Incoming Port-In Request</b> <br/>
        Account: ${account} <br/>
        Company: ${companyName} <br/>
        <br/>
        Attached is the CSV file of the request and the LOA/COB file.
      </div>
    `;

    const formData = new FormData();

    // workaround for multiple attachments - FormData for some reason doesn't support a FileList object (which you get with <input type="file" multiple ... />)
    formData.delete("attachments[]");

    const csvHeaders = `"Product","CustomerName","NPA","NXX","DNIS","ServiceAddress","BTN","LoaDate","RequestedPortDate","T.38 required","DidDestinationIP","CustomerNotes"`;
    const recordsAsCsv = records
      .filter((record) => !isRecordEmpty(record))
      .map(formatRecordAsCsv)
      .join("\n");

    const csvFile = `${csvHeaders}\n${recordsAsCsv}`;

    const fileBlob = new Blob([csvFile], { type: "text/csv" });

    formData.append("attachments[]", fileBlob, title);

    const files = (fileInputRef.current as any).files;
    [...files].forEach((file: File) => formData.append("attachments[]", file));

    formData.append("subject", subject);
    formData.append("body", body);
    formData.append("tags[]", "customer_portal");
    formData.append("tags[]", ticketTypeToTag("Local incoming port-in"));

    setIsWorking(true);

    try {
      setError(null);
      await createTicket(formData);
      navigate("/support/ticket-created");
    } catch (error: any) {
      setError("An error occurred... please try again");
      setIsWorking(false);
    }
  }

  return (
    <div>
      <div>
        <Alert
          className="mt-6 mb-8 max-w-3xl"
          title="Instructions"
          text={
            <>
              <div>
                Please only submit one order at a time. Orders should always be
                separated by BTN and/or NPA-NXX. Customer Name, spelling of
                Customer Name, and abbreviations/format of address information
                MUST match customers information listed on their Customer
                Service Record (CSR). If you are unsure of this information,
                please contact the local carrier prior to placing your order.
                Numbers entered on this LNP form must be associated with main
                BTN (Billing Telephone Number). If these guidelines are not met
                the order will not be processed. Please scan LOA and Bill Copy
                and upload via the button below.
              </div>
              <br />
              <br />
              <b>Attention:</b> All LOAs and COBs must now be signed and dated
              within 30 days of the request being submitted to TSG.
              <br />
              <br />
              If you select a requested port-in date, it MUST be no less than 10
              business days from the date your order was submitted. Your port-in
              date is not guaranteed until your order has been Accepted and with
              a FOC received.
            </>
          }
        />

        <div>
          <b>Step 1:</b> LOA/COB Files MUST be uploaded first before placing any
          order.
        </div>
        <div className="mt-1">
          <b>Step 2:</b> Fill up the form and press submit.
        </div>

        <div className="mt-4">
          <a
            href="https://support.tsgglobal.com/hc/en-us/article_attachments/15718347138331/TSG_Global_LOA.pdf"
            download="TSG_Global_LOA.pdf"
            className="underline"
            target="_blank"
          >
            Download TSG Global LOA
          </a>
        </div>

        <Form
          ref={formRef}
          onSubmit={submitForm}
          defaultValues={defaultFormValues}
          className=""
        >
          <div className="flex flex-wrap mt-4 mb-4 max-w-3xl">
            <div className="">
              <div className="">
                <FormFileInput
                  label="Upload LOA and COB Files here"
                  name="attachments"
                  multiple
                  validation={requiredValidation}
                  ref={fileInputRef}
                />
              </div>
              <div className="mt-1 text-sm">
                Please follow the proper naming convention:
                CUSTOMER_NAME_Toll_Free_LNP_Request_Form_MM-DD-YYYY.xls
              </div>
            </div>
          </div>

          {/* negative margins allow the form to get wider than the parent element  */}
          <div className="mt-8 ml-[-120px] mr-[-40px]">
            <TableInput submitInProgress={isWorking} />
          </div>
        </Form>
      </div>
    </div>
  );
}

function TableInput({ submitInProgress }: { submitInProgress: boolean }) {
  const { fields, append } = useFieldArray({
    name: "records",
  });

  return (
    <>
      <table className="text-xs table-fixed">
        <thead>
          <th className="">Product</th>
          <th className="">Customer name</th>
          <th className="w-[45px]">NPA</th>
          <th className="w-[45px]">NXX</th>
          <th className="w-[55px]">
            DNIS
            <br />
            (4 digit)
          </th>
          <th>Service address</th>
          <th>
            BTN
            <br />
            (no dashes)
          </th>
          <th>LOA date</th>
          <th>Requested port date</th>
          <th>T.38 required</th>
          <th>DID destination IP</th>
          <th>Customer notes</th>
        </thead>
        <tbody>
          {fields.map((item, index) => (
            <TableInputRow index={index} />
          ))}
        </tbody>
      </table>

      <div className="flex flex-row justify-between items-start mt-2">
        <Button
          type="button"
          small
          className="!w-fit flex align-center gap-2"
          onClick={() =>
            append({
              product: "Local Inbound",
              customerName: "",
              npa: "",
              nxx: "",
              dnis: "",
              serviceAddress: "",
              btn: "",
              loaDate: "",
              requestedPortDate: "",
              t38Required: null,
              didDestinationIp: "",
              customerNotes: "",
            })
          }
        >
          <PlusSmallIcon className="w-5 h-5" />
          Add record
        </Button>
        <PrimaryButton
          type="submit"
          className="h-[42px] !w-fit flex align-center gap-2"
          disabled={submitInProgress}
        >
          {submitInProgress ? <Loader small /> : "Create ticket"}
        </PrimaryButton>
      </div>
    </>
  );
}

type TableInputRowProps = {
  index: number;
};

function TableInputRow({ index }: TableInputRowProps) {
  const methods = useFormContext();

  const recordValidation = useMemo(
    () => ({
      validate: (value: any) => {
        const record = methods.getValues(`records[${index}]`);

        // all rows but the first can be empty, no need to validate those
        if (index !== 0 && isRecordEmpty(record)) return true;

        const emptyProps = unfilledRequiredFields(record);
        if (emptyProps.length === 0) return true;

        emptyProps.forEach((prop) => {
          const name = `records[${index}].${prop}`;
          methods.setError(name, {
            type: "required",
            message: "Field required",
          });
        });

        return value === "" || value === null ? "Field required" : true;
      },
    }),
    []
  );

  return (
    <tr>
      <td className="align-top">
        <Input tight noLabel name={`records[${index}].product`} />
      </td>
      <td className="align-top">
        <Input
          validation={recordValidation}
          tight
          noLabel
          name={`records[${index}].customerName`}
        />
      </td>
      <td className="align-top">
        <Input
          validation={recordValidation}
          tight
          noLabel
          name={`records[${index}].npa`}
        />
      </td>
      <td className="align-top">
        <Input
          validation={recordValidation}
          tight
          noLabel
          name={`records[${index}].nxx`}
        />
      </td>
      <td className="align-top">
        <Input
          validation={recordValidation}
          tight
          noLabel
          name={`records[${index}].dnis`}
        />
      </td>
      <td className="align-top">
        <Input tight noLabel name={`records[${index}].serviceAddress`} />
      </td>
      <td className="align-top">
        <Input
          validation={recordValidation}
          tight
          noLabel
          name={`records[${index}].btn`}
        />
      </td>
      <td className="align-top">
        <Input tight noLabel name={`records[${index}].loaDate`} />
      </td>
      <td className="align-top w-[140px]">
        <Input tight noLabel name={`records[${index}].requestedPortDate`} />
      </td>
      <td className="align-top w-[60px]">
        <FormSelect
          wrapperClassName="w-[110px]"
          className="!w-[110px] !min-w-[110px]"
          noLabel
          tight
          name={`records[${index}].t38Required`}
          options={t38RequiredOptions}
        />
      </td>
      <td className="align-top">
        <TextArea
          validation={recordValidation}
          tight
          noLabel
          name={`records[${index}].didDestinationIp`}
        />
      </td>
      <td className="align-top w-[140px]">
        <TextArea tight noLabel name={`records[${index}].customerNotes`} />
      </td>
    </tr>
  );
}

type PortInRecord = {
  product: string;
  customerName: string;
  npa: string;
  nxx: string;
  dnis: string;
  serviceAddress: string;
  btn: string;
  loaDate: string;
  requestedPortDate: string;
  t38Required: { label: string; value: string } | null;
  didDestinationIp: string;
  customerNotes: string;
};

const t38RequiredOptions = [
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];

const defaultFormValues = {
  records: new Array(10).fill({
    product: "Local Inbound",
    customerName: "",
    npa: "",
    nxx: "",
    dnis: "",
    serviceAddress: "",
    btn: "",
    loaDate: "",
    requestedPortDate: "",
    t38Required: null,
    didDestinationIp: "",
    customerNotes: "",
  }),
};

function isRecordEmpty(record: PortInRecord) {
  return (
    record.customerName === "" &&
    record.npa === "" &&
    record.nxx === "" &&
    record.dnis === "" &&
    record.btn === "" &&
    record.didDestinationIp === ""
  );
}

function unfilledRequiredFields(record: PortInRecord) {
  return [
    ["customerName", record.customerName === ""],
    ["npa", record.npa === ""],
    ["nxx", record.nxx === ""],
    ["dnis", record.dnis === ""],
    ["btn", record.btn === ""],
    ["t38Required", record.t38Required === null],
    ["didDestinationIp", record.didDestinationIp === ""],
  ]
    .filter(([_prop, isEmpty]) => isEmpty === true)
    .map(([prop, _isEmpty]) => prop);
}

function formatRecordAsCsv(record: PortInRecord) {
  const str = `"${record.product}","${record.customerName}","${record.npa}","${
    record.nxx
  }","${record.dnis}","${record.serviceAddress}","${record.btn}","${
    record.loaDate
  }","${record.requestedPortDate}","${record.t38Required?.label || "N/A"}","${
    record.didDestinationIp
  }","${record.customerNotes}"`;
  return str;
}
