import { useMemo, useState } from "react";
import { Form } from "../../components/Form";
import { Input, TextArea } from "../../components/Input";
import { formatDate } from "../../utils/format";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Button } from "../../components/Button";
import { Alert } from "../../components/Alert";
import { PrimaryButton } from "../../components/PrimaryButton";
import { PlusSmallIcon } from "@heroicons/react/24/solid";
import { createTicket } from "../../api/ticket";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { ticketTypeToTag } from "./ticket";
import { AuthenticatedState, useAuthStore } from "~/stores/authStore";

export function DirectoryListing411() {
  const [formattedDate] = useState(() => formatDate(new Date()));
  const user = useAuthStore(
    (store) => (store.state as AuthenticatedState).user
  );
  const [isWorking, setIsWorking] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const account = user.company.internal_id;

  async function submitForm({ records }: { records: ListingRecord[] }) {
    const title = `411_directory_listing_${account}_${formattedDate}.csv`;
    const subject = "411 Directory Listing Request";
    const body = `
      <div>
      <b>411 Directory Listing Request</b><br/>
      <br/>
      Account: ${account} <br/>
      <br/>
      <br/>
      Attached is the CSV file of the request
      </div>
    `;

    const formData = new FormData();

    // workaround for multiple attachments - FormData for some reason doesn't support a FileList object (which you get with <input type="file" multiple ... />)
    formData.delete("attachments[]");

    const csvHeaders = `"AreaCode","Number","Type","Privacy","Code","LastName","FirstName","FullAddress","City","State","ZipCode","SicCode","CustomerNotes"`;
    const recordsAsCsv = records
      .filter(isRecordFilled)
      .map(formatRecordAsCsv)
      .join("\n");

    const csvFile = `${csvHeaders}\n${recordsAsCsv}`;

    const fileBlob = new Blob([csvFile], { type: "text/csv" });

    formData.append("attachments[]", fileBlob, title);

    formData.append("subject", subject);
    formData.append("body", body);
    formData.append("tags[]", "customer_portal");
    formData.append("tags[]", ticketTypeToTag("411 Directory Listing"));

    setIsWorking(true);

    try {
      setError(null);
      await createTicket(formData);
      navigate("/support/ticket-created");
    } catch (error: any) {
      setError("An error occurred... please try again");
      setIsWorking(false);
    }
  }

  return (
    <div>
      <div>
        <Alert
          className="mt-6 mb-8  max-w-xl"
          title="Note"
          text={
            <>
              For <b>residential customers</b>, please fill in the first and
              last name fields.
              <br /> For <b>business customers</b>, please fill the last name
              field with the business name.
            </>
          }
        />

        {/* negative margins allow the form to get wider than the parent element  */}
        <Form
          onSubmit={submitForm}
          defaultValues={defaultFormValues}
          className="ml-[-120px] mr-[-40px]"
          validationMode="onSubmit"
        >
          <TableInput submitInProgress={isWorking} />
        </Form>
      </div>
    </div>
  );
}

function TableInput({ submitInProgress }: { submitInProgress: boolean }) {
  const { fields, append } = useFieldArray({
    name: "records",
  });

  return (
    <>
      <table className="text-xs table-fixed">
        <thead>
          <tr>
            <th className="w-[40px]">
              Area
              <br />
              code
            </th>
            <th className="w-[60px]">Number</th>
            <th className="w-[40px]">Type</th>
            <th className="w-[40px]">Privacy</th>
            <th className="w-[40px]">Code</th>
            <th>Last name</th>
            <th>First name</th>
            <th>Full address</th>
            <th>City</th>
            <th>State</th>
            <th>
              Zip
              <br />
              Code
            </th>
            <th>SIC Code</th>
            <th>Customer notes</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((item, index) => (
            <TableInputRow key={item.id} index={index} />
          ))}
        </tbody>
      </table>

      <div className="flex flex-row justify-between items-start mt-2">
        <Button
          type="button"
          small
          className="!w-fit flex align-center gap-2"
          onClick={() =>
            append({
              areaCode: "",
              number: "",
              type: "",
              privacy: "",
              code: "",
              lastName: "",
              firstName: "",
              fullAddress: "",
              city: "",
              state: "",
              zipCode: "",
              sicCode: "",
              customerNotes: "",
            })
          }
          disabled={submitInProgress}
        >
          <PlusSmallIcon className="w-5 h-5" />
          Add record
        </Button>
        <PrimaryButton
          type="submit"
          className="h-[42px] !w-fit flex align-center gap-2"
          disabled={submitInProgress}
        >
          {submitInProgress ? <Loader small /> : "Create request"}
        </PrimaryButton>
      </div>
    </>
  );
}

type TableInputRowProps = {
  index: number;
};

function TableInputRow({ index }: TableInputRowProps) {
  const methods = useFormContext();

  const recordValidation = useMemo(
    () => ({
      validate: (value: any) => {
        const record = methods.getValues(`records[${index}]`);

        // all rows but the first can be empty, no need to validate those
        if (index !== 0 && isRecordEmpty(record)) return true;

        const emptyProps = unfilledRequiredFields(record);
        if (emptyProps.length === 0) return true;

        emptyProps.forEach((prop) => {
          const name = `records[${index}].${prop}`;
          methods.setError(name, {
            type: "required",
            message: "Field required",
          });
        });

        return value === "" ? "Field required" : true;
      },
    }),
    []
  );

  return (
    <tr>
      <td className="align-top w-[40px]">
        <Input
          validation={recordValidation}
          tight
          noLabel
          name={`records[${index}].areaCode`}
        />
      </td>
      <td className="align-top w-[75px]">
        <Input
          validation={recordValidation}
          tight
          noLabel
          name={`records[${index}].number`}
        />
      </td>
      <td className="align-top w-[35px]">
        <Input tight noLabel name={`records[${index}].type`} disabled />
      </td>
      <td className="align-top w-[35px]">
        <Input tight noLabel name={`records[${index}].privacy`} disabled />
      </td>
      <td className="align-top w-[35px]">
        <Input tight noLabel name={`records[${index}].code`} disabled />
      </td>
      <td className="align-top w-[140px]">
        <Input
          validation={recordValidation}
          tight
          noLabel
          name={`records[${index}].lastName`}
        />
      </td>
      <td className="align-top w-[120px]">
        <Input tight noLabel name={`records[${index}].firstName`} />
      </td>
      <td className="align-top">
        <TextArea
          validation={recordValidation}
          tight
          noLabel
          name={`records[${index}].fullAddress`}
        />
      </td>
      <td className="align-top w-[140px]">
        <Input tight noLabel name={`records[${index}].city`} />
      </td>
      <td className="align-top w-[140px]">
        <Input tight noLabel name={`records[${index}].state`} />
      </td>
      <td className="align-top w-[60px]">
        <Input tight noLabel name={`records[${index}].zipCode`} />
      </td>
      <td className="align-top w-[40px]">
        <Input tight noLabel name={`records[${index}].sicCode`} disabled />
      </td>
      <td className="align-top w-[140px]">
        <TextArea tight noLabel name={`records[${index}].customerNotes`} />
      </td>
    </tr>
  );
}

type ListingRecord = {
  areaCode: string;
  number: string;
  type: string;
  privacy: string;
  code: string;
  lastName: string;
  firstName: string;
  fullAddress: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  sicCode: string;
  customerNotes: string;
};

const defaultFormValues = {
  records: new Array(10).fill({
    areaCode: "",
    number: "",
    type: "",
    privacy: "",
    code: "",
    lastName: "",
    firstName: "",
    fullAddress: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    sicCode: "",
    customerNotes: "",
  }),
};

function isRecordFilled(record: ListingRecord) {
  return (
    record.areaCode !== "" &&
    record.number !== "" &&
    record.lastName !== "" &&
    record.fullAddress !== ""
  );
}

function isRecordEmpty(record: ListingRecord) {
  return (
    record.areaCode === "" &&
    record.number === "" &&
    record.lastName === "" &&
    record.fullAddress === ""
  );
}

function unfilledRequiredFields(record: ListingRecord) {
  return [
    ["areaCode", record.areaCode === ""],
    ["number", record.number === ""],
    ["lastName", record.lastName === ""],
    ["fullAddress", record.fullAddress === ""],
  ]
    .filter(([_prop, isEmpty]) => isEmpty === true)
    .map(([prop, _isEmpty]) => prop);
}

function formatRecordAsCsv(record: ListingRecord) {
  const str = `"${record.areaCode}","${record.number}","${record.type}","${record.privacy}","${record.code}","${record.lastName}","${record.firstName}","${record.fullAddress}","${record.city}","${record.state}","${record.zipCode}","${record.zipCode}","${record.customerNotes}"`;
  return str;
}
