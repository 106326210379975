import { useMutation, useQuery, useQueryClient } from "react-query";
import { Loader } from "../../components/Loader";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Layout } from "../../components/Layout";
import { Button } from "../../components/Button";
import { Input, RadioInputGroup } from "../../components/Input";
import { PrimaryButton } from "../../components/PrimaryButton";
import { FormSelect, Select } from "../../components/Select";
import { usStateOptionsAbbreviated } from "~/utils/usStates";
import {
  clearReservedNumbersForPurchase,
  listReservedPhoneNumbersForPurchase,
  orderReservedNumbers,
  reserveNumbersForPurchase,
  searchPhoneNumbersForPurchase,
} from "~/api/purchasePhoneNumbers";
import {
  ClearReservedNumbersForPurchaseResult,
  OrderReservedNumbersResult,
  ReserveNumbersForPurchaseResult,
  SearchPurchaseNumberResultItem,
  UseCaseType,
} from "~/types";
import { Toast } from "~/components/Toast";
import { DropdownItem } from "~/utils/dropdownItemTypes";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { searchRateCenters } from "~/api/phoneNumbers";
import { Label } from "~/components/Label";
import { useNumberTypeStore } from "./numberTypesStore";
import { useAccountData } from "~/hooks/useAccountData";
import { AuthenticatedState, useAuthStore } from "~/stores/authStore";
import { ErrorComponent } from "~/components/Error";

export function PurchaseLocalNumbers() {
  return (
    <Layout className="Phone-Numbers">
      <h2 className="text-2xl lg:text-3xl font-bold mt-2 mb-6">
        Purchase phone numbers
      </h2>

      <div>
        <div className="flex flex-col">
          <div className="-my-2 sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle min-w-full sm:px-6 lg:px-8 flex gap-8">
              <div>
                <SearchNumbers />
              </div>
              <div className="relative mt-[172px]">
                <ReservedNumbers />
              </div>
            </div>
            <div className="pt-[80px] pb-[40px] align-middle min-w-full sm:px-6 lg:px-8 flex gap-8">
              <DisclaimerInfo />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const formDefaultValues = {
  contains: "",
  quantity: "10",
  usState: { label: "", value: "" },
  rateCenter: { label: "", value: "" },
  consecutive: "false",
};

function SearchNumbers() {
  const numberType = useNumberTypeStore((state) => state.numberType);
  const numberTypes = useNumberTypeStore((state) => state.numberTypes);
  const changeNumberType = useNumberTypeStore(
    (state) => state.changeNumberType
  );

  // TEMP CODE UNTIL WE RELEASE TO CLIENTS
  // const user = useAuthStore(
  //   (store) => (store.state as AuthenticatedState).user
  // );
  // const userEmail = user.email;
  // const onlyForTsgMembers =
  //   userEmail?.includes("@tsgglobal.com") ||
  //   userEmail?.includes("@example.com");
  // END OF TEMP CODE UNTIL WE RELEASE TO CLIENTS

  const queryClient = useQueryClient();

  // search filters
  const [contains, setContains] = useState("");
  const [quantity, setQuantity] = useState(10);
  const [usState, setUsState] = useState("");
  const [rateCenter, setRateCenter] = useState("");
  const [consecutive, setConsecutive] = useState(false);

  const methods = useForm<FormDataType>({
    defaultValues: formDefaultValues,
  });

  const selectedUsState = methods.watch("usState").value;
  // only if US state is selected
  const isRateCenterSelectionEnabled = selectedUsState?.length > 0;

  useEffect(() => {
    methods.setValue("rateCenter", null);
  }, [selectedUsState]);

  const { numbers, error, isLoading, isFetching, wasSearchMade, clearResults } =
    useSearchPhoneNumbers({
      contains,
      quantity,
      usState,
      rateCenter,
      consecutive,
    });

  const { rateCenters } = useRateCenters({ usState: selectedUsState });

  const reserveNumbersMutation = useMutateReservedPhoneNumbers();

  // selected numbers
  const [selectedNumbers, setSelectedNumbers] = useState<string[]>([]);
  const checkbox = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  // useLayoutEffect to avoid flash changes of checkbox state
  useLayoutEffect(() => {
    const isIndeterminate =
      selectedNumbers.length > 0 && selectedNumbers.length < numbers.length;
    const areAllNumbersChecked =
      selectedNumbers.length > 0 && selectedNumbers.length === numbers.length;
    setChecked(areAllNumbersChecked);
    setIndeterminate(isIndeterminate);
    if (checkbox.current) {
      checkbox.current.indeterminate = isIndeterminate;
    }
  }, [selectedNumbers]);

  function toggleAll() {
    setSelectedNumbers(
      checked || indeterminate ? [] : numbers.map((n) => n.telephoneNumber)
    );
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  function reserveSelectedNumbers() {
    reserveNumbersMutation.mutate(selectedNumbers);
  }

  function triggerSearch(data: FormDataType) {
    // clear out the selected number before search,
    // users can reserve numbers if they want to preserve them between searches
    setSelectedNumbers([]);

    const searchParams = parseSearchParams(data);

    setContains(searchParams.contains);
    setQuantity(searchParams.quantity);
    setConsecutive(searchParams.consecutive);
    setUsState(searchParams.usState || "");
    setRateCenter(searchParams.rateCenter || "");
  }

  function clearSearchForm() {
    setContains("");
    setQuantity(10);
    setUsState("");
    setContains("");
    setRateCenter("");
    setConsecutive(false);

    methods.reset(formDefaultValues, { keepDefaultValues: true });

    clearResults();

    queryClient.resetQueries(["searchPhoneNumbersForPurchase"]);
    queryClient.removeQueries(["searchPhoneNumbersForPurchase"]);
  }

  const showNoSearchMade =
    numbers?.length === 0 && !wasSearchMade && !isFetching;
  const showNoResults = numbers?.length === 0 && wasSearchMade && !isFetching;
  const showNumbersLoadingInProgress = isFetching;

  return (
    <div className="">
      <div className="flex flex-col flex-wrap w-full">
        <h3 className="text-lg font-bold mb-4">Number search</h3>

        <ErrorComponent className="mb-4" error={error} />

        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(triggerSearch)}
            className="w-full"
          >
            <div className="">
              <div className="flex">
                {/* {onlyForTsgMembers && ( */}
                <div className="Select-Wrapper max-w-lg mr-8">
                  <Label htmlFor="ticketType">Type</Label>
                  <Select
                    id="numberType"
                    options={numberTypes}
                    onChange={changeNumberType}
                    value={numberType}
                    defaultValue={numberType}
                  />
                </div>
                {/* )} */}

                <Input
                  wrapperClassName="w-[190px]"
                  white
                  label="Contains"
                  name="contains"
                  defaultValue=""
                  placeholder="402xxxxxxx"
                  disabled={false}
                />

                <Input
                  wrapperClassName="ml-8 w-[100px]"
                  white
                  label="Quantity"
                  name="quantity"
                  defaultValue=""
                  placeholder="5"
                  disabled={false}
                />

                <div className="ml-8">
                  <RadioInputGroup
                    label="Consecutive numbers?"
                    name={"consecutive"}
                    options={[
                      {
                        label: "Yes",
                        value: true,
                      },
                      {
                        label: "No",
                        value: false,
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="flex mt-4">
                <FormSelect
                  wrapperClassName=""
                  label="State"
                  name="usState"
                  options={usStateOptionsAbbreviated}
                />

                <FormSelect
                  wrapperClassName="ml-8"
                  label="Rate center"
                  name="rateCenter"
                  options={rateCenters}
                  isDisabled={!isRateCenterSelectionEnabled}
                />

                <PrimaryButton
                  type="submit"
                  className="flex rounded-sm h-[42px] ml-8 !w-[140px] align-bottom justify-end self-end "
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Loader small white />
                  ) : (
                    <>
                      Search <SearchIcon className="ml-2" />
                    </>
                  )}
                </PrimaryButton>
                <Button
                  type="button"
                  onClick={clearSearchForm}
                  light
                  className="self-end !rounded-sm ml-8 bg-red-500 hover:bg-red-600 text-white h-[42px] w-[140px]"
                >
                  Clear
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>

      <div className="shadow overflow-x-auto overflow-y-hidden border-b border-gray-200 sm:rounded-lg mt-10 relative min-h-[220px] bg-white">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="relative w-12 px-6 sm:w-16 sm:px-8">
                <input
                  type="checkbox"
                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                  ref={checkbox}
                  checked={checked}
                  onChange={toggleAll}
                />
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Number
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                State
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Rate center
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {numbers?.map((phoneNumber) => (
              <tr key={phoneNumber.telephoneNumber}>
                <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                  {selectedNumbers.includes(phoneNumber.telephoneNumber) && (
                    <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                  )}
                  <input
                    type="checkbox"
                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                    value={phoneNumber.telephoneNumber}
                    checked={selectedNumbers.includes(
                      phoneNumber.telephoneNumber
                    )}
                    onChange={(e) =>
                      setSelectedNumbers(
                        e.target.checked
                          ? [...selectedNumbers, phoneNumber.telephoneNumber]
                          : selectedNumbers.filter(
                              (n) => n !== phoneNumber.telephoneNumber
                            )
                      )
                    }
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {formatTelephoneNumber(phoneNumber)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {phoneNumber.province}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {phoneNumber.rateCenter}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {showNumbersLoadingInProgress && (
          <div className="absolute-center">
            <Loader />
          </div>
        )}

        {showNoSearchMade && (
          <div className="bg-white text-center px-4 py-12">
            Search for numbers first, reserve them and then you can order
            reserved numbers.
          </div>
        )}

        {showNoResults && (
          <div className="bg-white text-center p-4">
            Search did not find any numbers for purchase.
          </div>
        )}
      </div>

      <Button
        light
        className="bg-amber-300 hover:bg-amber-400 text-white mt-6 h-min-[40px] w-[260px]"
        onClick={reserveSelectedNumbers}
        disabled={
          selectedNumbers.length == 0 || reserveNumbersMutation.isLoading
        }
      >
        {reserveNumbersMutation.isLoading ? (
          <Loader white small />
        ) : (
          "Reserve selected numbers"
        )}
      </Button>
    </div>
  );
}

function ReservedNumbers() {
  const user = useAuthStore(
    (store) => (store.state as AuthenticatedState).user
  );

  // there seems to be a bug or something where user doesn't get populated on time sometimes.
  // need to track that down.
  const companyId = user.company?.id!;

  const { isLoading, error, data, refetch } = useAccountData(companyId);
  const [smsEnable, setSmsEnable] = useState(true);
  const [useCaseType, setUseCaseType] = useState<UseCaseType>("a2p");

  const {
    numbers: reservedNumbers,
    error: reservedNumbersError,
    isLoading: reservedNumbersIsLoading,
  } = useReservedPhoneNumbers();

  const clearReservedNumbersMutation = useMutateClearReservedPhoneNumbers();
  const orderReservedNumbersMutation = useMutateOrderReservedPhoneNumbers();

  function clearReservedNumbers() {
    const numbers = reservedNumbers.map((n) => n.telephoneNumber);
    clearReservedNumbersMutation.mutate(numbers);
  }

  function orderReservedNumbers() {
    orderReservedNumbersMutation.mutate({ smsEnable, useCaseType });
  }

  // if (reservedNumbers.length === 0) return null;
  if (reservedNumbersError)
    return <div>Error - failed to load reserved numbers...</div>;
  if (reservedNumbersIsLoading) return <Loader />;

  const workInProgress =
    clearReservedNumbersMutation.isLoading ||
    orderReservedNumbersMutation.isLoading;

  return (
    <div>
      <h3 className="text-lg font-bold mb-4">Reserved numbers</h3>
      <div>
        <div className="shadow overflow-x-auto overflow-y-hidden border-b border-gray-200 sm:rounded-lg mt-8">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Number
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  State
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Rate center
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {reservedNumbers?.map((phoneNumber) => (
                <tr key={phoneNumber.telephoneNumber}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {formatTelephoneNumber(phoneNumber)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {phoneNumber.province}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {phoneNumber.rateCenter}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {reservedNumbers?.length === 0 && (
            <div className="bg-white text-center px-4 py-12">
              To order numbers you have to reserve them first
            </div>
          )}
        </div>

        <div className="mt-4">
          <div>
            <label className="cursor-pointer">
              <input
                className="mr-2"
                type="checkbox"
                checked={smsEnable}
                onChange={() => setSmsEnable(!smsEnable)}
              />
              Enable SMS for numbers?
            </label>
          </div>

          {data?.allow_p2p_numbers_provisioning && smsEnable ? (
            <div className="mt-6">
              <label>Messaging use case type</label>

              <div className="flex items-center gap-6">
                <div className="flex items-center h-[42px]">
                  <input
                    id={"use_case_type_a2p"}
                    name={"use_case_type"}
                    type="radio"
                    defaultChecked={useCaseType === "a2p"}
                    value={"a2p"}
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 Radio-Input"
                    onChange={(e) => setUseCaseType("a2p")}
                  />

                  <label
                    htmlFor={"use_case_type_a2p"}
                    className="ml-3 block text-sm font-medium text-gray-700 h-[42px] Radio-Input-Label"
                  >
                    A2P
                  </label>
                </div>
                <div className="flex items-center h-[42px]">
                  <input
                    id={"use_case_type_p2p"}
                    name={"use_case_type"}
                    type="radio"
                    defaultChecked={useCaseType === "p2p"}
                    value={"p2p"}
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 Radio-Input"
                    onChange={(e) => setUseCaseType("p2p")}
                  />

                  <label
                    htmlFor={"use_case_type_p2p"}
                    className="ml-3 block text-sm font-medium text-gray-700 h-[42px] Radio-Input-Label"
                  >
                    P2P
                  </label>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="mt-6 flex gap-4">
          <Button
            onClick={orderReservedNumbers}
            disabled={workInProgress || reservedNumbers.length === 0}
            light
            className="bg-green-500 hover:bg-green-600 text-white h-min-[40px] w-[223px]"
          >
            {workInProgress ? <Loader small white /> : "Order reserved numbers"}
          </Button>
          <Button
            onClick={clearReservedNumbers}
            light
            className="bg-red-500 hover:bg-red-600 text-white h-min-[40px] w-[146px]"
            disabled={workInProgress || reservedNumbers.length === 0}
          >
            {workInProgress ? <Loader small white /> : "Clear numbers"}
          </Button>
        </div>
      </div>
    </div>
  );
}

type useSearchPhoneNumbersArgs = {
  contains: string;
  quantity: number;
  usState: string;
  rateCenter: string;
  consecutive: boolean;
};

const TWO_MINUTES_IN_MS = 1000 * 60 * 2;

function useSearchPhoneNumbers({
  contains,
  quantity,
  usState,
  rateCenter,
  consecutive,
}: useSearchPhoneNumbersArgs) {
  const doTriggerSearch = !!contains || !!usState || !!rateCenter;

  const {
    isLoading,
    error,
    data: numbers,
    isFetching,
    isFetched,
    isFetchedAfterMount,
    remove,
  } = useQuery(
    [
      "searchPhoneNumbersForPurchase",
      contains,
      quantity,
      usState,
      rateCenter,
      consecutive,
    ],
    async ({}) => {
      const numbers = await searchPhoneNumbersForPurchase({
        contains,
        quantity,
        state: usState,
        rateCenter,
        consecutive,
      });

      return numbers;
    },
    {
      staleTime: TWO_MINUTES_IN_MS,
      enabled: doTriggerSearch,
    }
  );

  return {
    isLoading,
    isFetching,
    isFetched,
    wasSearchMade: isFetchedAfterMount,
    error: error as Error,
    numbers: numbers || [],
    clearResults: remove,
  };
}

type useRateCentersArgs = {
  usState: string;
};

function useRateCenters({ usState }: useRateCentersArgs) {
  const { isLoading, error, data, isFetching, isFetched, remove } = useQuery(
    ["rateCenters", usState],
    async ({}) => {
      const rateCenters = await searchRateCenters(usState);
      return rateCenters.map((rateCenter) => ({
        label: rateCenter.abbreviation,
        value: rateCenter.abbreviation,
      }));
    },
    {
      staleTime: TWO_MINUTES_IN_MS,
      enabled: !!usState,
    }
  );

  return {
    isLoading,
    isFetching,
    isFetched,
    error: error as Error,
    rateCenters: data || [],
    clearResults: remove,
  };
}

function useReservedPhoneNumbers() {
  const {
    isLoading,
    error,
    data: numbers,
    isFetching,
  } = useQuery(
    ["reservedPhoneNumbersForPurchase"],
    async ({}) => {
      const numbers = await listReservedPhoneNumbersForPurchase();

      return numbers;
    },
    {
      keepPreviousData: true,
      staleTime: TWO_MINUTES_IN_MS,
    }
  );

  return {
    isLoading,
    isFetching,
    error: error as Error,
    numbers: numbers || [],
  };
}

type ReserveNumbersArgs = string[];

function useMutateReservedPhoneNumbers() {
  const queryClient = useQueryClient();

  return useMutation<
    ReserveNumbersForPurchaseResult["numbersReserve"],
    Error,
    ReserveNumbersArgs
  >((numbers) => reserveNumbersForPurchase({ numbers }), {
    onError: (error, _variables, _ctx) => {
      console.error("[ERROR] Reserve numbers failed", error);
      Toast({
        type: "Error",
        title: "Couldn't reserve numbers, please try again...",
        timeout: 10000,
      });
    },
    onSuccess: (data, _variables, _ctx) => {
      let message = "Reserved numbers";
      if (data.excluded.length) {
        message = `Reserved numbers, but some of them couldn't be reserved: ${data.excluded.join(
          ", "
        )}`;
      }

      Toast({
        title: message,
        type: "Success",
      });

      // we'll clear the cache and refetch the reserved numbers for purchase
      queryClient.invalidateQueries(["reservedPhoneNumbersForPurchase"]);
      queryClient.refetchQueries({
        queryKey: ["reservedPhoneNumbersForPurchase"],
      });
    },
  });
}

function useMutateClearReservedPhoneNumbers() {
  const queryClient = useQueryClient();

  return useMutation<
    ClearReservedNumbersForPurchaseResult["releaseReserved"],
    Error,
    ReserveNumbersArgs
  >((numbers) => clearReservedNumbersForPurchase({ numbers }), {
    onError: (error, _variables, _ctx) => {
      console.error("[ERROR] Reserve numbers failed", error);
      Toast({
        type: "Error",
        title: "Couldn't release reserved numbers, please try again...",
        timeout: 10000,
      });
    },
    onSuccess: (data, _variables, _ctx) => {
      let message = "Reserved numbers cleared";
      if (data.excluded.length) {
        message = `Reserved numbers cleared, some of them couldn't be cleared: ${data.excluded.join(
          ", "
        )}`;
      }

      Toast({
        title: message,
        type: "Success",
      });

      // we'll clear the cache and refetch the reserved numbers for purchase
      queryClient.invalidateQueries(["reservedPhoneNumbersForPurchase"]);
      queryClient.refetchQueries({
        queryKey: ["reservedPhoneNumbersForPurchase"],
      });
    },
  });
}

type MutateOrderReservedPhoneNumbersInput = {
  smsEnable: boolean;
  useCaseType: UseCaseType;
};

function useMutateOrderReservedPhoneNumbers() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation<
    OrderReservedNumbersResult["numbersOrder"],
    Error,
    MutateOrderReservedPhoneNumbersInput
  >(
    ({ smsEnable, useCaseType }) =>
      orderReservedNumbers(smsEnable, useCaseType),
    {
      onError: (error, _variables, _ctx) => {
        console.error("[ERROR] Order numbers failed", error);
        Toast({
          type: "Error",
          title: "Couldn't order reserved numbers, please try again...",
          timeout: 10000,
        });
      },
      onSuccess: (data, smsEnable, _ctx) => {
        Toast({
          title: "Numbers ordered",
          type: "Success",
          timeout: 10000,
        });

        queryClient.invalidateQueries(["reservedPhoneNumbersForPurchase"]);
        queryClient.refetchQueries({
          queryKey: ["reservedPhoneNumbersForPurchase"],
        });

        queryClient.invalidateQueries(["phoneNumbers"]);

        const purchasedNumbers = data.details.dids
          .filter((did) => data.details.numbers.includes(did.did))
          .map((number) => {
            return {
              number: number.did,
              state: number.state,
              rateCenter: number.rateCenter,
              smsEnable: smsEnable,
            };
          });

        const failedToPurchaseNumbers = data?.details?.failed || [];

        navigate("/purchase-phone-numbers-success", {
          state: {
            purchasedNumbers,
            failedToPurchaseNumbers,
            type: "long-code",
          },
        });
      },
    }
  );
}

function SearchIcon({ className }: { className: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${className ? className : ""} h-6 w-6`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
      />
    </svg>
  );
}

interface FormDataType {
  contains: string;
  quantity: string;
  usState: DropdownItem;
  rateCenter: DropdownItem | null;
  consecutive: string;
}

function parseSearchParams(formData: FormDataType) {
  // contains must have 10 digits, the format of "402xxxxxxx"
  let parsedContains = formData.contains.startsWith("1")
    ? formData.contains.slice(1)
    : formData.contains;
  parsedContains =
    parsedContains.length != 10
      ? parsedContains.padEnd(10, "x")
      : parsedContains;
  const quantity = parseInt(formData.quantity || "1", 10);
  const consecutive = formData.consecutive === "true";

  return {
    contains: parsedContains.trim(),
    quantity: quantity,
    usState: formData.usState?.value,
    rateCenter: formData.rateCenter?.value,
    consecutive: consecutive,
  };
}

function DisclaimerInfo() {
  return (
    <div>
      After purchasing a local numbers, please be sure to avoid higher
      surcharges that you are registering your traffic 10DLC registration.
      <br />
      More information on 10DLC can be found here:{" "}
      <a
        target="_blank"
        className="link"
        href="https://support.tsgglobal.com/hc/en-us/articles/1260803569690-What-is-A2P-10DLC-"
      >
        What is A2P 10DLC?
      </a>
    </div>
  );
}

/**
 * Sometimes the numbers have the dial prefix included and sometimes they don't.
 * We'll try to make a best guess and format it accordingly.
 */
function formatTelephoneNumber(
  telephoneNumber: SearchPurchaseNumberResultItem
) {
  if (
    telephoneNumber.telephoneNumber.length === 11 &&
    telephoneNumber.telephoneNumber.startsWith(telephoneNumber.countryCode)
  ) {
    return telephoneNumber.telephoneNumber;
  } else {
    return `${telephoneNumber.countryCode}${telephoneNumber.telephoneNumber}`;
  }
}
