import { useNavigate } from "react-router-dom";
import { Layout } from "../../components/Layout";
import { FormEvent, useEffect, useRef, useState } from "react";
import { Select } from "../../components/Select";
import { Label } from "../../components/Label";
import { PrimaryButton } from "../../components/PrimaryButton";
import { createTicket } from "../../api/ticket";
import { Loader } from "../../components/Loader";
import { FileInput } from "../../components/Input";
import { DirectoryListing411 } from "./DirectoryListing411";
import { TroubleWithService } from "./TroubleWithService";
import { IpTermination } from "./IpTermination";
import { LocalIncomingPortIn } from "./LocalIncomingPortIn";
import { TollFreePortIn } from "./TollFreePortIn";
import { LocalIncomingBulkPortIn } from "./LocalIncomingBulkPortIn";
import { stringsToSelectOptions } from "../../utils/select";
import { TicketTypeOption, ticketTypeToTag } from "./ticket";
import { User } from "../../types";
import { AuthenticatedState, useAuthStore } from "~/stores/authStore";

export function CreateTicket() {
  const [ticketType, setTicketType] = useState<TicketTypeOption>(
    () => ticketOptions[0]
  );

  return (
    <div className="CreateTicket">
      <Layout>
        <div className="max-w-5xl mx-auto mt-6">
          <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
            Create ticket
          </h2>
          <p className="mt-4 text-lg leading-6 text-gray-500">
            This will create a ticket with our support. Select the issue you're
            having.
          </p>

          <div className="mt-6 text-base text-gray-500">
            <div className="Select-Wrapper max-w-lg">
              <Label htmlFor="ticketType">Request / problem</Label>
              <Select
                id="ticketType"
                options={ticketOptions}
                onChange={setTicketType}
                value={ticketType}
                defaultValue={ticketType}
              />
            </div>

            <TicketForm type={ticketType} />
          </div>
        </div>
      </Layout>
    </div>
  );
}
type TicketFormProps = {
  type: TicketTypeOption;
};

function TicketForm({ type }: TicketFormProps) {
  switch (type.value) {
    case "Billing Credit Request":
      return <DefaultForm ticketType={type} />;
    case "411 Directory Listing":
      return <DirectoryListing411 />;
    case "Create port request":
      return <DefaultForm ticketType={type} />;
    case "Trouble with service":
      return <TroubleWithService />;
    case "IP termination request":
      return <IpTermination />;
    case "Support / Other":
      return <DefaultForm ticketType={type} />;
    case "Local incoming port-in":
      return <LocalIncomingPortIn />;
    case "Toll-free port-in":
      return <TollFreePortIn />;
    case "Local incoming bulk port-in":
      return <LocalIncomingBulkPortIn />;
  }
}

type DefaultFormProps = {
  ticketType: TicketTypeOption;
};

function DefaultForm({ ticketType }: DefaultFormProps) {
  const [ticketBody, setTicketBody] = useState("");
  const user = useAuthStore(
    (store) => (store.state as AuthenticatedState).user
  );

  useEffect(() => {
    const template = getTemplate(user, ticketType);
    setTicketBody(template);
  }, [ticketType]);

  const [isWorking, setIsWorking] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();

  async function submitTicket(event: FormEvent) {
    event.preventDefault();

    const formData = new FormData(formRef.current!);

    // make sure the template is HTML formatted.
    const body = (formRef.current as any).elements["body"].value;
    formData.delete("body");
    formData.append("body", body.replaceAll("\n", "<br/>"));

    // workaround for multiple attachments - FormData for some reason doesn't support a FileList object (which you get with <input type="file" multiple ... />)
    formData.delete("attachments[]");
    const files = (formRef.current as any).elements!["attachments[]"].files;
    [...files].forEach((file: File) => formData.append("attachments[]", file));

    formData.append("tags[]", "customer_portal");
    formData.append("tags[]", ticketTypeToTag(ticketType.label));

    setIsWorking(true);

    try {
      setError(null);
      await createTicket(formData);

      navigate("/support/ticket-created");
    } catch (error: any) {
      setError("An error occurred... please try again");
      setIsWorking(false);
    }
  }

  return (
    <form id="ticketForm" ref={formRef}>
      <input
        className="hidden"
        name="subject"
        value={ticketType.label}
        onChange={doNothing}
      />

      <div className="w-full mt-10">
        <Label htmlFor="ticketBody">Ticket</Label>
        <textarea
          id="ticketBody"
          name="body"
          className="w-full TicketBodyText"
          onChange={(e) => setTicketBody(e.target.value)}
          value={ticketBody}
        />
        <div className="text-xs">
          Please fill in the data between {"{"} {"}"} to help our support solve
          your ticket faster
        </div>

        <div className="flex flex-wrap mt-6">
          <div className="w-full">
            <div className="mb-12">
              <Label htmlFor="ticket-attachment">Add attachments</Label>
              <FileInput id="ticket-attachment" name="attachments[]" multiple />
            </div>
          </div>
        </div>
      </div>

      <PrimaryButton
        disabled={isWorking}
        className="mt-6"
        onClick={submitTicket}
      >
        {isWorking ? <Loader small /> : "Create ticket"}
      </PrimaryButton>
    </form>
  );
}

const ticketOptions = stringsToSelectOptions([
  // "Billing Credit Request",
  "411 Directory Listing",
  // "Create port request",
  "Trouble with service",
  "IP termination request",
  "Local incoming port-in",
  "Toll-free port-in",
  "Local incoming bulk port-in",
  "Support / Other",
]) as any as TicketTypeOption[];

function getTemplate(user: User, ticketType: TicketTypeOption): string {
  switch (ticketType.value) {
    case "Billing Credit Request":
      return billingTemplate(user);
    case "Create port request":
      return portingTemplate(user);
    case "Support / Other":
      return supportTemplate(user);
    default:
      return "";
  }
}

function billingTemplate(user: any) {
  return `Billing template


User: ${user.first_name} ${user.last_name} (${user.email})
Company: ${user.company.name} (${user.company.internal_id})
Date: ${new Date().toUTCString()}
Billing issue: {describe what happened}
`;
}

function portingTemplate(user: any) {
  return `Porting template


User: ${user.first_name} ${user.last_name} (${user.email})
Company: ${user.company.name} (${user.company.internal_id})
Date: ${new Date().toUTCString()}
Numbers to port: {fill the numbers you need to port}
`;
}

function supportTemplate(user: any) {
  return `User: ${user.first_name} ${user.last_name} (${user.email})
Company: ${user.company.name} (${user.company.internal_id})
Date: ${new Date().toUTCString()}
Problem:

{describe what happened}
`;
}

function doNothing() {}
