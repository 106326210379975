import { httpClient } from "./httpClient";

const localStorageTokenKey = "__customer_portal_token__";

export function setAuthToken(token: string) {
  window.localStorage.setItem(localStorageTokenKey, token);
}

export function getAuthToken() {
  return window.localStorage.getItem(localStorageTokenKey);
}

export function deleteAuthToken() {
  return window.localStorage.removeItem(localStorageTokenKey);
}

export async function replaceCodeWithToken(code: string): Promise<any> {
  const response = await httpClient("/auth/code", {
    method: "POST",
    body: {
      code,
    },
  });

  return response.body;
}
