import { useQuery } from "react-query";
import { getAccountData } from "~/api/account";

const TWO_MINUTES_IN_MS = 1000 * 60 * 2;

export function useAccountData(companyId?: string) {
  const { isLoading, error, data, refetch } = useQuery(
    ["companies", companyId, "account"],
    async ({}) => {
      const accountData = await getAccountData(companyId!);
      return accountData;
    },
    {
      // don't trigger the load if the companyId is missing
      enabled: !!companyId,
      keepPreviousData: true,
      staleTime: TWO_MINUTES_IN_MS,
    }
  );

  return {
    isLoading,
    error,
    data,
    refetch,
  };
}
