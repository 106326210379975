import { enableVoice } from "../config";
import { RecordType } from "../types";

type TypeSelectorProps = {
  activeType: RecordType;
  setType: (type: RecordType) => void;
};

export function TypeSelector({ activeType, setType }: TypeSelectorProps) {
  return (
    <div className="flex items-center">
      <span className="w-14 font-semibold">Type</span>

      <div className="flex flex-wrap gap-3">
        <TypeSelectorItem
          activeType={activeType}
          setType={setType}
          itemValue="SMS"
        />
        <TypeSelectorItem
          activeType={activeType}
          setType={setType}
          itemValue="MMS"
        />
        {enableVoice && (
          <TypeSelectorItem
            activeType={activeType}
            setType={setType}
            itemValue="VOICE"
          />
        )}
        <TypeSelectorItem
          activeType={activeType}
          setType={setType}
          itemValue="API"
        />
      </div>
    </div>
  );
}

type TypeSelectorItemProps = TypeSelectorProps & {
  itemValue: RecordType;
};

function TypeSelectorItem({
  setType,
  activeType,
  itemValue,
}: TypeSelectorItemProps) {
  // pageNumber == currentPage
  //   ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative "
  //   : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
  // } relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
  if (activeType == itemValue) {
    return (
      <span className="w-16 h-8 flex justify-center items-center  bg-indigo-50 border-indigo-500 text-gray-800 text-sm text-center border rounded-md cursor-default">
        {itemValue}
      </span>
    );
  } else {
    return (
      <button
        type="button"
        className="w-16 h-8 flex justify-center items-center bg-white hover:bg-gray-100 active:bg-gray-200 text-gray-800 text-sm text-center border rounded-md transition duration-100"
        onClick={() => setType(itemValue)}
      >
        {itemValue}
      </button>
    );
  }
}
