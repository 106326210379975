import React from "react";
import { useQuery } from "react-query";
import { getAllCompanyNames } from "~/api/company";

const FIVE_MINUTES_IN_MS = 1000 * 60 * 5;

export function useCompanyNames(enabled: boolean = true, config?: object) {
  const { data, isLoading, error } = useQuery(
    ["companyNames"],
    async () => {
      return getAllCompanyNames(config);
    },
    {
      enabled,
      keepPreviousData: true,
      retry: false,
      cacheTime: FIVE_MINUTES_IN_MS,
    }
  );

  const companyKeyMap = data?.data?.reduce(
    (acc: Record<string, string>, company) => {
      acc[company.id] = company.name;
      return acc;
    },
    {}
  );

  return {
    companySelectData: data?.data!.map((c) => ({
      label: c.name,
      value: c.id,
    })),
    companyData: data,
    companyKeyMap,
    isCompanyNamesLoading: isLoading,
    companyNamesError: error as Error,
  };
}
