import { useMutation, useQuery, useQueryClient } from "react-query";
import { Loader } from "../../components/Loader";
import { useLayoutEffect, useRef, useState } from "react";
import { Layout } from "../../components/Layout";
import { Button } from "../../components/Button";
import { Input, RadioInputGroup } from "../../components/Input";
import { PrimaryButton } from "../../components/PrimaryButton";
import {
  orderTollfreeNumbers,
  searchTollfreePhoneNumbersForPurchase,
} from "~/api/purchaseTollfreePhoneNumbers";
import { OrderTollfreeNumbersResult } from "~/types";
import { Toast } from "~/components/Toast";
import { Link, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Label } from "~/components/Label";
import { Select } from "~/components/Select";
import { useNumberTypeStore } from "./numberTypesStore";
import { ErrorComponent } from "~/components/Error";

export function PurchaseTollfreeNumbers() {
  const [reservedNumbers, setReservedNumbers] = useState<string[]>([]);

  return (
    <Layout>
      <h2 className="text-2xl lg:text-3xl font-bold mt-2 mb-6">
        Purchase phone numbers
      </h2>

      <div>
        <div className="flex flex-col">
          <div className="-my-2 sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle min-w-full sm:px-6 lg:px-8 flex gap-8">
              <div>
                <SearchNumbers
                  setReservedNumbers={setReservedNumbers}
                  reservedNumbers={reservedNumbers}
                />
              </div>
              <div className="relative mt-[148px]">
                <ReservedNumbers
                  setReservedNumbers={setReservedNumbers}
                  reservedNumbers={reservedNumbers}
                />
              </div>
            </div>
            <div className="pt-[80px] pb-[40px] align-middle min-w-full sm:px-6 lg:px-8 flex gap-8">
              <DisclaimerInfo />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const formDefaultValues = {
  contains: "",
  quantity: "5",
  consecutive: "false",
};

type ReservedNumbersArgs = {
  reservedNumbers: string[];
  setReservedNumbers: React.Dispatch<React.SetStateAction<string[]>>;
};

function SearchNumbers({
  reservedNumbers,
  setReservedNumbers,
}: ReservedNumbersArgs) {
  const numberType = useNumberTypeStore((state) => state.numberType);
  const numberTypes = useNumberTypeStore((state) => state.numberTypes);
  const changeNumberType = useNumberTypeStore(
    (state) => state.changeNumberType
  );

  // TEMP CODE UNTIL WE RELEASE TO CLIENTS
  // const user = useAuthStore(
  //   (store) => (store.state as AuthenticatedState).user
  // );
  // const userEmail = user.email;
  // const onlyForTsgMembers =
  //   userEmail?.includes("@tsgglobal.com") ||
  //   userEmail?.includes("@example.com");
  // END OF TEMP CODE UNTIL WE RELEASE TO CLIENTS

  const queryClient = useQueryClient();

  // search filters
  const [contains, setContains] = useState("");
  const [quantity, setQuantity] = useState(5);
  const [consecutive, setConsecutive] = useState(false);

  const methods = useForm<FormDataType>({
    defaultValues: formDefaultValues,
  });

  const { numbers, error, isLoading, isFetching, wasSearchMade, clearResults } =
    useSearchPhoneNumbers({
      contains,
      quantity,
      consecutive,
    });

  // selected numbers
  const [selectedNumbers, setSelectedNumbers] = useState<string[]>([]);
  const checkbox = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  // useLayoutEffect to avoid flash changes of checkbox state
  useLayoutEffect(() => {
    const isIndeterminate =
      selectedNumbers.length > 0 && selectedNumbers.length < numbers.length;
    const areAllNumbersChecked =
      selectedNumbers.length > 0 && selectedNumbers.length === numbers.length;
    setChecked(areAllNumbersChecked);
    setIndeterminate(isIndeterminate);
    if (checkbox.current) {
      checkbox.current.indeterminate = isIndeterminate;
    }
  }, [selectedNumbers]);

  function toggleAll() {
    setSelectedNumbers(checked || indeterminate ? [] : numbers);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  function reserveSelectedNumbers() {
    setReservedNumbers(selectedNumbers);
  }

  function triggerSearch(data: FormDataType) {
    // clear out the selected number before search,
    // users can reserve numbers if they want to preserve them between searches
    setSelectedNumbers([]);

    const searchParams = parseSearchParams(data);

    setContains(searchParams.contains);
    setQuantity(searchParams.quantity);
    setConsecutive(searchParams.consecutive);
  }

  function clearSearchForm() {
    setContains("");
    setQuantity(5);
    setContains("");
    setConsecutive(false);

    methods.reset(formDefaultValues, { keepDefaultValues: true });

    clearResults();

    queryClient.resetQueries(["searchTollfreePhoneNumbersForPurchase"]);
    queryClient.removeQueries(["searchTollfreePhoneNumbersForPurchase"]);
  }

  const showNoSearchMade =
    numbers?.length === 0 && !wasSearchMade && !isFetching;
  const showNoResults = numbers?.length === 0 && wasSearchMade && !isFetching;
  const showNumbersLoadingInProgress = isFetching;

  return (
    <div className="">
      <div className="flex flex-col  flex-wrap w-full">
        <h3 className="text-lg font-bold mb-4">Number search</h3>

        <ErrorComponent className="mb-4" error={error} />

        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(triggerSearch)}
            className="w-full"
          >
            <div className="">
              <div className="flex">
                {/* {onlyForTsgMembers && ( */}
                <div className="Select-Wrapper max-w-lg">
                  <Label htmlFor="ticketType">Type</Label>
                  <Select
                    id="numberType"
                    options={numberTypes}
                    onChange={changeNumberType}
                    value={numberType}
                    defaultValue={numberType}
                  />
                </div>
                {/* )} */}

                <Input
                  wrapperClassName="ml-8 w-[190px]"
                  white
                  label="Contains"
                  name="contains"
                  defaultValue=""
                  placeholder="888xxxxxxx"
                  disabled={false}
                />

                <Input
                  wrapperClassName="ml-8 w-[100px]"
                  white
                  label="Quantity"
                  name="quantity"
                  min={1}
                  max={10}
                  step={1}
                  type="number"
                  defaultValue=""
                  placeholder="5"
                  disabled={false}
                />

                <div className="ml-8">
                  <RadioInputGroup
                    label="Consecutive numbers?"
                    name={"consecutive"}
                    options={[
                      {
                        label: "Yes",
                        value: true,
                      },
                      {
                        label: "No",
                        value: false,
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="flex mt-4">
                <PrimaryButton
                  type="submit"
                  className="flex rounded-sm h-[42px] !w-[140px] align-bottom justify-end self-end "
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Loader small white />
                  ) : (
                    <>
                      Search <SearchIcon className="ml-2" />
                    </>
                  )}
                </PrimaryButton>
                <Button
                  type="button"
                  onClick={clearSearchForm}
                  light
                  className="self-end !rounded-sm ml-8 bg-red-500 hover:bg-red-600 text-white h-[42px] w-[140px]"
                >
                  Clear
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>

      <div className="shadow overflow-x-auto overflow-y-hidden border-b border-gray-200 sm:rounded-lg mt-10 relative min-h-[220px] bg-white">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="relative w-12 px-6 sm:w-16 sm:px-8">
                <input
                  type="checkbox"
                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                  ref={checkbox}
                  checked={checked}
                  onChange={toggleAll}
                />
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Number
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {numbers?.map((phoneNumber) => (
              <tr key={phoneNumber}>
                <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                  {selectedNumbers.includes(phoneNumber) && (
                    <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                  )}
                  <input
                    type="checkbox"
                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                    value={phoneNumber}
                    checked={selectedNumbers.includes(phoneNumber)}
                    onChange={(e) =>
                      setSelectedNumbers(
                        e.target.checked
                          ? [...selectedNumbers, phoneNumber]
                          : selectedNumbers.filter((n) => n !== phoneNumber)
                      )
                    }
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {phoneNumber}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {showNumbersLoadingInProgress && (
          <div className="absolute-center">
            <Loader />
          </div>
        )}

        {showNoSearchMade && (
          <div className="bg-white text-center px-4 py-12">
            Search for numbers first, reserve them and then you can order
            reserved numbers.
          </div>
        )}

        {showNoResults && (
          <div className="bg-white text-center p-4">
            Search did not find any numbers for purchase.
          </div>
        )}
      </div>

      <Button
        light
        className="bg-amber-300 hover:bg-amber-400 text-white mt-6 h-min-[42px] w-[260px]"
        onClick={reserveSelectedNumbers}
        disabled={selectedNumbers.length == 0}
      >
        Reserve selected numbers
      </Button>
    </div>
  );
}

function ReservedNumbers({
  reservedNumbers,
  setReservedNumbers,
}: ReservedNumbersArgs) {
  const [smsEnable, setSmsEnable] = useState(true);

  const orderReservedNumbersMutation = useMutateOrderTollfreeNumbers();

  function clearReservedNumbers() {
    setReservedNumbers([]);
  }

  function orderReservedNumbers() {
    orderReservedNumbersMutation.mutate({
      numbers: reservedNumbers,
      smsEnable,
    });
  }

  const workInProgress = orderReservedNumbersMutation.isLoading;

  return (
    <div>
      <h3 className="text-lg font-bold mb-4">Reserved numbers</h3>
      <div>
        <div className="shadow overflow-x-auto overflow-y-hidden border-b border-gray-200 sm:rounded-lg mt-8">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Number
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {reservedNumbers?.map((phoneNumber) => (
                <tr key={phoneNumber}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {phoneNumber}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {reservedNumbers?.length === 0 && (
            <div className="bg-white text-center px-4 py-12">
              To order numbers you have to reserve them first
            </div>
          )}
        </div>

        <div className="mt-4">
          <label className="cursor-pointer">
            <input
              className="mr-2"
              type="checkbox"
              checked={smsEnable}
              onChange={() => setSmsEnable(!smsEnable)}
            />
            Enable SMS for numbers?
          </label>
        </div>

        <div className="mt-4 flex gap-4">
          <Button
            onClick={orderReservedNumbers}
            disabled={workInProgress || reservedNumbers.length === 0}
            light
            className="bg-green-500 hover:bg-green-600 text-white h-min-[40px] w-[223px]"
          >
            {workInProgress ? <Loader small white /> : "Order reserved numbers"}
          </Button>
          <Button
            onClick={clearReservedNumbers}
            light
            className="bg-red-500 hover:bg-red-600 text-white h-min-[40px] w-[146px]"
            disabled={workInProgress || reservedNumbers.length === 0}
          >
            {workInProgress ? <Loader small white /> : "Clear numbers"}
          </Button>
        </div>
      </div>
    </div>
  );
}

type useSearchPhoneNumbersArgs = {
  contains: string;
  quantity: number;
  consecutive: boolean;
};

const TWO_MINUTES_IN_MS = 1000 * 60 * 2;

function useSearchPhoneNumbers({
  contains,
  quantity,
  consecutive,
}: useSearchPhoneNumbersArgs) {
  const doTriggerSearch = !!contains;

  const {
    isLoading,
    error,
    data: numbers,
    isFetching,
    isFetched,
    isFetchedAfterMount,
    remove,
  } = useQuery(
    ["searchTollfreePhoneNumbersForPurchase", contains, quantity, consecutive],
    async ({}) => {
      const numbers = await searchTollfreePhoneNumbersForPurchase({
        contains,
        quantity,
        consecutive,
      });

      return numbers;
    },
    {
      staleTime: TWO_MINUTES_IN_MS,
      enabled: doTriggerSearch,
    }
  );

  return {
    isLoading,
    isFetching,
    isFetched,
    wasSearchMade: isFetchedAfterMount,
    error: error as Error,
    numbers: numbers || [],
    clearResults: remove,
  };
}

type OrderTollfreeNumbersArgs = {
  numbers: string[];
  smsEnable: boolean;
};

function useMutateOrderTollfreeNumbers() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation<
    OrderTollfreeNumbersResult["orderTollfreeNumbers"],
    Error,
    OrderTollfreeNumbersArgs
  >(({ numbers, smsEnable }) => orderTollfreeNumbers(numbers, smsEnable), {
    onError: (error, _variables, _ctx) => {
      console.error("[ERROR] Order numbers failed", error);

      Toast({
        type: "Error",
        title: `Couldn't order reserved numbers, please try again... Error: ${
          error.message || "Unknown error"
        }`,
        timeout: 10000,
      });
    },
    onSuccess: (data, variables, _ctx) => {
      Toast({
        title: "Numbers ordered",
        type: "Success",
        timeout: 10000,
      });

      queryClient.invalidateQueries(["orderTollfreeNumbers"]);
      queryClient.refetchQueries({
        queryKey: ["orderTollfreeNumbers"],
      });

      queryClient.invalidateQueries(["searchTollfreePhoneNumbersForPurchase"]);

      const purchasedNumbers = data?.numbers.map((number) => {
        return {
          number: number,
          state: "Toll-free",
          rateCenter: "Toll-free",
          smsEnable: variables.smsEnable,
        };
      });

      navigate("/purchase-phone-numbers-success", {
        state: {
          purchasedNumbers,
          failedToPurchaseNumbers: [],
          type: "toll-free",
        },
      });
    },
  });
}

function SearchIcon({ className }: { className: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${className ? className : ""} h-6 w-6`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
      />
    </svg>
  );
}

type FormDataType = {
  contains: string;
  quantity: string;
  consecutive: string;
};

function parseSearchParams(formData: FormDataType) {
  // contains must have 10 digits, the format of "888xxxxxxx"
  let parsedContains = formData.contains.startsWith("1")
    ? formData.contains.slice(1)
    : formData.contains;
  parsedContains =
    parsedContains.length != 10
      ? parsedContains.padEnd(10, "x")
      : parsedContains;
  const quantity = parseInt(formData.quantity || "1", 10);
  const consecutive = formData.consecutive === "true";

  return {
    contains: parsedContains.trim(),
    quantity: quantity,
    consecutive: consecutive,
  };
}

function DisclaimerInfo() {
  return (
    <div>
      After purchasing a toll-free number, please ensure that you are submitting
      a Verified Sender Form{" "}
      <Link className="link" to="/tf-verification-form">
        located here
      </Link>{" "}
      to ensure that your use-case is processed.
      <br />
      More information can be found here:{" "}
      <a
        target="_blank"
        className="link"
        href="https://support.tsgglobal.com/hc/en-us/articles/5938515603739-Toll-Free-SMS-and-MMS-best-practices-and-information-for-using-in-the-US-and-Canada"
      >
        Toll-Free SMS and MMS best practices and information for using in the US
        and Canada{" "}
      </a>
    </div>
  );
}
