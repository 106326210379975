type ItemProp = { value: any; label: string };
type ItemSelectorProps = {
  selectedItem: string;
  selectItem: (type: any) => void;
  items: ItemProp[];
  title: string;
};

export function ItemSelector({
  selectedItem,
  selectItem,
  items,
  title = "",
}: ItemSelectorProps) {
  return (
    <div className="flex items-center">
      <span className="mr-3 font-semibold">{title}</span>

      <div className="flex flex-wrap gap-3">
        {items.map((item) => (
          <ItemSelectorItem
            key={item.value}
            selectedItem={selectedItem}
            selectItem={selectItem}
            item={item}
          />
        ))}
      </div>
    </div>
  );
}

type ItemSelectorItemProps = {
  selectedItem: any;
  selectItem: (type: any) => void;
  item: ItemProp;
};

function ItemSelectorItem({
  selectItem,
  selectedItem,
  item,
}: ItemSelectorItemProps) {
  if (selectedItem == item.value) {
    return (
      <span className="w-16 h-8 flex justify-center items-center  bg-indigo-50 border-indigo-500 text-gray-800 text-sm text-center border rounded-md cursor-default">
        {item.label}
      </span>
    );
  } else {
    return (
      <button
        type="button"
        className="w-16 h-8 flex justify-center items-center bg-white hover:bg-gray-100 active:bg-gray-200 text-gray-800 text-sm text-center border rounded-md transition duration-100"
        onClick={() => selectItem(item.value)}
      >
        {item.label}
      </button>
    );
  }
}
