import "./App.css";
import { BrowserRouter } from "react-router-dom";
import AuthenticatedApp from "./AuthenticatedApp";
import UnauthenticatedApp from "./UnauthenticatedApp";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import ScrollToTop from "./components/ScrollToTop";
import { useAuthStore } from "./stores/authStore";
import { useEffect } from "react";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        {/* ensure that we always scroll to top on page change */}
        <ScrollToTop />
        <InnerApp />
      </BrowserRouter>
    </QueryClientProvider>
  );
}

function InnerApp() {
  const isAuthenticated = useAuthStore((store) => store.state.isAuthenticated);
  const checkAuth = useAuthStore((store) => store.checkAuth);

  useEffect(() => {
    checkAuth();
  }, []);

  if (isAuthenticated) {
    return <AuthenticatedApp />;
  } else {
    return <UnauthenticatedApp />;
  }
}

export default App;
