import { gql } from "graphql-request";
import {
  OrderTollfreeNumbersResult,
  SearchTollfreeNumbersForPurchaseResult,
} from "~/types";
import { graphqlClient } from "./graphqlClient";

// **Example Flow**
//
// - 1 - searchTollfreePhoneNumbersForPurchase - User searches for numbers, uses filters to get the numbers they want/need
// - 2 - orderTollfreeNumbers - User orders the numbers they want

type searchTollfreePhoneNumbersForPurchaseArgs = {
  contains: string;
  quantity: number;
  consecutive: boolean;
};

export async function searchTollfreePhoneNumbersForPurchase({
  contains,
  quantity,
  consecutive,
}: searchTollfreePhoneNumbersForPurchaseArgs) {
  const query = gql`
    query searchPhoneNumbersQuery(
      $contains: String
      $quantity: Int
      $consecutive: Boolean
    ) {
      tollfreeNumbers(
        contains: $contains
        quantity: $quantity
        consecutive: $consecutive
      ) {
        numbers
      }
    }
  `;

  const data = {
    contains,
    quantity,
    consecutive,
  };

  try {
    const response: SearchTollfreeNumbersForPurchaseResult =
      await graphqlClient(query, data);

    if (response.tollfreeNumbers.numbers) {
      return response.tollfreeNumbers.numbers;
    } else {
      throw response;
    }
  } catch (errorResponse) {
    throw errorResponse;
  }
}

export async function orderTollfreeNumbers(
  numbers: string[],
  smsEnable: boolean
) {
  const mutation = gql`
    mutation orderTollfreeNumbersMutation(
      $numbers: [String]
      $smsEnable: Boolean
    ) {
      orderTollfreeNumbers(numbers: $numbers, smsEnable: $smsEnable) {
        numbers
        orderId
      }
    }
  `;

  const data = {
    numbers,
    smsEnable,
  };

  try {
    const response: OrderTollfreeNumbersResult = await graphqlClient(
      mutation,
      data
    );
    // numbers is both the query and the response object, so we need to access numbers 2 times
    if (response.orderTollfreeNumbers) {
      return response.orderTollfreeNumbers;
    } else {
      throw response;
    }
  } catch (errorResponse) {
    throw errorResponse;
  }
}
