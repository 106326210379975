// import { User } from "~/types";
import { httpClient } from "./httpClient";

export async function login(email: string, password: string) {
  const response = await httpClient("/account/login", {
    method: "POST",
    body: {
      email,
      password,
      // this is probably not needed
      otp: null,
    },
  });

  // @ts-ignore
  const { token, user } = response.body;

  return { token, user };
}

// export async function requestPasswordResetApi(email: string) {
//   const response = await httpClient("/api/v1/accounts/request-password-reset", {
//     method: "POST",
//     body: {
//       email,
//     },
//   });

//   return response.body;
// }

// export async function resetPasswordApi(password: string, token: string) {
//   const response = await httpClient(
//     `/api/v1/accounts/password-reset/${token}`,
//     {
//       method: "POST",
//       body: {
//         password: password,
//       },
//     }
//   );

//   return response.body;
// }

// export async function getUserInfo(token?: string) {
//   // If we have a token we'll override the config to use that token to log in,
//   // this is for 3rd party OAuth scenarios via Google and Microsoft
//   const config = token
//     ? {
//         headers: buildAuthorizationHeader(token),
//       }
//     : undefined;

//   const response = await httpClient("/api/v1/account/user", config);
//   return response.body;
// }

// export async function getProfile(token?: string) {
//   // If we have a token we'll override the config to use that token to log in,
//   // this is for 3rd party OAuth scenarios via Google and Microsoft
//   const config = token
//     ? {
//         headers: buildAuthorizationHeader(token),
//       }
//     : undefined;

//   const response = await httpClient("/api/v1/profile", config);
//   return response.body.data;
// }

export async function replaceCodeWithToken(code: string): Promise<any> {
  const response = await httpClient("/api/v1/auth/code", {
    method: "POST",
    body: {
      code,
    },
  });

  return response.body;
}
