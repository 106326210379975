import { buildAuthorizationHeader, httpClient } from "./httpClient";
import { pick } from "lodash-es";
import { TelephoneNumberInfo, PhoneNumberDestination } from "../types";
import { apiServiceBaseUrl } from "~/config";

type ListPhoneNumbersParams = {
  page?: number;
  page_size?: number;
  did?: Array<string>;
  market?: string;
  orderId?: string;
  sortBy?: string;
  companyId: string;
};

export type PhoneNumber = {
  account: Account;
  activated: boolean;
  cnam: Cnam;
  description: string;
  dex_id?: null;
  directory: DirectoryOrE911OrWhitepages;
  disconnect_at?: null;
  e911: DirectoryOrE911OrWhitepages;
  expire_at: string;
  inserted_at?: null;
  last_used_at: string;
  last_used_date: string;
  market: string;
  mms: Mms;
  notes?: null;
  number: string;
  origin_order_id: string;
  payphone_block: boolean;
  portal_id?: null;
  ported: boolean;
  provisioned_date: string;
  rate_center: string;
  request: Request;
  sms: Sms;
  state: string;
  tier: string;
  type_config: string;
  updated_at?: null;
  voice: Voice;
  wcdb_id: number;
  whitepages: DirectoryOrE911OrWhitepages;
  number_type: PhoneNumberType;
  compliance_status: ComplianceStatusType;
};

export type PhoneNumberType = "short-code" | "long-code" | "toll-free";
export type ComplianceStatusType =
  | "non_compliant"
  | "in_progress"
  | "compliant";

export type Account = {
  account_code: string;
  company_id?: null;
  id?: null;
};
export type Cnam = {
  account: Account;
  inbound_lookup: boolean;
  owned: boolean;
};
export type DirectoryOrE911OrWhitepages = {
  account: Account1;
  data?: null;
  enabled: boolean;
  owned: boolean;
  provider?: null;
};
export type Account1 = {
  account_code?: null;
  company_id?: null;
  id?: null;
};
export type Mms = {
  account: Account;
  api_key: ApiKey;
  data?: null;
  enabled: boolean;
  owned: boolean;
  provider: string;
  type: string;
};
export type ApiKey = {
  id?: null;
  system_id?: null;
};
export type Request = {
  id?: null;
};
export type Sms = {
  account: Account;
  api_key: ApiKey;
  data?: null;
  enabled: boolean;
  owned: boolean;
  provider: string;
  type: string;
  webhook_type?: null;
};
export type Voice = {
  account: Account;
  channels: Channels;
  data?: null;
  enabled: boolean;
  hold_media: string;
  minute_price: string;
  owned: boolean;
  provider: string;
  seconds_used: number;
};
export type Channels = {
  in_use: number;
  max: number;
};

export type ListPhoneNumbersReturn = {
  phoneNumbers: PhoneNumber[];
  totalPages: number;
  total: number;
  currentPage: number;
  perPage: number;
};

export async function listPhoneNumbers(
  listParams: ListPhoneNumbersParams
): Promise<ListPhoneNumbersReturn> {
  const page = listParams.page ?? 1;
  const page_size = listParams.page_size ?? 20;
  const url = `/dids`;

  let filter = buildFilterParams(
    pick(listParams, ["did", "market", "orderId", "companyId"])
  );

  const { body: phoneNumbers, headers } = await httpClient(url, {
    body: {
      page,
      page_size,
      sort: listParams.sortBy,
      filter: filter,
      // q: q,
      // filter: filter,
    },
  });

  return {
    phoneNumbers,
    totalPages: parseInt(headers["total-pages"]),
    total: parseInt(headers["total"]),
    currentPage: parseInt(headers["page-number"]),
    perPage: parseInt(headers["per-page"]),
  };
}

export async function listAllPhoneNumbersAsCsv(
  listParams: Omit<ListPhoneNumbersParams, "companyId">
): Promise<string> {
  const url = `/export/numbers`;
  const filter = buildFilterParams(
    pick(listParams, ["did", "market", "orderId"])
  );

  const { body: data } = await httpClient(url, {
    body: {
      sort: listParams.sortBy,
      filter: filter,
    },
  });

  return data;
}

export async function getPhoneNumber(phoneNumber: string) {
  const url = `/dids/${phoneNumber}`;
  const { body: telephoneNumber } = await httpClient(url);

  return { phoneNumber: telephoneNumber };
}

export async function getPhoneNumberDestinations(phoneNumber: string) {
  const url = `/dids/${phoneNumber}/destinations?sort=priority`;
  const { body: destinations } = await httpClient(url);
  return { destinations };
}

// type DataPayload = {
//   id?: number,
//   priority: number,
//   type: string,
//   // type = "voip" has the phone number saved as did, and
//   //  the ip as the destination
//   // type = "pstn" has the destination empty
//   did: string | null,
//   // type = "pstn" has the telephone number stored as destination
//   destination: string
// }

export async function createDestination(
  phoneNumber: string,
  data: PhoneNumberDestination
) {
  const url = `/dids/${phoneNumber}/destinations`;
  const { body: telephoneNumber } = await httpClient(url, {
    body: data,
    method: "POST",
  });

  return { phoneNumber: telephoneNumber };
}

export async function saveDestination(
  phoneNumber: string,
  data: PhoneNumberDestination
) {
  const url = `/dids/${phoneNumber}/destinations/${data.priority}`;
  const { body: telephoneNumber } = await httpClient(url, {
    body: data,
    method: "PATCH",
  });

  return { phoneNumber: telephoneNumber };
}

export async function deleteDestination(phoneNumber: string, priority: number) {
  const url = `/dids/${phoneNumber}/destinations/${priority}`;
  const { body: telephoneNumber } = await httpClient(url, {
    method: "DELETE",
  });

  return { phoneNumber: telephoneNumber };
}

export type E911RequestData = {
  number: string;
  caller_name: string;
  address: string;
  city: string;
  state: string;
  zip_code: string;
};

export async function activateE911Address(data: E911RequestData) {
  const url = `/e911/address`;
  const response = await httpClient(url, {
    body: data,
    method: "POST",
  });

  return response.body;
}

type Number = string;
type ErrorReason = string;

type BatchUpdateDestinationResponse = {
  success: Number[];
  error: [Number, ErrorReason][];
};

export async function batchUpdateDestination(
  destination: string,
  phoneNumbers: string[]
): Promise<BatchUpdateDestinationResponse> {
  const url = `/dids/destinations`;
  const { body: response } = await httpClient(url, {
    body: {
      destination,
      numbers: phoneNumbers,
    },
    method: "PUT",
  });

  return response;
}

function buildFilterParams(params: any) {
  return Object.keys(params).reduce((acc: any, key: string) => {
    if (params[key] != undefined && params[key] != "") {
      const mappedKey = mapFields[key as mapFieldsKey] || key;
      acc[mappedKey] = prepareValue(key, params[key]);
    }

    return acc;
  }, {});
}

function prepareValue(field: string, value: string) {
  if (["orderId"].includes(field)) return value + "*";
  return value;
}

type mapFieldsKey = keyof typeof mapFields;
const mapFields = {
  phoneNumber: "number",
  orderId: "origin_order_id",
  market: "market",
  companyId: "company_id",
};

// https://api.portal.tsgglobal.world/call_records/voice?page=1&q=&filter[>=start_date]=2022-02-02T00:01:01.000Z&filter[<=start_date]=2022-02-09T01:02:02.000Z&page_size=10&sort=-started_at

// "filter[number]": 177*
// "filter[origin_order_id]": HOP*
// "filter[market]": sms_only
// "filter[sms_enabled]": true
// "filter[mms_enabled]": true

interface RateCenterItem {
  name: string;
  state: string;
  abbreviation: string;
}

export async function searchRateCenters(
  usState: string
): Promise<RateCenterItem[]> {
  const url = `/static_rate_centers`;
  const { body: response } = await httpClient(url, {
    parameters: {
      state: usState,
    },
    method: "GET",
  });

  return response;
}

export async function getTelephoneNumberInfo(
  token: string,
  number: string
): Promise<TelephoneNumberInfo> {
  const path = `/v4/dids/${number}`;

  const config = {
    baseUrl: apiServiceBaseUrl,
    headers: buildAuthorizationHeader(token),
  };

  const response = await httpClient(path, config);

  return response.body;
}

export async function createSmsEnablement(
  token: string,
  payload: object
): Promise<any> {
  const path = `/v1/sms/enablement`;

  const config = {
    baseUrl: apiServiceBaseUrl,
    headers: buildAuthorizationHeader(token),
    body: payload,
  };

  const response = await httpClient(path, config);

  return response.body;
}
