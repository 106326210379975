import { ContinueWithFacebookButton } from "./components/ContinueWithFacebookButton";
import { ContinueWithGoogleButton } from "./components/ContinueWithGoogleButton";
import { ForgotPasswordAction } from "./components/ForgotPasswordAction";

import { LogInForm } from "./components/LoginForm";
import { portalName } from "../../config";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

export function LogIn() {
  return (
    <div className="LogIn py-40">
      <h2 className="text-white text-2xl lg:text-2xl font-bold text-center md:mb-8 mb-12">
        {portalName}
      </h2>

      <LogInForm />

      <div>
        <LoginNotification />
      </div>
    </div>
  );
}

// Temporary message for transitioning users.

export default function LoginNotification() {
  return (
    <div className="Login-TemporaryLoginNotification mt-16 rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-bold text-yellow-800">Login notice</h3>
          <div className="mt-2 text-xs text-yellow-700">
            <p>
              This is the new portal for TSG customers, use your email to log
              in. <br />
              If you don't know your password use the "Forgot Your Password?"
              link above. If you don't know or don't have an email reach out to{" "}
              <a className="underline" href="mailto:support@tsgglobal.com">
                support@tsgglobal.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
