import { useQuery } from "react-query";
import {
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subMinutes,
} from "date-fns";
import { countCdrRecords, getCdrRecords } from "../../api/cdrs";
import { useEffect, useRef, useState } from "react";
import { Button } from "../../components/Button";
// @ts-ignore
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker/dist/entry.nostyle";
// @ts-ignore
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/entry.nostyle";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import { Loader, LoaderOverlay } from "../../components/Loader";
import CdrListPagination from "./CdrListPagination";
import { uniqueId } from "lodash-es";
import { RecordType } from "../../types";
import { TypeSelector } from "../../components/TypeSelector";
import { InfoTooltip } from "../../components/InfoTooltip";
import { BaseInput } from "../../components/Input";
import {
  formatServerError,
  isErrorWithMessage,
  isGraphQlError,
} from "../../utils/error";

// 2021 is as good enough year for a minimum date
const minimumDate = new Date("2021-01-01");

export function CdrList() {
  const [dates, setDates] = useState(() => [
    startOfDay(new Date()),
    new Date(),
  ]);
  const [type, setType] = useState<RecordType>("SMS");
  const [showTime, setShowTime] = useState(false);
  const [sourceNumber, setSourceNumber] = useState("");
  const [destinationNumber, setDestinationNumber] = useState("");

  const {
    isLoading,
    isFetching,
    error,
    data: cdrData,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
  } = useCdrList({
    type,
    startDatetime: dates[0],
    endDatetime: dates[1],
    sourceNumber: sourceNumber,
    destinationNumber: destinationNumber,
    limit: 20,
    useTime: !!showTime,
  });

  function setDateRange(dates: [Date, Date]) {
    setDates(dates);
  }

  function setDateTimeRange(dates: [Date, Date]) {
    setDates(dates);
  }

  function showLast5Minutes() {
    setShowTime(true);
    setDates([subMinutes(new Date(), 5), new Date()]);
  }

  function showToday() {
    setDates([startOfDay(new Date()), new Date()]);
  }

  function showThisWeek() {
    setDates([startOfWeek(new Date()), new Date()]);
  }

  function showThisMonth() {
    setDates([startOfMonth(new Date()), new Date()]);
  }

  if (isLoading) {
    return (
      <div className="CdrList py-2 sm:px-6 lg:px-8">
        <Loader />
      </div>
    );
  }
  const cdrs = cdrData?.cdrs || [];
  const exportLink = cdrData?.exportLink;

  return (
    <div className="CdrList py-2 sm:px-6 lg:px-8">
      <h2 className="text-2xl lg:text-3xl font-bold mt-2 mb-6">
        CDRs
        <InfoTooltip className="ml-4">
          Lists your records. It might take a few seconds for your results to
          become visible.
          <br />
          <br />
          <b>Note: </b>If you're having problems listing your CDRs it might mean
          the dataset is too large,
          <br />
          you can try reducing the date range and enabling the "Show time"
          functionality.
        </InfoTooltip>
      </h2>

      <div>
        <div className="flex flex-row items-end flex-wrap gap-4">
          <TypeSelector activeType={type} setType={setType} />

          <div className="flex flex-row gap-2">
            <BaseInput
              white
              label="Source"
              name="sourceNumber"
              defaultValue=""
              placeholder="19512111234"
              disabled={false}
              wrapperClassName="w-full"
              inputClassName="h-[32px]"
              onEnterOrBlur={(val) => setSourceNumber(val)}
            />
            <BaseInput
              white
              label="Destination"
              name="destinationNumber"
              defaultValue=""
              placeholder="19512111236"
              disabled={false}
              wrapperClassName="w-full"
              inputClassName="h-[32px]"
              onEnterOrBlur={(val) => setDestinationNumber(val)}
            />
          </div>

          <ExportCdrsLink className="ml-auto p-1" exportLink={exportLink} />
        </div>

        <div className="mt-4 flex flex-wrap">
          <span className="w-14 font-semibold">Dates</span>

          <div className="flex flex-col items-start">
            {showTime ? (
              <DateTimeRangePicker
                className="rounded-md bg-white"
                onChange={setDateTimeRange}
                value={dates}
                minDate={minimumDate}
                maxDate={new Date()}
                clearIcon={null}
              />
            ) : (
              <DateRangePicker
                className="rounded-md bg-white"
                onChange={setDateRange}
                value={dates}
                minDate={minimumDate}
                maxDate={new Date()}
                clearIcon={null}
              />
            )}

            <div className="mt-2">
              <label>
                <input
                  className="mr-2"
                  type="checkbox"
                  checked={showTime}
                  onChange={() => setShowTime(!showTime)}
                />
                Show time
              </label>
            </div>
          </div>

          <div className="ml-auto flex gap-3 sm:ml-auto">
            <Button className="self-start" small onClick={showLast5Minutes}>
              Last 5 minutes
            </Button>
            <Button className="self-start" small onClick={showToday}>
              Today
            </Button>
            <Button className="self-start" small onClick={showThisWeek}>
              This week
            </Button>
            <Button className="self-start" small onClick={showThisMonth}>
              This month
            </Button>
          </div>
        </div>
      </div>

      <div className="relative">
        {(isLoading || isFetching) && <LoaderOverlay />}

        <CdrListError error={error} />

        {cdrs.length ? (
          <CdrData
            type={type}
            cdrs={cdrs}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            fetchPreviousPage={fetchPreviousPage}
            hasPreviousPage={hasPreviousPage}
            isLoading={isLoading}
            isFetching={isFetching}
          />
        ) : (
          <NoRecordsAvailable type={type} />
        )}
      </div>
    </div>
  );
}

type CdrDataProps = {
  type: RecordType;
  cdrs: any[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  fetchNextPage: () => void;
  fetchPreviousPage: () => void;
  isLoading: boolean;
  isFetching: boolean;
};

function CdrData({
  type,
  cdrs,
  hasNextPage,
  fetchNextPage,
  fetchPreviousPage,
  hasPreviousPage,
  isLoading,
  isFetching,
}: CdrDataProps) {
  return (
    <>
      <CdrTable type={type} cdrs={cdrs} />
      <CdrListPagination
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        isLoading={isLoading}
        isFetching={isFetching}
        onNextClick={fetchNextPage}
        onPreviousClick={fetchPreviousPage}
      />
    </>
  );
}

type CdrTableProps = {
  cdrs: any[];
  type: RecordType;
};

function CdrTable({ cdrs, type }: CdrTableProps) {
  const columns = <CdrTableHeader type={type} />;
  const rows = cdrs.map((cdr) => (
    <CdrTableRow key={cdr.key} type={type} cdr={cdr} />
  ));

  return (
    <div className="flex flex-col my-6">
      <div className="overflow-x-auto -my-2 sm:-mx-6 lg:-mx-8">
        <div className="align-middle inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">{columns}</thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {rows}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

type ExportCdrsLinkProps = {
  exportLink: string;
  className: string;
};

function ExportCdrsLink({ exportLink, className }: ExportCdrsLinkProps) {
  // If there was an error, there might not be an export link.
  if (!exportLink) return null;

  const classes = `flex ${className ?? ""}`;
  return (
    <a className={classes} href={exportLink} target="_blank">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
      </svg>
      Export all as CSV
    </a>
  );
}

type TypeProp = { type: RecordType };

function NoRecordsAvailable({ type }: TypeProp) {
  return (
    <div className="text-center my-24 text-2xl">
      No {type} records, please modify your search.
    </div>
  );
}

function CdrTableHeader({ type }: TypeProp) {
  switch (type) {
    // SMS and MMS are identical
    case "SMS":
    case "MMS":
      // columns:
      //
      // datetime
      // fromNumber
      // toNumber
      // direction
      // status
      // charge
      // type
      return (
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Record
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Charge
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Status
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Type
          </th>
        </tr>
      );

    case "VOICE":
      // columns:
      //
      // datetime
      // callId
      // sourceAni
      // destinationNumber
      // didUsed
      // sessionTime
      // status
      // calledRate
      // charge
      // callType
      // aniIi
      return (
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Record
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Charge
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Status
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Type
          </th>
        </tr>
      );

    case "API":
      // columns:
      //
      // datetime
      // from
      // destination
      // status
      // charge
      // type
      return (
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Record
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Charge
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Status
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Type
          </th>
        </tr>
      );
  }
}

type CdrProps = { cdr: any };
type TypeAndCdrProps = TypeProp & CdrProps;

function CdrTableRow({ type, cdr }: TypeAndCdrProps) {
  switch (type) {
    // SMS and MMS are identical
    case "SMS":
    case "MMS":
      return (
        <tr>
          <td className="px-6 py-1 whitespace-nowrap">
            <div>
              <div className="text-sm font-medium text-gray-900">
                {format(new Date(cdr.datetime), "MM/dd/yyyy HH:mm:ss")}
              </div>
              <div className="text-sm text-gray-500">{cdr.direction}</div>
            </div>
          </td>
          <td className="px-6 py-1">
            <div className="text-sm text-gray-900">
              ${formatCharge(cdr.charge)}
            </div>
            <div className="text-sm text-gray-500">
              +{cdr.fromNumber}{" "}
              <span className="align-text-bottom">&#8594;</span> +{cdr.toNumber}
            </div>
          </td>
          <td className="px-6 py-1 whitespace-nowrap">
            <Status statusText={cdr.status} />
          </td>
          <td className="px-6 py-1 whitespace-nowrap text-sm text-gray-500">
            {cdr.type}
          </td>
        </tr>
      );

    case "VOICE":
      return (
        <tr>
          <td className="px-6 py-1 whitespace-nowrap">
            <div>
              <div className="text-sm font-medium text-gray-900">
                {format(new Date(cdr.datetime), "MM/dd/yyyy HH:mm:ss")}
              </div>
              <div className="text-sm text-gray-500">{cdr.callId}</div>
            </div>
          </td>
          <td className="px-6 py-1">
            <div className="text-sm text-gray-900">
              ${formatCharge(cdr.charge)} ({cdr.sessionTime}s)
            </div>
            <div className="text-sm text-gray-500">
              {cdr.sourceAni || "N/A"} {">"} {cdr.destinationNumber || "N/A"}
            </div>
          </td>
          <td className="px-6 py-1 whitespace-nowrap">
            <Status statusText={cdr.status} />
          </td>
          <td className="px-6 py-1 whitespace-nowrap text-sm text-gray-500">
            {cdr.callType}
          </td>
        </tr>
      );

    case "API":
      return (
        <tr>
          <td className="px-6 py-1 whitespace-nowrap">
            <div>
              <div className="text-sm font-medium text-gray-900">
                {format(new Date(cdr.datetime), "MM/dd/yyyy HH:mm:ss")}
              </div>
              {/* <div className="text-sm text-gray-500">{cdr.direction}</div> */}
            </div>
          </td>
          <td className="px-6 py-1">
            <div className="text-sm text-gray-900">
              ${formatCharge(cdr.charge)}
            </div>
            <div className="text-sm text-gray-500">
              {cdr.from} {">"} {cdr.destination}
            </div>
          </td>
          <td className="px-6 py-1 whitespace-nowrap">
            <Status statusText={cdr.status} />
          </td>
          <td className="px-6 py-1 whitespace-nowrap text-sm text-gray-500">
            {cdr.type}
          </td>
        </tr>
      );
  }
}

type useCdrListArgs = {
  type: string;
  startDatetime: Date;
  endDatetime: Date;
  limit?: number;
  sourceNumber?: string;
  destinationNumber?: string;
  // if the relevant time is passed as well
  useTime?: boolean;
};

const TWO_MINUTES_IN_MS = 1000 * 60 * 2;

function useCdrList({
  type,
  startDatetime,
  endDatetime,
  sourceNumber,
  destinationNumber,
  limit,
  useTime = false,
}: useCdrListArgs) {
  // this'll hold previous cursors so we can easily navigate back in time
  // our cdr query returns only the nextCursor so this is a workaround for that
  const cursorPaginationReference = useRef<any[]>([null]);

  const [cursor, setCursor] = useState<string | null>();

  sourceNumber = sourceNumber?.replace("+", "");
  destinationNumber = destinationNumber?.replace("+", "");

  useEffect(() => {
    setCursor(null);
    cursorPaginationReference.current = [null];
  }, [type, startDatetime, endDatetime, sourceNumber, destinationNumber]);

  let [normalizedStartDatetime, normalizedEndDateTime] = [
    startDatetime,
    endDatetime,
  ];

  // if the user is only selecting dates, ensure there's no date shift
  // due to local timezone to UTC conversion
  if (useTime === false) {
    [normalizedStartDatetime, normalizedEndDateTime] =
      fixForUtcConversionDateShift(startDatetime, endDatetime);
  }

  const { isLoading, error, data, isFetching, isPreviousData } = useQuery(
    [
      "cdrList",
      type,
      startDatetime,
      endDatetime,
      cursor,
      sourceNumber,
      destinationNumber,
    ],
    async ({}) => {
      const cdrData = await getCdrRecords({
        type,
        startDatetime: normalizedStartDatetime.toISOString(),
        endDatetime: normalizedEndDateTime.toISOString(),
        sourceNumber: sourceNumber || undefined,
        destinationNumber: destinationNumber || undefined,
        cursor: cursor ?? undefined,
        limit,
      });

      return {
        ...cdrData,
        cdrs: addKeyProperties(cdrData.cdrs),
      };
    },
    {
      keepPreviousData: true,
      staleTime: TWO_MINUTES_IN_MS,
      retry: false,
    }
  );

  const nextCursor = data?.paginationInfo.nextCursor ?? null;

  function fetchNextPage() {
    // both previousCursor and cursor start with a null
    // no need to keep both
    if (cursorPaginationReference.current.length >= 1 && cursor !== null) {
      cursorPaginationReference.current.push(cursor);
    }

    setCursor(nextCursor);
  }

  function fetchPreviousPage() {
    let previousCursor = null;
    if (cursorPaginationReference.current.length > 1) {
      previousCursor = cursorPaginationReference.current.pop();
    }

    setCursor(previousCursor);
  }

  return {
    isLoading,
    isFetching,
    error,
    data,
    isPreviousData,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage: !!nextCursor,
    hasPreviousPage: !!cursor,
  };
}

/**
 * Functions ensures that the dates don't shift into previous or next year
 * when converting the date from user's timezone to UTC timezone.
 *
 * For example, the user can select the first or last day of the year,
 * and the date times is being converted from user's timezone
 * to UTC, that might shift the date into another year in UTC,
 * that means the date range can span 2 years, but our backend only
 * support getting results within a single year.
 *
 * That's why we need to ensure the range doesn't overflow into the next year.
 * We'll do that by "clamping" the date to the start or end of the year.
 *
 * For example:
 *
 * User selects the end date as: 2023-12-31 23:00:00 GMT-0800 (Pacific Standard Time)
 * In UTC it means it's: "2024-01-01T07:00:00.000Z"
 *
 * That moves the UTC date to the next year,
 * so we'll have to "clamp" the date to: "2023-12-31T23:59:59.999Z"
 *
 * That ensures that the date range remains inside of the same year
 * in these edge cases.
 */
function fixForUtcConversionDateShift(startDatetime: Date, endDateTime: Date) {
  if (startDatetime.getDate() === 1 && startDatetime.getMonth() === 0) {
    startDatetime = removeOffset(startDatetime);
  }

  if (endDateTime.getDate() === 31 && endDateTime.getMonth() === 11) {
    endDateTime = removeOffset(endDateTime);
  }

  return [startDatetime, endDateTime];
}

function removeOffset(date: Date): Date {
  const fixedDate = new Date(date);
  const localTime = fixedDate.getTime();
  const localOffset = fixedDate.getTimezoneOffset() * 60000; // offset in milliseconds
  const utcDate = new Date(localTime - localOffset);
  return utcDate;
}

function formatCharge(charge: string) {
  // sometimes scientific format is returned, usually for 0 values
  if (charge == "0E-10") return "0.000000";
  return charge.slice(0, 8);
}

type StatusProps = {
  statusText: string;
};

function Status({ statusText }: StatusProps) {
  const classes = getStatusColor(statusText);

  return <span className={classes}>{statusText}</span>;
}

function CdrListError({ error }: { error: any }) {
  if (!error) return null;

  let message;
  if (isErrorWithMessage(error)) {
    message = `An error has occured: ${formatServerError(error)}`;
  } else {
    message = `An error has occurred: ${(error as Error).message}`;
  }

  return <div className="Error mt-4">{message}</div>;
}

function getStatusColor(statusText: string) {
  let status = statusText.toUpperCase();
  let base = "px-2 inline-flex text-xs leading-5 font-semibold rounded-full";

  if (status === "SENT" || status === "ANSWER") {
    return `${base} bg-green-100 text-green-800`;
  } else if (status === "BUSY" || status === "CANCEL") {
    return `${base} bg-yellow-100 text-yellow-800`;
  } else if (status === "CONGESTION") {
    return `${base} bg-red-100 text-red-800`;
  } else {
    return base;
  }
}

function addKeyProperties(cdrs: any[] = []) {
  return cdrs.map((c) => {
    const key = uniqueId("cdrlist_");
    return { ...c, key };
  });
}
