// this ensures that we always scroll to top when changing pages
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  // on each location change we'll just call scrollTo and that should be it
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      // there's a few different scroll animations
      behavior: "smooth",
    });
  }, [pathname]);

  // no need to render anything
  return <></>;
}
