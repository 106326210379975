// @ts-ignore
import DateRangePickerComponent from "@wojtekmaj/react-daterange-picker/dist/entry.nostyle";
// import DateTimeRangePickerComponent from "@wojtekmaj/react-datetimerange-picker/dist/entry.nostyle";
// @ts-ignore
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
// import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";

type DateRangePickerProps = {
  value: Date[];
  onChange: (dates: [Date, Date] | [null, null]) => void;
  minimumDate?: Date;
  maximumDate?: Date;
  // if you want to enable clearing the input
  enableClear?: boolean;
  // set to true if you want a month selector
  monthly?: boolean;
};

export function DateRangePicker({
  value,
  onChange,
  minimumDate,
  maximumDate,
  enableClear = false,
  monthly = false,
}: DateRangePickerProps) {
  // setting clearIcon to null will remove the action completely,
  // setting it to undefined will leave the default icon.
  const clearIcon = enableClear === false ? null : undefined;
  const maxDetail = monthly ? "year" : "month";

  function datesChanged(dates: [Date, Date] | null) {
    onChange(dates || [null, null]);
  }

  return (
    <div>
      <DateRangePickerComponent
        className="DatePicker"
        onChange={datesChanged}
        value={value}
        minDate={minimumDate}
        maxDate={maximumDate}
        clearIcon={clearIcon}
        maxDetail={maxDetail}
      />
    </div>
  );
}
