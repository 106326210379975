import classNames from "classnames";
import React, { useId } from "react";
import {
  Controller,
  RegisterOptions,
  useFormContext,
  useFormState,
  useWatch,
} from "react-hook-form";
import { ErrorComponent, FieldError } from "../Error";
import { Label } from "../Label";
import { Required } from "./Required";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string | React.ReactNode;
  validation?: RegisterOptions;
  inputClassName?: string;
  wrapperClassName?: string;
  white?: boolean;
  tight?: boolean;
  noLabel?: boolean;
  required?: boolean;
  hideError?: boolean;
  helperMessage?: string | React.ReactNode;
}

interface BaseInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string | React.ReactNode;
  validation?: RegisterOptions;
  inputClassName?: string;
  wrapperClassName?: string;
  white?: boolean;
  error?: any;
  required?: boolean;
  onEnter?: (val: string) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  onEnterOrBlur?: (val: string) => void;
}

export function BaseInput({
  label,
  name,
  wrapperClassName,
  inputClassName,
  white,
  error,
  onEnter,
  onBlur,
  onEnterOrBlur,
  required,
  ...props
}: BaseInputProps) {
  const classes = classNames("Input", {
    "Input-White": white,
    [`${inputClassName}`]: inputClassName,
  });

  const wrapperClasses = classNames({
    [`${wrapperClassName}`]: wrapperClassName,
  });

  function onKeyUpFn(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      onEnter && onEnter(event.currentTarget.value);
      onEnterOrBlur && onEnterOrBlur(event.currentTarget.value);
    }
  }

  function onBlurFn(event: React.FocusEvent<HTMLInputElement>) {
    onEnterOrBlur && onEnterOrBlur(event.currentTarget.value);
  }

  return (
    <div className={wrapperClasses}>
      <Label htmlFor={name}>
        {label}
        <Required required={required} />
      </Label>
      <input
        id={name}
        className={classes}
        {...props}
        onKeyUp={onKeyUpFn}
        onBlur={onBlurFn}
      />
      <ErrorComponent error={error} />
    </div>
  );
}

export function Input({
  label,
  name,
  validation = {},
  wrapperClassName,
  inputClassName,
  white,
  tight,
  noLabel,
  required,
  helperMessage,
  ...props
}: InputProps) {
  const { register, formState } = useFormContext();

  const classes = classNames("Input", {
    "Input-White": white,
    "Input-Tight": tight,
    [`${inputClassName}`]: inputClassName,
  });

  const wrapperClasses = classNames({
    [`${wrapperClassName}`]: wrapperClassName,
    "Input-No-Label": noLabel,
  });

  return (
    <div className={wrapperClasses}>
      <Label htmlFor={name}>
        {label}
        <Required required={required} />
      </Label>
      <input
        onWheel={props.type === "number" ? disableScroll : undefined}
        id={name}
        {...props}
        {...register(name, validation)}
        className={classes}
      />

      <HelperMessage helperMessage={helperMessage} />

      <FieldError errors={formState.errors} name={name} />
    </div>
  );
}

// disables scrolling from updating the value
function disableScroll(event: React.WheelEvent<HTMLInputElement>) {
  // Prevent the input value change
  // @ts-ignore
  event.target.blur();

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    // @ts-ignore
    event.target.focus();
  }, 0);
}

// disables up-down arrows from updating the value
// function disableUpDownArrows(event: React.KeyboardEvent<HTMLInputElement>): undefined {
//   if (event.key === "ArrowUp" || event.key === "ArrowDown") {
//     event.preventDefault();
//  }
// }

interface TextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label?: string | React.ReactNode;
  validation?: RegisterOptions;
  inputClassName?: string;
  wrapperClassName?: string;
  white?: boolean;
  tight?: boolean;
  noLabel?: boolean;
  required?: boolean;
  helperMessage?: string | React.ReactNode;
}

export function TextArea({
  label,
  name,
  validation = {},
  wrapperClassName,
  inputClassName,
  white,
  tight,
  noLabel,
  required,
  helperMessage,
  ...props
}: TextAreaProps) {
  const { register } = useFormContext();
  const { errors } = useFormState();

  const classes = classNames("Input", {
    "Input-White": white,
    "Input-Tight": tight,
    [`${inputClassName}`]: inputClassName,
  });

  const wrapperClasses = classNames({
    [`${wrapperClassName}`]: wrapperClassName,
    "Input-No-Label": noLabel,
  });

  return (
    <div className={wrapperClasses}>
      <Label htmlFor={name}>
        {label}
        <Required required={required} />
      </Label>
      <textarea
        id={name}
        {...props}
        {...register(name, validation)}
        className={classes}
      />
      <HelperMessage helperMessage={helperMessage} />

      <FieldError errors={errors} name={name} />
    </div>
  );
}

type RadioInputProps = {
  label: string;
  name: string;
  checked?: boolean;
  value: any;
};

export function RadioInput({
  label,
  name,
  checked,
  value,
  ...props
}: RadioInputProps) {
  const inputId = useId();

  return (
    <div className="flex items-center Radio-Input-Container">
      <Input
        {...props}
        id={"radio-input-" + inputId}
        name={name}
        type="radio"
        defaultChecked={checked}
        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
        value={value}
      />
      <label
        htmlFor={"radio-input-" + inputId}
        className="ml-3 block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
    </div>
  );
}

// We have to mark the types as readonly on every level
// to stop the typescript compiler from complaining.
type RadioInputGroupOptionItem = {
  readonly label: string;
  readonly value: any;
};

type RadioInputGroupOptionItems = readonly RadioInputGroupOptionItem[];

type RadioInputGroupProps = {
  label: string;
  readonly options: RadioInputGroupOptionItems;
  name: string;
  required?: boolean;
  validation?: RegisterOptions;
  helperMessage?: string | React.ReactNode;
};

export function RadioInputGroup({
  label,
  options,
  required,
  validation,
  name,
  helperMessage,
  ...props
}: RadioInputGroupProps) {
  const value: string = useWatch({ name });

  const { register } = useFormContext();
  const { errors } = useFormState();

  return (
    <div>
      <Label>
        {label}
        <Required required={required} />
      </Label>

      <div className="flex items-center gap-6">
        {options.map((option) => (
          <div className="flex items-center h-[42px]" key={option.label}>
            <input
              id={name + "_" + option.value}
              // name={name}
              type="radio"
              defaultChecked={value === option.value}
              value={option.value}
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 Radio-Input"
              {...register(name, validation)}
            />

            <label
              htmlFor={name + "_" + option.value}
              className="ml-3 block text-sm font-medium text-gray-700 h-[42px] Radio-Input-Label"
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>

      <HelperMessage helperMessage={helperMessage} />

      <FieldError errors={errors} name={name} />
    </div>
  );
}

type FileInputProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  ref?: any;
  name?: string;
  multiple?: boolean;
  placeholder?: string;
  className?: string;
};

export const FileInput = React.forwardRef(
  (
    { id, onChange, name, multiple = false }: FileInputProps,
    ref: React.ForwardedRef<any>
  ) => {
    return (
      <input
        id={id}
        ref={ref}
        name={name}
        onChange={onChange}
        type="file"
        multiple={multiple}
        className="
        w-full
        border-[1px] border-form-stroke
        rounded
        border-slate-300
        text-slate-800
        placeholder-body-color
        outline-none
        focus:border-primary
        active:border-primary
        transition
        disabled:bg-[#F5F7FD] disabled:cursor-default
        cursor-pointer
        file:border-0
        file:border-solid
        file:border-r
        file:border-collapse
        file:border-form-stroke
        file:py-3
        file:px-5
        file:mr-5
      file:bg-slate-800
      file:text-white
      file:active:bg-slate-700
      file:hover:bg-slate-700
        file:cursor-pointer
      "
      />
    );
  }
);

type FormFileInputProps = {
  label?: string | React.ReactNode;
  name: string;
  validation?: RegisterOptions;
  wrapperClassName?: string;
  className?: string;
  value?: any;
  onChange?: (value: any) => void;
  noLabel?: boolean;
  tight?: boolean;
  placeholder?: string;
  ref?: any;
  multiple?: boolean;
  required?: boolean;
  helperMessage?: string | React.ReactNode;
};

export const FormFileInput = React.forwardRef(
  (
    {
      validation,
      wrapperClassName,
      className,
      name,
      label,
      noLabel,
      tight,
      placeholder,
      multiple,
      required,
      helperMessage,
      ...props
    }: FormFileInputProps,
    ref: React.ForwardedRef<any>
  ) => {
    const { control } = useFormContext();
    const { errors } = useFormState();

    const classes = classNames({
      "Select-Wrapper": true,
      [`${wrapperClassName}`]: wrapperClassName,
      "Select-No-Label": noLabel,
      "Select-Tight": tight,
    });

    return (
      <div className={classes}>
        <Label htmlFor={name}>
          {label}
          <Required required={required} />
        </Label>

        <Controller
          name={name}
          control={control}
          rules={validation}
          render={({ field }) => (
            <FileInput
              {...field}
              multiple={multiple}
              placeholder={placeholder}
              className={className}
              ref={ref}
            />
          )}
        />

        <HelperMessage helperMessage={helperMessage} />

        <FieldError errors={errors} name={name} />
      </div>
    );
  }
);

export function FormFileInput_Old({
  validation,
  wrapperClassName,
  className,
  name,
  label,
  noLabel,
  tight,
  placeholder,
  ...props
}: FormFileInputProps) {
  const { control } = useFormContext();
  const { errors } = useFormState();

  const classes = classNames({
    "Select-Wrapper": true,
    [`${wrapperClassName}`]: wrapperClassName,
    "Select-No-Label": noLabel,
    "Select-Tight": tight,
  });

  return (
    <div className={classes}>
      <Label htmlFor={name}>{label}</Label>

      <Controller
        name={name}
        control={control}
        rules={validation}
        // defaultValue={defaultValue}
        render={({ field }) => (
          <FileInput
            {...field}
            placeholder={placeholder}
            className={className}
          />
        )}
      />

      <FieldError errors={errors} name={name} />
    </div>
  );
}

function HelperMessage({
  helperMessage,
}: {
  helperMessage: string | React.ReactNode;
}) {
  if (!helperMessage) return null;

  return <div className="text-gray-600 text-xs">{helperMessage}</div>;
}
