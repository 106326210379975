import React from 'react';
import { IOrderDetailsBusiness } from './types';

export const OrderDetailsBusiness = ({
  business_contact_first_name,
  business_contact_last_name,
  business_name,
  business_registered_address,
  business_registered_city,
  business_registered_state,
  business_registered_zip,
}: IOrderDetailsBusiness) => (
  <div className="flex flex-col justify-start mb-10 w-2/3">
    <div className="mb-2 text-xl capitalize">
      <b>Business</b>
    </div>
    <div className="flex flex-col">
      <OrderDetailsBusinessField
        businessFieldName="Contact"
        businessFieldValue={`${business_contact_first_name} ${business_contact_last_name}`}
      />
      <OrderDetailsBusinessField
        businessFieldName="Name"
        businessFieldValue={business_name}
      />
      <OrderDetailsBusinessField
        businessFieldName="Address"
        businessFieldValue={business_registered_address}
      />
      <OrderDetailsBusinessField
        businessFieldName="City"
        businessFieldValue={business_registered_city}
      />
      <OrderDetailsBusinessField
        businessFieldName="State"
        businessFieldValue={business_registered_state}
      />
      <OrderDetailsBusinessField
        businessFieldName="Zip"
        businessFieldValue={business_registered_zip}
      />
    </div>
  </div>
);

function OrderDetailsBusinessField({
  businessFieldName,
  businessFieldValue,
}: {
  businessFieldName: string;
  businessFieldValue?: string;
}) {
  return (
    <div className="flex justify-start">
      <b>{businessFieldName}</b>:{' '}
      <span className="ml-2">{businessFieldValue || 'N/A'}</span>
    </div>
  );
}
