import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import {
  autocompleteUsersAndCompanies,
  getImpersonificationToken,
} from "~/api/user";
import { AuthenticatedState, useAuthStore } from "~/stores/authStore";
import { Select } from "./Select";
import { useCompanyNames } from "~/hooks/useCompanyNames";
import { buildAuthorizationHeader } from "~/api/httpClient";
import { useNavigate } from "react-router-dom";
import { Toast } from "./Toast";

export function ImpersonateAccount() {
  const navigate = useNavigate();
  const getOriginalToken = useAuthStore((state) => state.getOriginalToken);
  const currentUser = useAuthStore(
    (store) => (store.state as AuthenticatedState).user
  );
  const originalUser = useAuthStore(
    (store) => (store.state as AuthenticatedState).original?.user
  );
  const impersonateUser = useAuthStore((store) => store.impersonateUser);

  const queryClient = useQueryClient();

  const enableImpersonaficitation =
    currentUser.level === "super_admin" ||
    originalUser?.level === "super_admin";

  const [adminHeadersConfig] = useState(() => {
    const token = getOriginalToken();

    return {
      headers: buildAuthorizationHeader(token!),
    };
  });

  const { companySelectData, companyNamesError, isCompanyNamesLoading } =
    useCompanyNames(enableImpersonaficitation, adminHeadersConfig);

  const [selectedCompany, setCompany] = useState(() => {
    return {
      value: currentUser.company.id,
      label: currentUser.company.name,
    };
  });

  async function setImpersonateUser(
    record: { value: string; label: string } | null
  ) {
    const companyId = record?.value;
    if (!companyId) return;
    if (companyId === currentUser.company.id) return;

    setCompany(record);

    try {
      const adminToken = getOriginalToken();

      const config = {
        headers: buildAuthorizationHeader(adminToken!),
      };

      const token = await getImpersonificationToken(companyId, config);

      await impersonateUser(token);

      queryClient.invalidateQueries();
      navigate("/");
    } catch (error) {
      console.error("error", error);
      Toast({
        type: "Error",
        title: "Impersonation failed, check console for more info...",
        timeout: 7500,
      });
    }
  }

  // async function autocompleteDropdown(query: string) {
  //   // to workaround the issue where we need to fetch
  //   // admin routes, but we're impersonating a non-admin user
  //   const token = getOriginalToken();

  //   const config = {
  //     headers: buildAuthorizationHeader(token),
  //   };
  //   const results = await autocompleteUsersAndCompanies(query, config);

  //   return results.map((r) => ({
  //     name: r.company_name,
  //     id: r.company_id,
  //   }));
  // }

  if (!enableImpersonaficitation) {
    return null;
  }

  return (
    <div className="cursor-pointer">
      <div className="flex flex-col items-start">
        <Select
          value={selectedCompany}
          onChange={setImpersonateUser}
          options={companySelectData || []}
        />
      </div>
    </div>
  );
}

export function useUsersAndCompaniesAutocomplete(query = "", skip = false) {
  const { data, isLoading, error } = useQuery(
    ["users", "autocomplete"],
    async () => {
      return autocompleteUsersAndCompanies(query);
    },
    {
      enabled: !skip,
      keepPreviousData: true,
      retry: false,
    }
  );

  return {
    selectData: data?.map((c) => ({
      label: `${c.user_first_name} ${c.user_last_name} (${c.company_name})`,
      value: c.user_id,
    })),
    data: data,
    isLoading,
    error,
  };
}
