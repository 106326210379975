import { useEffect } from "react";
import { PurchaseTollfreeNumbers } from "./PurchaseTollfreeNumbers";
import { PurchaseLocalNumbers } from "./PurchaseLocalNumbers";
import { useNumberTypeStore } from "./numberTypesStore";

export function PurchaseNumbers() {
  const numberType = useNumberTypeStore((state) => state.numberType);
  const resetStore = useNumberTypeStore((state) => state.resetStore);

  useEffect(() => {
    resetStore();
  }, []);

  if (numberType.value === "local") {
    return <PurchaseLocalNumbers />;
  } else {
    return <PurchaseTollfreeNumbers />;
  }
}
