import React from "react";
import {
  TOrderDetailsCampaign,
  ShortcodeFormCampaignDetails,
  VerifiedSenderFormCampaignDetails,
} from "./types";

export function OrderDetailsCampaign(data: TOrderDetailsCampaign) {
  if (data.type === "verified_sender_form") {
    return <VerifiedSenderFormFields data={data} />;
  } else if (data.type === "shortcode_form") {
    return <ShortcodeFormFields data={data} />;
  } else {
    return <div>No order details to show.</div>;
  }
}

function VerifiedSenderFormFields({
  data,
}: {
  data: VerifiedSenderFormCampaignDetails;
}) {
  return (
    <div className="flex flex-col justify-start w-2/3">
      <div className="mb-2 capitalize text-xl">
        <b>Campaign</b>
      </div>
      <div className="flex flex-col justify-start w-96">
        <OrderDetailsCampaignField
          campaignFieldName="Use case:"
          campaignFieldValue={data.use_case}
        />
        <div className="flex flex-col justify-start">
          <b>
            Messages to be sent:{" "}
            {!data.sample_messages?.length && (
              <span className="font-normal">N/A</span>
            )}
          </b>
          <ul>
            {data.sample_messages?.map((message: string, i) => (
              <li key={message + i} className="ml-3">
                - {message}
              </li>
            ))}
          </ul>
        </div>
        <OrderDetailsCampaignField
          campaignFieldName="How do consumers opt in?"
          campaignFieldValue={data.how_do_consumers_opt_in}
        />
        <OrderDetailsCampaignField
          campaignFieldName="Are messages commercial?"
          campaignFieldValue={data.commercial_messages}
        />
        <OrderDetailsCampaignField
          campaignFieldName="Where are the numbers published?"
          campaignFieldValue={data.number_published_location}
        />
        <OrderDetailsCampaignField
          campaignFieldName="Are you a Fortune 500 company?"
          campaignFieldValue={data.fortune_500_or_1000_company}
        />
        <OrderDetailsCampaignField
          campaignFieldName="Estimated monthly traffic:"
          campaignFieldValue={data.estimated_monthly_message_volume}
        />
        <OrderDetailsCampaignField
          campaignFieldName="Canadian traffic:"
          campaignFieldValue={data.canadian_traffic_mostly}
        />
        <OrderDetailsCampaignField
          campaignFieldName="Covid-19 related content:"
          campaignFieldValue={data.covid_19_related}
        />
        <OrderDetailsCampaignField
          campaignFieldName="Previously on another messaging service?"
          campaignFieldValue={data.previously_another_messaging_service_traffic}
        />
        <OrderDetailsCampaignField
          campaignFieldName="Previously used messages:"
          campaignFieldValue={data.previously_another_messaging_service_content}
        />
        <div className="flex flex-col justify-start">
          <b>
            Previously used numbers:{" "}
            {!data.previously_another_messaging_service_numbers?.length && (
              <span className="font-normal">N/A</span>
            )}
          </b>{" "}
          {data.previously_another_messaging_service_numbers?.map(
            (number, i) => (
              <div key={number + i} className="ml-2">
                - {number}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

function ShortcodeFormFields({ data }: { data: ShortcodeFormCampaignDetails }) {
  return (
    <div className="flex flex-col justify-start w-2/3">
      <div className="mb-2 capitalize text-xl">
        <b>Campaign</b>
      </div>
      <div className="flex flex-col justify-start w-96">
        <OrderDetailsCampaignField
          campaignFieldName="Use case:"
          campaignFieldValue={data.use_case}
        />
        <OrderDetailsCampaignField
          campaignFieldName="Terms and conditions website"
          campaignFieldValue={data.terms_and_conditions_website}
        />
        <OrderDetailsCampaignField
          campaignFieldName="Privacy policy website"
          campaignFieldValue={data.privacy_policy_website}
        />
        <OrderDetailsCampaignField
          campaignFieldName="Call to action locations"
          campaignFieldValue={data.call_to_action_locations}
        />
        <OrderDetailsCampaignField
          campaignFieldName="Call to action language"
          campaignFieldValue={data.call_to_action_locations}
        />
        <OrderDetailsCampaignField
          campaignFieldName="Opt-in keywords"
          campaignFieldValue={data.opt_in_keywords}
        />
        <OrderDetailsCampaignField
          campaignFieldName="Opt-in confirmation"
          campaignFieldValue={data.opt_in_confirmation}
        />
        <OrderDetailsCampaignField
          campaignFieldName="Welcome message"
          campaignFieldValue={data.welcome_message}
        />
        <OrderDetailsCampaignField
          campaignFieldName="Example alerts"
          campaignFieldValue={data.example_alerts}
        />
        <OrderDetailsCampaignField
          campaignFieldName="Help response text"
          campaignFieldValue={data.help_response_text}
        />
        <OrderDetailsCampaignField
          campaignFieldName="Stop response text"
          campaignFieldValue={data.stop_response_text}
        />
        <OrderDetailsCampaignField
          campaignFieldName="Customer support contact email"
          campaignFieldValue={data.customer_support_contact_email}
        />
        <OrderDetailsCampaignField
          campaignFieldName="Customer support contact phone"
          campaignFieldValue={data.customer_support_contact_phone}
        />
        <OrderDetailsCampaignField
          campaignFieldName="Customer support response URL"
          campaignFieldValue={data.customer_support_url}
        />
      </div>
    </div>
  );
}

function OrderDetailsCampaignField({
  campaignFieldName,
  campaignFieldValue,
}: {
  campaignFieldName: string;
  campaignFieldValue?: string | null;
}) {
  return (
    <div className="flex justify-start flex-wrap">
      <b>{campaignFieldName}</b>&nbsp;
      <span className="capitalize">{campaignFieldValue || "N/A"}</span>
    </div>
  );
}
