/**
 * Helper for creating and reseting all stores.
 *
 * Each store must have a resetState function.
 */
import { create as _create, StateCreator } from "zustand";

const storeResetFns = new Set<() => void>();

// type ResetStoreFn = {
//   resetStore: () => void;
// }

export const createStore = (<T extends unknown>(
  f: StateCreator<T> | undefined
) => {
  if (f === undefined) return createStore;
  const store = _create(f);

  // @ts-ignore
  const resetStoreFn = store.getState().resetStore;

  storeResetFns.add(() => {
    resetStoreFn();
  });

  return store;
}) as typeof _create;

export const resetAllStores = () => {
  storeResetFns.forEach((resetFn) => {
    resetFn();
  });
};
