import React, { useState } from "react";
import { useQuery } from "react-query";
import { Loader, LoaderOverlay } from "../../components/Loader";
import { Layout } from "../../components/Layout";
import { listTerminationIPs } from "~/api/terminationIPs";
import { CreateTerminationIP, DeleteTerminationIP } from ".";
import { Pagination } from "~/components/Pagination";

export function TerminationIPs() {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, error, isFetching, isLoading } =
    useTerminationIPList(currentPage);

  if (isLoading) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  if (error) {
    return <Layout>Error occured: {error.message}</Layout>;
  }

  const tableCellTextStyles =
    "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider";

  return (
    <Layout className="Phone-Numbers">
      <h2 className="text-2xl lg:text-3xl font-bold mt-2 mb-6">
        Termination IPs
      </h2>
      <CreateTerminationIP />
      <div>
        <div className="flex flex-col">
          <div className="-my-2 sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 relative">
              <LoaderOverlay show={isFetching} />
              <div className="shadow overflow-x-auto overflow-y-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr className="w-1/2">
                      <th scope="col" className={tableCellTextStyles}>
                        Termination IP
                      </th>
                      <th className={tableCellTextStyles}>Action</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {data.entries.map((ip: any) => (
                      <tr key={ip.id}>
                        <td className={tableCellTextStyles}>{ip.ip}</td>
                        <td>
                          <DeleteTerminationIP id={ip.id} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  totalPages={data.totalPages}
                  total={data.totalEntries}
                  currentPage={data.pageNumber}
                  perPage={data.pageSize}
                  onPageChange={(newPage: number) => setCurrentPage(newPage)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const TWO_MINUTES_IN_MS = 1000 * 60 * 2;

function useTerminationIPList(page: number): any {
  const { isLoading, error, data, isFetching } = useQuery(
    ["terminationIPs", page],
    async ({}) => {
      return await listTerminationIPs(page);
    },
    {
      keepPreviousData: true,
      staleTime: TWO_MINUTES_IN_MS,
    }
  );

  return {
    isLoading,
    isFetching,
    error: error as Error,
    data,
  };
}
