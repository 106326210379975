type DecodedToken = {
  header: {
    alg: string;
    type: string;
  };
  payload: {
    aud: string;
    exp: string;
    iat: string;
    iss: string;
    jti: string;
    nbf: string;
    sub: string;
    typ: string;
    user_id: string;
    company_id?: string;
  };
};

/**
 * Decodes a JWT token.
 */
export function decodeJwt(token: string): DecodedToken {
  const parts = token.split(".");

  if (parts.length !== 3) {
    throw new Error("Invalid JWT token");
  }

  const [headerEncoded, payloadEncoded, _signature] = parts;

  // Decode the header and payload.
  const headerJson = base64UrlDecode(headerEncoded);
  const payloadJson = base64UrlDecode(payloadEncoded);

  // Parse the header and payload as JSON objects.
  const header = JSON.parse(headerJson);
  const payload = JSON.parse(payloadJson);

  return { header, payload };
}

export function isJwtStillValid(token: string): boolean {
  let decoded;

  // If the JWT cannot be decoded, just report expired/invalid,
  // caller can decide whether to remove it or not.
  try {
    decoded = decodeJwt(token);
  } catch (_error) {
    return false;
  }

  // The JWT token expiry is saved in seconds from beggining of the "Unix epoch" (1970-01-01 00:00:00 UTC)
  // but the JS Date object timestamp returns time in milliseconds, that's why we have to multiply with 1000.
  const expiryTimestampInMs = Number(decoded.payload.exp) * 1000;
  const currentTimestmapInMs = new Date().getTime();

  return currentTimestmapInMs < expiryTimestampInMs;
}

function base64UrlDecode(str: string): string {
  // Replace URL-safe characters with their base64 equivalents.
  const base64 = str.replace("-", "+").replace("_", "/");

  // Add padding to the base64 string if necessary.
  const padding = base64.length % 4 ? 4 - (base64.length % 4) : 0;
  const paddedBase64 = base64 + "=".repeat(padding);

  // Decode the base64 string.
  const decoded = atob(paddedBase64);
  return decoded;
}
