type CdrListPaginationProps = {
  onNextClick: () => void;
  onPreviousClick: () => void;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  isLoading: boolean;
  isFetching: boolean;
  // totalPages: number,
  // currentPage: number,
};

export default function CdrListPagination({
  onNextClick,
  onPreviousClick,
  hasNextPage,
  hasPreviousPage,
  // totalPages,
  // currentPage,
  isLoading,
  isFetching,
}: CdrListPaginationProps) {
  const isLoadingInProgress = isLoading || isFetching;

  // className="w-full bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500 sm:ml-6 sm:order-last sm:w-auto"

  return (
    <nav
      className="py-3 flex items-center justify-between"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        {/* <p className="text-sm text-gray-700">
          Showing page{' '}<span className="font-medium">{currentPage}</span> of{' '}
          <span className="font-medium">{totalPages}</span>
        </p> */}
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        <button
          disabled={!hasPreviousPage || isLoadingInProgress}
          onClick={onPreviousClick}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50  disabled:bg-gray-100 disabled:text-gray-500 disabled:cursor-not-allowed"
        >
          Previous
        </button>
        <button
          disabled={!hasNextPage || isLoadingInProgress}
          onClick={onNextClick}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50  disabled:bg-gray-100 disabled:text-gray-500 disabled:cursor-not-allowed"
        >
          Next
        </button>
      </div>
    </nav>
  );
}
