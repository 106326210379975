import React, { ReactNode, useState } from "react";
import BugsnagLib from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

// if Bugsnag API key isn't provided, don't wrap the app with it
export function NoopBoundary({ children }: { children: ReactNode }) {
  return <>{children}</>;
}

type BugsnagProps = {
  apiKey: string | null;
  children: React.ReactNode;
};

export function Bugsnag({ apiKey, children }: BugsnagProps) {
  const [Boundary] = useState(() => {
    if (!apiKey) return NoopBoundary;

    BugsnagLib.start({
      apiKey: apiKey,
      plugins: [new BugsnagPluginReact()],
      maxBreadcrumbs: 40,
    });

    const ErrorBoundary =
      BugsnagLib.getPlugin("react")!.createErrorBoundary(React);
    return ErrorBoundary;
  });

  return <Boundary FallbackComponent={ErrorView}>{children}</Boundary>;
}

const ErrorView = () => (
  <div>
    <p>The app crashed, the error has been reported, please refresh the app.</p>
  </div>
);
