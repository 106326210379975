import React, { useState } from "react";
import { create } from "zustand";
import { DropdownItem } from "~/utils/dropdownItemTypes";

export const numberTypes = [
  {
    label: "Local",
    value: "local",
  },
  {
    label: "Toll-free",
    value: "tollfree",
  },
];

interface NumberTypeState {
  numberType: DropdownItem;
  changeNumberType: (newNumberType: DropdownItem) => void;
  resetStore: () => void;
  numberTypes: DropdownItem[];
}

export const useNumberTypeStore = create<NumberTypeState>((set) => ({
  numberType: numberTypes[0],
  changeNumberType: (newNumberType: DropdownItem) =>
    set((state) => ({ numberType: newNumberType })),
  numberTypes: numberTypes,
  resetStore: () => set({ numberType: numberTypes[0] }),
}));
