import differenceInCalendarMonths from "date-fns/differenceInCalendarMonths";
import startOfMonth from "date-fns/startOfMonth";
import startOfYesterday from "date-fns/startOfYesterday";
import sub from "date-fns/sub";
import { startCase } from "lodash-es";
import { useState } from "react";
import { useQuery } from "react-query";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";
import { getAnalyticsRecords } from "../../api/analytics";
import { DateRangePicker } from "../../components/DateRangePicker";
import { ExportLink } from "../../components/ExportLink";
import { InfoTooltip } from "../../components/InfoTooltip";
import { ItemSelector } from "../../components/ItemSelector";
import { Layout } from "../../components/Layout";
import { TypeSelector } from "../../components/TypeSelector";
import { PeriodType, RecordType } from "../../types";
import { generateCsv, downloadCsv } from "../../utils/download";
import {
  formatDateToISO,
  formatIntegerNumber,
  formatMoney,
} from "../../utils/format";
import { Stats } from "./Stats";

// we don't really have data prior to mid/late 2021, so this should be ok for now
const minDate = new Date("2021-01-01");

const periodTypeItems = [
  {
    value: "daily",
    label: "Daily",
  },
  {
    value: "monthly",
    label: "Monthly",
  },
];

export function Analytics() {
  const maxDate = startOfYesterday();
  const [dates, setDates] = useState(() => [
    startOfMonth(new Date()),
    startOfYesterday(),
  ]);
  const [periodType, setPeriodType] = useState<PeriodType>("daily");
  const [recordType, setRecordType] = useState<RecordType>("SMS");
  const [activeType, setActiveType] = useState<string | null>(null);

  const { isLoading, isFetching, error, data } = useAnalyticsData({
    periodType,
    recordType,
    startDate: formatDateToISO(dates[0]),
    endDate: formatDateToISO(dates[1]),
  });

  const types = data?.types || [];
  const lineComponents = types.map((type) => (
    <Line
      key={type}
      type="monotone"
      dataKey={type}
      // @ts-ignore
      stroke={typeToColor[type]}
      animationDuration={750}
    />
  ));

  function changePeriodType(periodType: PeriodType) {
    setPeriodType(periodType);

    // we want to widen the period if we switch to months
    const difference = differenceInCalendarMonths(dates[1], dates[0]);
    if (periodType === "monthly" && difference <= 1) {
      const newDate = sub(dates[0], { months: 3 });
      setDates([newDate, dates[1]]);
    }
  }

  function exportData() {
    const dataTypes = types
      .map(formatLabel)
      .flatMap((label) => [`${label} (count)`, `${label} (bill)`]);
    const columns = ["Date", ...dataTypes];
    let csvData = [columns];

    const dataLength = data?.usageData.length || 0;
    for (let i = 0; i < dataLength; i++) {
      csvData[i + 1] = [data?.usageData[i].name];
      for (let j = 0; j < types.length; j++) {
        const type = types[j];
        csvData[i + 1].push(
          // @ts-ignore
          data?.usageData[i][type]
        );
        csvData[i + 1].push(
          // @ts-ignore
          data?.billData[i][type]
        );
      }
    }

    downloadCsv(
      `analytics_${recordType}_${periodType}_${formatDateToISO(
        dates[0]
      )}_${formatDateToISO(dates[1])}.csv`,
      generateCsv(csvData)
    );
  }

  return (
    <Layout>
      <h2 className="text-2xl lg:text-3xl font-bold mt-2 mb-0 flex items-center">
        Analytics
      </h2>
      <div className="mb-6">
        These reports are calculated nightly, the latest available results are
        those from yesterday.
      </div>

      <div className="Analytics-Container">
        <div className="flex flex-wrap items-center">
          <div className="mr-6">
            <TypeSelector activeType={recordType} setType={setRecordType} />
          </div>
          <div className="mr-6">
            <ItemSelector
              selectedItem={periodType}
              selectItem={changePeriodType}
              items={periodTypeItems}
              title="Breakdown"
            />
          </div>
          <div className="flex flex-wrap items-center mr-6">
            <span className="w-14 font-semibold">Dates</span>

            <div className="flex flex-col items-start">
              <DateRangePicker
                className="rounded-md bg-white"
                // @ts-ignore
                onChange={setDates}
                value={dates}
                minDate={minDate}
                maxDate={maxDate}
                monthly={periodType === "monthly"}
              />
            </div>
          </div>

          <div>
            <ExportLink onExportClick={exportData} text="Export" />
          </div>
        </div>

        <div className="mt-4">
          <Stats type={recordType} data={data} />
        </div>

        <h2 className="text-xl font-bold mt-4 mb-2">Usage</h2>

        <div style={{ width: "100%", height: 400 }}>
          <ResponsiveContainer>
            <LineChart
              data={data?.usageData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis tickFormatter={formatIntegerNumber} />
              <Tooltip formatter={integerFormatter} />
              <Legend formatter={formatLegendLabels} />
              {lineComponents}
            </LineChart>
          </ResponsiveContainer>
        </div>

        <h2 className="text-xl font-bold mb-2">Bill</h2>
        <div style={{ width: "100%", height: 400 }}>
          <ResponsiveContainer>
            <LineChart
              data={data?.billData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis tickFormatter={formatMoney} />
              <Tooltip formatter={moneyTooltipFormatter} />
              <Legend formatter={formatLegendLabels} />
              {lineComponents}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </Layout>
  );
}

const typeToColor = {
  all: "#029be5",
  INBOUND: "#6e3eb7",
  OUTBOUND: "#3351b0",
  MO: "#6e3eb7",
  MT: "#3351b0",
};

type useAnalyticsDataProps = {
  periodType: PeriodType;
  recordType: RecordType;
  startDate?: string;
  endDate?: string;
};
const TWO_MINUTES_IN_MS = 1000 * 60 * 2;

function useAnalyticsData({
  periodType,
  recordType,
  startDate,
  endDate,
}: useAnalyticsDataProps) {
  const { isLoading, error, data, isFetching, isPreviousData } = useQuery(
    ["analytics", periodType, recordType, startDate, endDate],
    async ({}) => {
      const analyticsData = await getAnalyticsRecords({
        recordType,
        periodType,
        startDate,
        endDate,
      });

      // the labels will be date strings
      const labels = analyticsData.labels;

      // How the data should look like
      // {
      //   usageData: [{
      //     name: "2022-01-01",
      //     INBOUND: 12,
      //     OUTBOUND: 8,
      //     all: 20
      //   }, {
      //     name: "2022-01-02",
      //     INBOUND: 11,
      //     OUTBOUND: 12,
      //     all: 23
      //   }, ...],
      //   billData: [{
      //     name: "2022-01-01",
      //     INBOUND: 12,
      //     OUTBOUND: 8,
      //     all: 20
      //   }, {
      //     name: "2022-01-02",
      //     INBOUND: 11,
      //     OUTBOUND: 12,
      //     all: 23
      //   }, ...]
      // }

      let usageData = [];
      let billData = [];
      let durationData = [];
      const types = Object.keys(analyticsData.data);

      for (let i = 0; i < labels.length; i++) {
        usageData[i] = {
          name: labels[i],
          // the dates field contains all the dates, for daily overview that works,
          // but for monthly it lists all days, but we need the months, that's why we'll rely on labels.
          date: periodType === "daily" ? analyticsData.dates[i] : labels[i],
        };

        billData[i] = {
          name: labels[i],
          date: periodType === "daily" ? analyticsData.dates[i] : labels[i],
        };

        durationData[i] = {
          name: labels[i],
          date: periodType === "daily" ? analyticsData.dates[i] : labels[i],
        };

        for (let typeIdx = 0; typeIdx < types.length; typeIdx++) {
          const type = types[typeIdx];
          // @ts-ignore
          usageData[i][type] = parseFloat(
            analyticsData.data[type].rows.count[i]
          );
          // @ts-ignore
          billData[i][type] = parseFloat(analyticsData.data[type].rows.bill[i]);
          // @ts-ignore
          durationData[i][type] = parseFloat(
            analyticsData.data[type].rows.duration[i]
          );
        }
      }

      return {
        usageData,
        billData,
        durationData,
        types,
      };
    },
    {
      keepPreviousData: true,
      staleTime: TWO_MINUTES_IN_MS,
    }
  );

  return {
    isLoading,
    isFetching,
    error,
    data,
  };
}

function integerFormatter(value: string, name: string): [string, string] {
  return [formatIntegerNumber(value), formatLabel(name)];
}

function formatDate(val: string) {
  return val;
}

function moneyTooltipFormatter(value: string, name: string): [string, string] {
  return [formatMoney(value), formatLabel(name)];
}

function formatLegendLabels(value: any, _entry: any, _index: number): string {
  return formatLabel(value as string);
}

function formatLabel(str: string): string {
  // @ts-ignore
  const label = labelMap[str];
  if (label) return label;
  return startCase(str.toLocaleLowerCase());
}

const labelMap = {
  INBOUND: "Inbound",
  OUTBOUND: "Outbound",
  MO: "MO",
  MT: "MT",
  all: "All",
};
