import { Link } from "react-router-dom";

export function ForgotPasswordAction() {
  return (
    <div className="flex justify-center items-center bg-gray-100 p-4">
      <p className="text-gray-500 text-sm text-center">
        <Link
          to="/forgot-password"
          className="text-indigo-500 hover:text-indigo-600 active:text-indigo-700 transition duration-100"
        >
          Forgot your password?
        </Link>
      </p>
    </div>
  );
}
