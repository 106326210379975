export const errorCodes = [
  {
    hexCode: "0x00000000",
    decimalCode: "0",
    tsgCode: "0",
    description: "No Error",
    possibleFix: "N/A",
  },
  {
    hexCode: "0x000003EC",
    decimalCode: "1004",
    tsgCode: "3ec",
    description: "Message delivered to handset (toll-free specific)",
    possibleFix: "N/A",
  },
  {
    hexCode: "0x000003ED",
    decimalCode: "1005",
    tsgCode: "3ed",
    description: "Message Rejected (toll-free specific)",
    possibleFix: "",
  },
  {
    hexCode: "0x000003EF",
    decimalCode: "1007",
    tsgCode: "3ef",
    description: "Message Failed (toll-free specific)",
    possibleFix: "",
  },
  {
    hexCode: "0x0000044F",
    decimalCode: "1103",
    tsgCode: "44f",
    description: "Source Not Authorized (toll-free specific)",
    possibleFix: "",
  },
  {
    hexCode: "0x0000045C",
    decimalCode: "1116",
    tsgCode: "45c",
    description: "Destination Address is not text enabled (toll-free specific)",
    possibleFix: "",
  },
  {
    hexCode: "0x000004B6",
    decimalCode: "1206",
    tsgCode: "4b6",
    description: "Invalid destination address (toll-free specific)",
    possibleFix: "",
  },
  {
    hexCode: "0x000004CE",
    decimalCode: "1230",
    tsgCode: "4ce",
    description: "ESME Receiver reject message error (toll-free specific)",
    possibleFix: "",
  },
  {
    hexCode: "0x00000456",
    decimalCode: "1110",
    tsgCode: "456",
    description: "User Opted Out (STOP)",
    possibleFix: "",
  },
  {
    hexCode: "0x00000488",
    decimalCode: "1160",
    tsgCode: "488",
    description: "Toll-free Not Verified",
    possibleFix:
      "TFN must submit for Verified Sender status. Complete the Verified Sender form located in the Customer Portal.",
  },
  {
    hexCode: "0x00000480",
    decimalCode: "1152",
    tsgCode: "480",
    description: "SPAM Reject",
    possibleFix: "",
  },
  {
    hexCode: "0x00000001",
    decimalCode: "1",
    tsgCode: "001",
    description: "Routing Failure",
    possibleFix:
      "Max 140 octets; 160 chars in uncompressed default character encoding.",
  },
  {
    hexCode: "0x00000002",
    decimalCode: "2",
    tsgCode: "002",
    description: "Undelivered - invalid destination",
    possibleFix: "",
  },
  {
    hexCode: "0x00000003",
    decimalCode: "3",
    tsgCode: "003",
    description: "Invalid Command ID",
    possibleFix: "",
  },
  {
    hexCode: "0x00000004",
    decimalCode: "4",
    tsgCode: "004",
    description: "Blocked by Carrier",
    possibleFix: "",
  },
  {
    hexCode: "0x00000005",
    decimalCode: "5",
    tsgCode: "005",
    description: "Blocked - SPAM",
    possibleFix: "",
  },
  {
    hexCode: "0x00000006",
    decimalCode: "6",
    tsgCode: "006",
    description: "Invalid Priority Flag",
    possibleFix: "",
  },
  {
    hexCode: "0x00000007",
    decimalCode: "7",
    tsgCode: "007",
    description: "Invalid Registered Delivery Flag",
    possibleFix: "",
  },
  {
    hexCode: "0x00000008",
    decimalCode: "8",
    tsgCode: "008",
    description: "System Error",
    possibleFix: "",
  },
  {
    hexCode: "0x00000009",
    decimalCode: "9",
    tsgCode: "009",
    description: "Reserved",
    possibleFix: "",
  },
  {
    hexCode: "0x0000000A",
    decimalCode: "10",
    tsgCode: "00a",
    description: "Invalid Source Address",
    possibleFix: "",
  },
  {
    hexCode: "0x0000000B",
    decimalCode: "11",
    tsgCode: "00b",
    description: "Invalid Dest Addr",
    possibleFix:
      "Invalid length; the length was greater than 3 && less than 17, invalid international format.",
  },
  {
    hexCode: "0x0000000C",
    decimalCode: "12",
    tsgCode: "00c",
    description: "Message ID is invalid",
    possibleFix: "",
  },
  {
    hexCode: "0x0000000D",
    decimalCode: "13",
    tsgCode: "00d",
    description: "Bind Failed",
    possibleFix: "",
  },
  {
    hexCode: "0x0000000E",
    decimalCode: "14",
    tsgCode: "00e",
    description: "Invalid Password",
    possibleFix: "",
  },
  {
    hexCode: "0x0000000F",
    decimalCode: "15",
    tsgCode: "00f",
    description: "Invalid System ID",
    possibleFix: "",
  },
  {
    hexCode: "0x00000010",
    decimalCode: "16",
    tsgCode: "010",
    description: "Reserved",
    possibleFix: "",
  },
  {
    hexCode: "0x00000011",
    decimalCode: "17",
    tsgCode: "011",
    description: "Cancel SM Failed",
    possibleFix: "",
  },
  {
    hexCode: "0x00000012",
    decimalCode: "18",
    tsgCode: "012",
    description: "Reserved",
    possibleFix: "",
  },
  {
    hexCode: "0x00000013",
    decimalCode: "19",
    tsgCode: "013",
    description: "Replace SM Failed",
    possibleFix: "",
  },
  {
    hexCode: "0x00000014",
    decimalCode: "20",
    tsgCode: "014",
    description: "Message Queue Full",
    possibleFix: "",
  },
  {
    hexCode: "0x00000015",
    decimalCode: "21",
    tsgCode: "015",
    description: "Invalid Service Type",
    possibleFix: "Set to NULL",
  },
  {
    hexCode: "0x00000016",
    decimalCode: "22",
    tsgCode: "016",
    description: "Reserved thru -0x00000032",
    possibleFix: "",
  },
  {
    hexCode: "0x00000033",
    decimalCode: "51",
    tsgCode: "033",
    description: "Invalid number of destinations",
    possibleFix: "",
  },
  {
    hexCode: "0x00000034",
    decimalCode: "52",
    tsgCode: "034",
    description: "Invalid Distribution List name",
    possibleFix: "",
  },
  {
    hexCode: "0x00000035",
    decimalCode: "53",
    tsgCode: "035",
    description: "Reserved thru -0x0000003F",
    possibleFix: "",
  },
  {
    hexCode: "0x00000040",
    decimalCode: "64",
    tsgCode: "040",
    description: "Destination flag is invalid (submit_multi)",
    possibleFix: "",
  },
  {
    hexCode: "0x00000041",
    decimalCode: "65",
    tsgCode: "041",
    description: "Reserved",
    possibleFix: "",
  },
  {
    hexCode: "0x00000042",
    decimalCode: "66",
    tsgCode: "042",
    description:
      "Invalid submit with replace request; submit_sm with replace_if_present_flag set",
    possibleFix: "",
  },
  {
    hexCode: "0x00000043",
    decimalCode: "67",
    tsgCode: "043",
    description: "Invalid esm_class field data",
    possibleFix: "",
  },
  {
    hexCode: "0x00000044",
    decimalCode: "68",
    tsgCode: "044",
    description: "Cannot Submit to Distribution List",
    possibleFix: "",
  },
  {
    hexCode: "0x00000045",
    decimalCode: "69",
    tsgCode: "045",
    description: "MDN Blocked",
    possibleFix:
      "Blocked Sender or Receiver on the Platform. Likely reported as SPAM.",
  },
  {
    hexCode: "0x00000046",
    decimalCode: "70",
    tsgCode: "046",
    description: "Reserved thru -0x00000047",
    possibleFix: "",
  },
  {
    hexCode: "0x00000048",
    decimalCode: "72",
    tsgCode: "048",
    description: "Invalid Source address TON",
    possibleFix:
      "Accepts International, Network, or Alphanumeric; values of 0x01, 0x03, and 0x05.",
  },
  {
    hexCode: "0x00000049",
    decimalCode: "73",
    tsgCode: "049",
    description: "Invalid Source address NPI",
    possibleFix:
      "Set to null for default value or 0x01 for ISDN numbering plan indicator.",
  },
  {
    hexCode: "0x00000050",
    decimalCode: "80",
    tsgCode: "050",
    description: "Invalid Destination address TON",
    possibleFix:
      "Accepts either Unknown Or International; values: 0x00 and 0x01.",
  },
  {
    hexCode: "0x00000051",
    decimalCode: "81",
    tsgCode: "051",
    description: "Invalid Destination address NPI",
    possibleFix:
      "Accepts either Unknown Or ISDN E163/E164: values: 0x00 and 0x01.",
  },
  {
    hexCode: "0x00000052",
    decimalCode: "",
    tsgCode: "052",
    description: "Reserved",
    possibleFix: "",
  },
  {
    hexCode: "0x00000053",
    decimalCode: "83",
    tsgCode: "053",
    description: "Invalid system_type field",
    possibleFix: "",
  },
  {
    hexCode: "0x00000054",
    decimalCode: "84",
    tsgCode: "054",
    description: "Invalid replace_if_present flag.",
    possibleFix: "",
  },
  {
    hexCode: "0x00000055",
    decimalCode: "85",
    tsgCode: "055",
    description: "Invalid number of messages",
    possibleFix: "",
  },
  {
    hexCode: "0x00000056",
    decimalCode: "",
    tsgCode: "056",
    description: "Reserved thru -0x00000057",
    possibleFix: "",
  },
  {
    hexCode: "0x00000058",
    decimalCode: "88",
    tsgCode: "058",
    description: "Throttling error; ESME has exceeded allowed message limits.",
    possibleFix: "",
  },
  {
    hexCode: "0x00000059",
    decimalCode: "89",
    tsgCode: "059",
    description: "Reserved thru -0x00000060",
    possibleFix: "",
  },
  {
    hexCode: "0x00000061",
    decimalCode: "97",
    tsgCode: "061",
    description: "Invalid Scheduled Delivery Time",
    possibleFix: "",
  },
  {
    hexCode: "0x00000062",
    decimalCode: "98",
    tsgCode: "062",
    description: "Invalid message validity period (Expiry time).",
    possibleFix: "",
  },
  {
    hexCode: "0x00000063",
    decimalCode: "99",
    tsgCode: "063",
    description: "Predefined Message Invalid or Not Found",
    possibleFix: "Does not support canned messages; set to NULL.",
  },
  {
    hexCode: "0x00000064",
    decimalCode: "100",
    tsgCode: "064",
    description: "ESME Receiver Temporary App Error Code",
    possibleFix: "",
  },
  {
    hexCode: "0x00000065",
    decimalCode: "101",
    tsgCode: "065",
    description: "ESME Receiver Permanent App Error Code",
    possibleFix: "",
  },
  {
    hexCode: "0x00000066",
    decimalCode: "102",
    tsgCode: "066",
    description: "ESME Receiver Reject Message Error Code",
    possibleFix: "",
  },
  {
    hexCode: "0x00000067",
    decimalCode: "103",
    tsgCode: "067",
    description: "query_sm request failed",
    possibleFix: "",
  },
  {
    hexCode: "0x00000068",
    decimalCode: "104",
    tsgCode: "068",
    description: "AT&T: spam message detected/rejected",
    possibleFix: "",
  },
  {
    hexCode: "0x00000069",
    decimalCode: "105",
    tsgCode: "069",
    description: "AT&T: sending limit reached",
    possibleFix:
      "Send messages at a slower rate to AT&T subscribers. You can use the TSG LRN/LERG API to determine the destination carrier.",
  },
  {
    hexCode: "0x0000006A",
    decimalCode: "106",
    tsgCode: "06a",
    description: "AT&T: message has invalid tagging data",
    possibleFix: "",
  },
  {
    hexCode: "0x0000006B",
    decimalCode: "107",
    tsgCode: "06b",
    description: "AT&T: non-compliant URL or other non-compliance issue",
    possibleFix: "",
  },
  {
    hexCode: "0x000000C0",
    decimalCode: "192",
    tsgCode: "0c0",
    description: "Error in the optional part of the PDU Body",
    possibleFix: "",
  },
  {
    hexCode: "0x000000C1",
    decimalCode: "193",
    tsgCode: "0c1",
    description: "Optional Parameter not allowed",
    possibleFix: "",
  },
  {
    hexCode: "0x000000C2",
    decimalCode: "194",
    tsgCode: "0c2",
    description: "Invalid Parameter Length",
    possibleFix: "",
  },
  {
    hexCode: "0x000000C3",
    decimalCode: "195",
    tsgCode: "0c3",
    description: "Expected Optional Parameter missing.",
    possibleFix: "",
  },
  {
    hexCode: "0x000000C4",
    decimalCode: "196",
    tsgCode: "0c4",
    description: "Invalid Optional Parameter Value",
    possibleFix:
      "This error occurs when an optional value parameter retrieve fails; this not normal behavior.",
  },
  {
    hexCode: "0x000000FE",
    decimalCode: "245",
    tsgCode: "0fe",
    description: "Delivery Failure, used for data_sm_resp",
    possibleFix: "",
  },
  {
    hexCode: "0x000000FF",
    decimalCode: "255",
    tsgCode: "0ff",
    description: "Unknown Error",
    possibleFix: "",
  },
  {
    hexCode: "0x00000100",
    decimalCode: "",
    tsgCode: "100",
    description: "Reserved for SMPP extension thru -0x000003FF",
    possibleFix: "",
  },
  {
    hexCode: "0x0000265D",
    decimalCode: "9821",
    tsgCode: "265d",
    description: "T-Mobile: Tagging Error",
    possibleFix: "",
  },
  {
    hexCode: "0x00002655",
    decimalCode: "9813",
    tsgCode: "2655",
    description: "T-Mobile: Unexpected Network or System error",
    possibleFix: "",
  },
  {
    hexCode: "0x0000265B",
    decimalCode: "9819",
    tsgCode: "265b",
    description: "T-Mobile: Other internal error",
    possibleFix: "",
  },
  {
    hexCode: "0x00002649",
    decimalCode: "9801",
    tsgCode: "2649",
    description: "T-Mobile: Content provider service quota has been exhausted",
    possibleFix:
      "Send messages at a slower rate to all T-Mobile/Sprint/Metro PCS/etc. subscribers. You can use the TSG LRN/LERG API to determine the destination carrier. Please see the 10DLC throughput article for more information.",
  },
  {
    hexCode: "0x0000264A",
    decimalCode: "9802",
    tsgCode: "264a",
    description: "T-Mobile: ECE quota API return error code",
    possibleFix: "",
  },
  {
    hexCode: "0x00002667",
    decimalCode: "9831",
    tsgCode: "2667",
    description:
      "T-Mobile: Short code verification errorcomparation fail with ENUM server response",
    possibleFix: "",
  },
  {
    hexCode: "0x00002668",
    decimalCode: "9832",
    tsgCode: "2668",
    description: "T-Mobile: ENUM server connection error",
    possibleFix: "",
  },
  {
    hexCode: "0x00002669",
    decimalCode: "9833",
    tsgCode: "2669",
    description:
      "T-Mobile: Init ENUM server error, e.g. ENUM server IP address configuration error.",
    possibleFix: "",
  },
  {
    hexCode: "0x00002654",
    decimalCode: "9812",
    tsgCode: "2654",
    description:
      "10DLC: number not provisioned correctly to the 10DLC ecosystem",
    possibleFix: "Contact: support@tsgglobal.com",
  },
] as const;

export const dlrTsgErrorMap = errorCodes.reduce(
  (acc: Record<string, string>, item) => {
    acc[item.tsgCode] = item.description;
    return acc;
  },
  {}
);
