import "./App.css";

import { Routes, Route, Navigate } from "react-router-dom";
import { LogIn } from "./pages/Login";
import { PortalForgotPassword } from "./pages/PortalForgotPassword";
import { Support } from "./pages/Support/Support";
import { ResetPassword } from "./pages/PortalResetPassword";
import { enableSupportPage } from "./config";
import { AuthCallback } from "./pages/AuthCallback";
import { OnboardNewUser } from "./pages/OnboardNewUser";

function UnauthenticatedApp() {
  return (
    <>
      <main className="main">
        <Routes>
          <Route path="/" element={<LogIn />} />
          <Route path="/forgot-password" element={<PortalForgotPassword />} />
          <Route path="/password/:resetToken" element={<ResetPassword />} />
          <Route
            path="/confirm/:confirmationToken"
            element={<OnboardNewUser />}
          />
          <Route path="/auth/callback" element={<AuthCallback />} />
          <Route path="/auth/*" element={<AuthCallback />} />
          {enableSupportPage && <Route path="/support" element={<Support />} />}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </main>
    </>
  );
}

export default UnauthenticatedApp;
