import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

type AlertProps = {
  title?: string;
  text?: React.ReactNode;
  className?: string;
};

export function Alert({ title, text, className = "" }: AlertProps) {
  const classes = className + " rounded-md bg-yellow-50 p-4";
  return (
    <div className={classes}>
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-semibold text-yellow-800">{title}</h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
