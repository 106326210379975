if (typeof import.meta.env.VITE_PORTAL_API_BASE_URL != "string") {
  throw new Error(
    "VITE_PORTAL_API_BASE_URL System ENV variable is not set, please ensure your .env is properly configured"
  );
}

if (typeof import.meta.env.VITE_API_SERVICE_BASE_URL != "string") {
  throw new Error(
    "VITE_API_SERVICE_BASE_URL System ENV variable is not set, please ensure your .env is properly configured"
  );
}

if (typeof import.meta.env.VITE_PORTAL_GRAPHQL_API_BASE_URL != "string") {
  throw new Error(
    "VITE_PORTAL_GRAPHQL_API_BASE_URL System ENV variable is not set, please ensure your .env is properly configured"
  );
}

if (typeof import.meta.env.VITE_LOGO != "string") {
  throw new Error(
    "VITE_LOGO System ENV variable is not set, please ensure your .env is properly configured"
  );
}

if (typeof import.meta.env.VITE_PORTAL_NAME != "string") {
  throw new Error(
    "VITE_PORTAL_NAME System ENV variable is not set, please ensure your .env is properly configured"
  );
}

if (typeof import.meta.env.VITE_FRONTEND_URL != "string") {
  throw new Error(
    "VITE_FRONTEND_URL System ENV variable is not set, please ensure your .env is properly configured"
  );
}

if (typeof import.meta.env.VITE_EMAIL_FROM != "string") {
  throw new Error(
    "VITE_EMAIL_FROM System ENV variable is not set, please ensure your .env is properly configured"
  );
}

if (typeof import.meta.env.VITE_BUGSNAG_API_KEY != "string") {
  console.log(
    "VITE_BUGSNAG_API_KEY not set, errors won't be reported to Bugsnag"
  );
}

if (typeof import.meta.env.VITE_ENVIRONMENT != "string") {
  console.log("VITE_ENVIRONMENT not set, defaulting to 'DEVELOPMENT'");
}

export const portalApiBaseUrl: string = import.meta.env
  .VITE_PORTAL_API_BASE_URL;

export const apiServiceBaseUrl: string = import.meta.env
  .VITE_API_SERVICE_BASE_URL;

export const portalGraphQlBaseUrl: string = import.meta.env
  .VITE_PORTAL_GRAPHQL_API_BASE_URL;

export const portalLogo: string = import.meta.env.VITE_LOGO;

export const enableVoice: boolean = isTrue(import.meta.env.VITE_ENABLE_VOICE);

export const enableApiKeys: boolean = isTrue(
  import.meta.env.VITE_ENABLE_API_KEYS
);
export const enableSupportPage: boolean = isTrue(
  import.meta.env.VITE_ENABLE_SUPPORT_PAGE
);
export const portalName: string = import.meta.env.VITE_PORTAL_NAME;
export const portalTitle: string = import.meta.env.VITE_PAGE_TITLE;
// When we're doing backend redirects we need to know where the request was sent from
export const frontendUrl: string = import.meta.env.VITE_FRONTEND_URL;
// This is for password reset emails and similar, we need to be able to differentiate the senders
// depending for which environment we're building the app for.
export const emailFrom: string = import.meta.env.VITE_EMAIL_FROM;

export const bugsnagApiKey: string = import.meta.env.VITE_BUGSNAG_API_KEY;

export const appEnvironment = import.meta.env.VITE_ENVIRONMENT || "DEVELOPMENT";

// a temporary helper that allows only internal users to access certain functionality
// which is usually a Work In Progress.
export function isTsgOnlyFunctionalityAllowed(sessionInfo: any) {
  if (!sessionInfo?.user?.email) return false;

  const email: string = sessionInfo?.user?.email;

  return email.includes("@tsgglobal.com") || email.includes("@example.com");
}

function isTrue(env_var: any) {
  return env_var === "true" || env_var === "TRUE" || env_var === "1";
}
