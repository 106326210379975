import { useState } from "react";
import { useQuery } from "react-query";
import { DateRangePicker } from "~/components/DateRangePicker";
import { Label } from "~/components/Label";
import { Layout } from "~/components/Layout";
import { Loader } from "~/components/Loader";
import { Select } from "~/components/Select";
import { formatDateToISO, formatNumberToPercentage } from "~/utils/format";
import { getDeliverabilityRecords } from "~/api/deliverability";
import subWeeks from "date-fns/subWeeks";
import subDays from "date-fns/subDays";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { format } from "date-fns";
import { dlrTsgErrorMap } from "~/utils/dlrErrorCodes";

export function Deliverability() {
  const [dates, setDates] = useState<Date[]>(() => [
    subWeeks(new Date(), 2),
    subDays(new Date(), 1),
  ]);
  const [errorCode, setErrorCode] = useState<errorCodeOption>(defaultErrorCode);

  const { data, error, isLoading } = useDeliverabilityData({
    start_date: formatDateToISO(dates[0]),
    end_date: formatDateToISO(dates[1]),
  });

  function setDateRange(dates: [Date, Date]) {
    setDates(dates);
  }

  function setErrorCodeOption(code: errorCodeOption) {
    setErrorCode(code);
  }

  const getLabelColor = (label: string) => {
    if (label === errorCode.label) {
      return "#16A34A";
    }
    return "#282c34";
  };

  const renderCustomizedLabel = ({ x, y, cx, value, name }: any) => {
    return (
      <text
        fontSize={14}
        x={x}
        y={y}
        fill={getLabelColor(name)}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {renderLabelHyperLink(name)} - {formatNumberToPercentage(value)}
      </text>
    );
  };

  const renderLabelHyperLink = (labelName: string) => {
    const label = formatPieChartLabel(labelName);
    if (label === "Delivered") return label;
    return (
      <a
        target="_blank"
        href="https://support.tsgglobal.com/hc/en-us/articles/4643124175131-Common-DLR-Error-Codes"
      >
        {label}
      </a>
    );
  };

  function getCellColor(cellEntry: { name: string; value: number }) {
    const errorCode = cellEntry.name.split(" - ")[0];
    const errorColor = errorColorMap[errorCode];
    return errorColor || "#FF8042";
  }

  if (isLoading) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  if (error) {
    return <Layout>Error occured: {error.message}</Layout>;
  }

  return (
    <Layout className="Phone-Numbers">
      <h2 className="text-2xl lg:text-3xl font-bold mt-2 mb-6">
        Deliverability
      </h2>

      <div>
        <div className="flex flex-col">
          <div className="-my-2 sm:-mx-6 lg:-mx-8">
            <div className="py-2 mb-4 align-middle inline-block min-w-full sm:px-6 lg:px-8 relative">
              <div className="flex flex-row items-center flex-wrap w-full">
                <div className="flex flex-row">
                  <div className="flex flex-col items-start mr-8">
                    <Label>Dates</Label>
                    <DateRangePicker
                      className="rounded-md bg-white"
                      // @ts-ignore
                      onChange={setDateRange}
                      value={dates}
                      // max date is today - data is calculated nightly after each day
                      maximumDate={new Date()}
                    />
                  </div>
                  <div>
                    <Label>Error Code</Label>
                    <Select
                      defaultValue={errorCode}
                      value={errorCode}
                      onChange={setErrorCodeOption}
                      options={data.errorCodes}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {data.deliverabilityPercentage && (
        <div>
          Your average deliverability rate for the selected period is:{" "}
          {data.deliverabilityPercentage.toFixed(2)}%
        </div>
      )}
      <div
        style={{ width: "100%", height: 500 }}
        className="flex justify-between"
      >
        <ResponsiveContainer>
          <LineChart
            data={data?.deliverabilityRecords}
            margin={{
              top: 50,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis
              ticks={[0, 0.25, 0.5, 0.75, 1]}
              tickFormatter={formatNumberToPercentage}
            />
            <Tooltip formatter={integerFormatter} />
            <Legend formatter={formatLegendLabels} />
            <Line
              type="monotone"
              dataKey={errorCode.value}
              stroke="#029be5"
              animationDuration={450}
              activeDot={{ r: 6 }}
            />
          </LineChart>
        </ResponsiveContainer>
        <PieChart
          width={500}
          height={450}
          margin={{
            top: 50,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <Tooltip formatter={integerFormatter} />
          <Legend formatter={formatLegendLabels} />
          <Pie
            data={data.errorCodeCountMap}
            dataKey="value"
            nameKey="name"
            cy="50%"
            labelLine={false}
            outerRadius={80}
            legendType="none"
            label={renderCustomizedLabel}
            minAngle={4}
            blendStroke={
              data.errorCodeCountMap.length === 1 &&
              data.errorCodeCountMap[0].value === 1
            }
          >
            {data.errorCodeCountMap.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={getCellColor(entry)} />
            ))}
          </Pie>
        </PieChart>
      </div>
    </Layout>
  );
}

type deliverabilityItem = {
  name: string;
  deliverability: any;
};

type errorCodeOption = { label: string; value: string };

type useDeliverabilityReturn = {
  isLoading: boolean;
  isFetching: boolean;
  error: Error;
  data: {
    deliverabilityRecords: deliverabilityItem[];
    errorCodes: errorCodeOption[];
    deliverabilityPercentage: number;
    errorCodeCountMap: { name: string; value: number }[];
  };
};

type useDeliverabilityArgs = {
  start_date: string;
  end_date: string;
};

function useDeliverabilityData({
  start_date,
  end_date,
}: useDeliverabilityArgs): useDeliverabilityReturn {
  const { isLoading, error, data, isFetching } = useQuery(
    ["deliverability", start_date, end_date],
    async ({}) => {
      const deliverabilityData = await getDeliverabilityRecords({
        start_date,
        end_date,
      });

      const deliverabilityRecords = formatDeliverabilityRecords(
        deliverabilityData.deliverabilityRecords
      );

      return {
        ...deliverabilityData,
        deliverabilityRecords,
      };
    },
    {
      keepPreviousData: true,
    }
  );

  return {
    isLoading,
    isFetching,
    error: error as Error,
    data: data ?? [],
  };
}

function formatLegendLabels(value: any, _entry: any, _index: number): string {
  return formatLabel(value as string);
}

function integerFormatter(value: string, name: string): [string, string] {
  return [formatNumberToPercentage(value), formatLabel(name)];
}

function formatLabel(str: string): string {
  // @ts-ignore
  const label = labelMap[str];
  if (label) return label;
  return str;
}

function formatPieChartLabel(str: string): string {
  // @ts-ignore
  const label = pieChartLabelMap[str];
  if (label) return label;
  return str;
}

const labelMap = {
  All: "Deliverability",
  ...dlrTsgErrorMap,
};

const pieChartLabelMap = {
  Delivered: "Delivered",
  ...dlrTsgErrorMap,
};

function formatDeliverabilityRecords(records: any) {
  return Object.keys(records)
    .sort()
    .map((dateKey) => ({
      ...records[dateKey],
      name: format(new Date(dateKey), "MM/dd/yyyy"),
    }));
}

const defaultErrorCode = {
  label: "All",
  value: "All",
};

const errorColorMap: any = {
  "015": "#FF8042",
  "00b": "#FF8042",
  "066": "#FF8042",
  "045": "#dc2626",
  "069": "#dc2626",
  "488": "#dc2626",
  Other: "#FF8042",
  Delivered: "#16A34A",
  All: "#16A34A",
};
