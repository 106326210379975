import React from "react";

type LayoutProps = {
  children: React.ReactNode;
  className?: string;
};

export function Layout({ children, className = "" }: LayoutProps) {
  const classes = `Layout ${className}`;
  return <div className={classes}>{children}</div>;
}
