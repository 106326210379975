import { gql } from "graphql-request";
import { graphqlClient } from "./graphqlClient";

type getCdrRecordsArgs = {
  type: string;
  startDatetime: string;
  endDatetime: string;
  cursor?: string;
  limit?: number;
  sourceNumber?: string;
  destinationNumber?: string;
};

export async function getCdrRecords({
  type,
  startDatetime,
  endDatetime,
  cursor,
  limit,
  sourceNumber,
  destinationNumber,
}: getCdrRecordsArgs) {
  const query = gql`
    query cdrRecordsQuery(
      $type: CdrType!
      $startDatetime: DateTime!
      $endDatetime: DateTime!
      $limit: Int
      $cursor: String
      $sourceNumber: String
      $destinationNumber: String
    ) {
      cdrRecords(
        type: $type
        startDatetime: $startDatetime
        endDatetime: $endDatetime
        limit: $limit
        cursor: $cursor
        sourceNumber: $sourceNumber
        destinationNumber: $destinationNumber
      ) {
        exportLink

        paginationInfo {
          nextCursor
          limit
        }

        cdrs {
          ... on ApiCdr {
            datetime
            from
            destination
            status
            charge
            type
          }

          ... on MessageCdr {
            datetime
            fromNumber
            toNumber
            direction
            status
            charge
            type
          }

          ... on VoiceCdr {
            datetime
            callId
            sourceAni
            destinationNumber
            didUsed
            sessionTime
            status
            calledRate
            charge
            callType
            aniIi
          }
        }
      }
    }
  `;

  const data = {
    type,
    startDatetime,
    endDatetime,
    limit,
    cursor,
    sourceNumber,
    destinationNumber,
  };

  try {
    const response = await graphqlClient(query, data);
    if (response.cdrRecords) {
      const { cdrRecords } = response;

      return {
        cdrs: cdrRecords.cdrs,
        paginationInfo: cdrRecords.paginationInfo,
        exportLink: cdrRecords.exportLink,
      };
    } else {
      throw response;
    }
  } catch (errorResponse) {
    throw errorResponse;
  }
}

type countCdrRecordsArgs = Omit<getCdrRecordsArgs, "cursor" | "limit">;

export async function countCdrRecords({
  type,
  startDatetime,
  endDatetime,
  sourceNumber,
  destinationNumber,
}: countCdrRecordsArgs) {
  const query = gql`
    query cdrRecordsCountQuery(
      $type: CdrType!
      $startDatetime: DateTime!
      $endDatetime: DateTime!
      $source: String
      $destination: String
    ) {
      cdrRecordsCount(
        type: $type
        startDatetime: $startDatetime
        endDatetime: $endDatetime
        source: $source
        destination: $destination
      ) {
        total
      }
    }
  `;

  const data = {
    type,
    startDatetime,
    endDatetime,
    sourceNumber,
    destinationNumber,
  };

  const result = await graphqlClient(query, data);
  const { cdrRecordsCount } = result;
  return {
    total: cdrRecordsCount.total,
  };
}
