export function join(urlBase: string, urlPath: string): string {
  if (urlBase.endsWith("/")) {
    urlBase = urlBase.slice(0, -1);
  }

  if (urlPath.startsWith("/")) {
    urlPath = urlPath.slice(1);
  }

  if (urlPath.endsWith("/")) {
    urlPath = urlPath.slice(0, -1);
  }

  return `${urlBase}/${urlPath}`;
}
