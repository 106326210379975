/**
 * Building the CSV from the Data two-dimensional array
 * Each column is separated by ";" and new line "\n" for next row
 * @param content two-dimensional array of cells
 */
export function generateCsv(content: string[][]): string {
  return content.map((e: string[]) => e.join(",")).join("\n");
}

/**
 * Generates a CSV download link on the fly and clicks it programatically.
 *
 * Taken from: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 *
 * @param filename {string}
 * @param content {any[]}
 */
export function downloadCsv(filename: string, content: string) {
  // The download function takes a CSV string, the filename and mimeType as parameters
  // Scroll/look down at the bottom of this snippet to see how download is called
  // var download = function(content, filename, mimeType) {
  var a = document.createElement("a");
  const mimeType = "text/csv;encoding:utf-8";

  if (URL && "download" in a) {
    //html5 A[download]
    a.href = URL.createObjectURL(
      new Blob([content], {
        type: mimeType,
      })
    );
    a.setAttribute("download", filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    location.href =
      "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
  }
}
