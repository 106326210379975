import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { resetAllStores } from "~/stores/store";

export function LogoutLink() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  function logout() {
    // On logout we want to clear all the caching,
    // so the new user doesn't see any previous user's data.
    queryClient.clear();

    // And we want to clear the store data as well.
    resetAllStores();

    // We'll redirect to login, otherwise <RedirectToLogin /> in Router will kick in
    // and save the last location as redirect after login.
    navigate("/login");
  }

  return (
    <div className="Logout-Link" onClick={logout}>
      <ArrowRightOnRectangleIcon
        className="Logout-Icon flex-shrink-0 h-5 w-5 text-black mr-1"
        aria-hidden="true"
      />{" "}
      Log out
    </div>
  );
}
