import { useNavigate } from "react-router-dom";
import { Layout } from "../../components/Layout";
import {
  EnvelopeIcon,
  LinkIcon,
  LifebuoyIcon,
  InformationCircleIcon,
  SignalIcon,
  RssIcon,
  TicketIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { AuthenticatedState, useAuthStore } from "~/stores/authStore";

export function Support() {
  const user = useAuthStore(
    (store) => (store.state as AuthenticatedState).user
  );
  const navigate = useNavigate();

  return (
    <div className="Support">
      <Layout>
        <div className="max-w-xl mx-auto mt-12">
          <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
            Need support?
          </h2>
          <p className="mt-4 text-lg leading-6 text-gray-500">
            You can reach out for support using the information listed below.
          </p>
          <div className="mt-12 text-base text-gray-500">
            <SupportLinkItem
              icon={EnvelopeIcon}
              name="Email"
              linkHref="mailto:support@tsgglobal.com?subject=Customer%20Portal%20Support"
              linkText="support@tsgglobal.com"
            />

            <SupportLinkItem
              icon={LinkIcon}
              name="Website"
              linkHref="https://www.tsgglobal.com"
              linkText="tsgglobal.com"
            />

            <SupportLinkItem
              icon={InformationCircleIcon}
              name="API Docs"
              linkHref="https://docs.tsgglobal.com"
              linkText="docs.tsgglobal.com"
            />

            <SupportLinkItem
              icon={SignalIcon}
              name="Status"
              linkHref="https://status.tsgglobal.com"
              linkText="status.tsgglobal.com"
            />

            <SupportLinkItem
              icon={RssIcon}
              name="Announcements"
              linkHref="https://support.tsgglobal.com/hc/en-us/sections/1260801448130-Announcements"
              linkText="See latest announcements"
            />

            <SupportLinkItem
              icon={LifebuoyIcon}
              name="Support pages"
              linkHref="https://support.tsgglobal.com"
              linkText="support.tsgglobal.com"
            />

            <SupportActionItem
              icon={TicketIcon}
              name="Open Request"
              onClick={() => navigate("/support/new-ticket")}
              linkText="Open a request here"
            />
          </div>
        </div>
      </Layout>
    </div>
  );
}

type SupportLinkItemProps = {
  icon: (props: React.ComponentProps<"svg">) => JSX.Element;
  name: string;
  linkHref: string;
  linkText: string;
};

function SupportLinkItem({
  icon,
  name,
  linkHref,
  linkText,
}: SupportLinkItemProps) {
  // var name must start with a capital letter
  const IconComponent = icon;

  return (
    <div className="mt-6 flex flex-col md:flex-row">
      <div className="font-bold w-[180px] flex">
        <IconComponent
          className="flex-shrink-0 h-6 w-6 text-gray-400 mr-2"
          aria-hidden="true"
        />{" "}
        {name}
      </div>

      <span className="">
        <a
          className="underline hover:opacity-75 transition-opacity ease-out duration-150"
          href={linkHref}
          target="_blank"
          rel="noreferrer noopener"
        >
          {linkText}
        </a>
      </span>
    </div>
  );
}

type SupportActionItemProps = {
  icon: (props: React.ComponentProps<"svg">) => JSX.Element;
  name: string;
  linkText: string;
  onClick: () => void;
};

function SupportActionItem({
  icon,
  name,
  linkText,
  onClick,
}: SupportActionItemProps) {
  // var name must start with a capital letter
  const IconComponent = icon;

  return (
    <div className="mt-6 flex flex-col md:flex-row">
      <div className="font-bold w-[180px] flex">
        <IconComponent
          className="flex-shrink-0 h-6 w-6 text-gray-400 mr-2"
          aria-hidden="true"
        />{" "}
        {name}
      </div>

      <span className="">
        <span
          onClick={onClick}
          className="underline hover:opacity-75 transition-opacity ease-out duration-150 cursor-pointer"
        >
          {linkText}
        </span>
      </span>
    </div>
  );
}
