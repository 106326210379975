import { useQuery } from "react-query";
import { getTelephoneNumberInfo } from "~/api/phoneNumbers";

const TWO_MINUTES_IN_MS = 1000 * 60 * 2;

export function useTelephoneNumberInfo(token?: string, phoneNumber?: string) {
  const { isLoading, error, data, refetch, isFetching } = useQuery(
    ["phoneNumber", phoneNumber, "info"],
    async ({}) => {
      const config = await getTelephoneNumberInfo(token!, phoneNumber!);
      return config;
    },
    {
      // don't trigger the load if the companyId or token are missing
      enabled: !!phoneNumber && !!token,
      keepPreviousData: true,
      staleTime: TWO_MINUTES_IN_MS,
      retry: false,
    }
  );

  return {
    isLoading,
    error,
    data,
    refetch,
    isFetching,
  };
}
