import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { Form } from "../../../components/Form";
import { Input } from "../../../components/Input";
import { ForgotPasswordAction } from "./ForgotPasswordAction";
import { PrimaryButton } from "../../../components/PrimaryButton";
import {
  emailValidation,
  passwordValidation,
} from "../../../services/validation";
import { Loader } from "../../../components/Loader";
import { SocialLogin } from "../../../components/SocialLogin";
import { ErrorComponent } from "~/components/Error";
import { useAuthStore } from "~/stores/authStore";

type Inputs = {
  email: string;
  password: string;
};

export function LogInForm() {
  const login = useAuthStore((store) => store.login);
  const error = useAuthStore((store) => store.error);
  const isWorking = useAuthStore((store) => store.isWorking);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    login(data.email, data.password);
  };

  return (
    <div className="LogIn-FormWrapper">
      <h3 className="text-gray-800 text-xl lg:text-xl font-bold text-center my-4 md:my-8">
        Login
      </h3>

      <Form onSubmit={onSubmit} className="flex flex-col gap-4 p-4 md:p-8">
        <Input
          label="Email"
          name="email"
          validation={emailValidation}
          defaultValue=""
          placeholder="john.doe@example.com"
          disabled={isWorking}
        />

        <Input
          label="Password"
          name="password"
          validation={passwordValidation}
          type="password"
          disabled={isWorking}
        />

        <PrimaryButton
          className="mt-8 flex justify-center"
          disabled={isWorking}
          type="submit"
        >
          {isWorking ? <Loader small /> : "Login"}
        </PrimaryButton>
        <ForgotPasswordAction />
      </Form>

      <ErrorComponent
        error={error}
        className="text-red-500 py-8 px-4 w-full bg-gray-200 text-center"
      />
      <SocialLogin />
    </div>
  );
}
