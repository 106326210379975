import classNames from "classnames";
import "./Loader.css";

type LoaderProps = {
  small?: boolean;
  smallInline?: boolean;
  medium?: boolean;
  white?: boolean;
};

export function Loader({ small, smallInline, medium, white }: LoaderProps) {
  const loaderClasses = classNames({
    Loader: true,
    "Loader-Small": small,
    "Loader-Small-Inline": smallInline,
    "Loader-White": white,
    "Loader-Medium": medium,
  });

  return <div className={loaderClasses}></div>;
}

/**
 * A overlay loader.
 *
 * It will cover the parent element with a shadow and show a loader in the center.
 * it should be added within containers with 'position: relative'.
 */
export function LoaderOverlay({ show = true }: LoaderOverlayProps) {
  if (!show) return null;

  return (
    <div className="absolute top-0 bottom-0 left-0 right-0 bg-gray-100 bg-opacity-25  flex items-center justify-center p-6">
      <Loader />
    </div>
  );
}

type LoaderOverlayProps = {
  show?: boolean;
};
