import { mapKeys, snakeCase } from "lodash-es";
import { httpClient } from "./httpClient";

const pageSize = 20;

export async function listTerminationIPs(page: number): Promise<any> {
  const response = await httpClient(
    `/termination_ips?page_size=${pageSize}&page=${page}`,
    {
      method: "GET",
    }
  );

  return {
    entries: response.body,
    pageNumber: response.headers["page-number"],
    pageSize: response.headers["per-page"],
    totalPages: response.headers["total-pages"],
    totalEntries: response.headers["total"],
  };
}

export async function createTerminationIP(ip: string): Promise<any> {
  const response = await httpClient("/termination_ips", {
    method: "POST",
    body: { ip: ip },
  });

  return response.body.data;
}

export async function deleteTerminationIP(
  terminationIPId: string
): Promise<any> {
  const response = await httpClient(`/termination_ips/${terminationIPId}`, {
    method: "DELETE",
  });

  return response.body.data;
}
