interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  // showIcon: boolean;
}

export function PrimaryButton({
  disabled = false,
  type = "button",
  children,
  className = "",
  ...props
}: ButtonProps) {
  let baseClassNames = "PrimaryButton";
  if (disabled) {
    baseClassNames += " PrimaryButton--disabled";
  }
  const classes = baseClassNames + " " + className;

  return (
    <button disabled={disabled} type={type} className={classes} {...props}>
      {children}
    </button>
  );
}
