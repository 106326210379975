import "./RevealSecret.css";
import { useState } from "react";
import classNames from "classnames";

type RevealSecretProps = {
  secret: string;
};

export function RevealSecret({ secret }: RevealSecretProps) {
  const [isVisible, setIsVisible] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  function copyToClibboard() {
    navigator.clipboard.writeText(secret);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1500);
  }

  function toggleVisible() {
    setIsVisible(!isVisible);
  }

  function doNothing() {}

  return (
    <div className="RevealSecret flex flex-wrap gap-1 flex-col lg:flex-row">
      {isVisible ? (
        <div className="mr-auto text-[11px] lg:text-[13px]">{secret}</div>
      ) : (
        <div className="mr-auto text-[11px] lg:text-[13px] relative top-[3px]">
          {secret.replaceAll(/./g, "*")}
        </div>
      )}

      <span className="flex gap-2 lg:gap-1">
        {isCopied ? (
          <ActionButton onClick={doNothing}>Copied</ActionButton>
        ) : (
          <ActionButton onClick={copyToClibboard}>Copy</ActionButton>
        )}
        <Separator />
        <ActionButton className="" onClick={toggleVisible}>
          {isVisible ? "Hide" : "Show"}
        </ActionButton>
      </span>
    </div>
  );
}

type ActionButtonProps = {
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
};

function ActionButton({
  children,
  onClick,
  className = " ",
}: ActionButtonProps) {
  const classes = classNames(
    "bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none",
    {
      [className]: className,
    }
  );

  return (
    <button type="button" className={classes} onClick={onClick}>
      {children}
    </button>
  );
}

function Separator() {
  return (
    <span className="text-gray-300" aria-hidden="true">
      |
    </span>
  );
}
