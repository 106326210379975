import { Layout } from "../../components/Layout";
import {
  FormFileInput,
  Input,
  RadioInputGroup,
  TextArea,
} from "../../components/Input";
import {
  emailValidation,
  multiplePhoneNumbersValidation,
  multipleTollFreePhoneNumbersValidation,
  requiredValidation,
  telephoneSeparatorRegEx,
} from "../../services/validation";
import { PrimaryButton } from "../../components/PrimaryButton";
import { useMutation, useQueryClient } from "react-query";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { Loader } from "../../components/Loader";
import { ErrorComponent } from "../../components/Error";
import { useRef, useState } from "react";
import { FormSelect } from "~/components/Select";
import { createOrder, Order } from "~/api/order";
import { Toast } from "~/components/Toast";
import {
  YesNoUnsureDropdownItemType,
  AnotherMessagingServiceTrafficType,
  yesNoUnsureDropdownItems,
  anotherMessagingServiceTrafficItems,
  trueFalseDropdownItems,
  estimatedMonthlyMessageVolume,
  EstimatedMonthlyMessageVolumeItemType,
  yesNoDropdownItems,
  YesNoDropdownItemValueType,
  YesNoUnsureDropdownItemValueType,
  TrueFalseDropdownItemValueType,
  AnotherMessagingServiceTrafficValueType,
} from "~/utils/dropdownItemTypes";
import { readFileInputAsBase64 } from "~/utils/file";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ServerValidationError, setErrorsOnForm } from "~/utils/error";

type FormValues = {
  type: "verified_sender_form";

  // 1. messaging provider
  messagingProvider: string;

  // 2. business name
  businessName: string;

  // 3. business registered address
  businessRegisteredAddress: string;
  businessRegisteredCity: string;
  businessRegisteredState: string;
  businessRegisteredZip: string;

  // 4. missing? - this seems like a mistake

  // 5. busines contact
  businessContactFirstName: string;
  businessContactLastName: string;

  // 6. busines contact information
  businessContactEmail: string;
  businessContactPhone: string;

  // 7. numbers
  numbers: string;
  numbersExplanation: string;

  // 8. summarize use cases
  useCase: string;

  // 9. how will consumers be opting in?
  howDoConsumersOptIn: string;

  // 10. will the messages be advertising or promoting a commercial product or service
  commercialMessages: YesNoUnsureDropdownItemType;

  // 11. was this traffic previously on another messaging service
  previouslyAnotherMessagingServiceTraffic: AnotherMessagingServiceTrafficType;

  // 12. if previously on another messaging service, please provide sample content and numbers
  previouslyAnotherMessagingServiceContent: string;
  previouslyAnotherMessagingServiceNumbers: string;

  // 13. where is the number published (if anywhere)
  numberPublishedLocation: string;

  // 14. fortune 500 or 1000 company?
  fortune500Or1000Company: YesNoDropdownItemValueType;

  // 15. corporate website
  messageSenderWebsite: string;

  // 16. terms & privacy
  termsAndPrivacy: string;

  // 17. Calls to action
  callToAction: string;

  // 18. example Message(s)
  sampleMessages: string;
  // 19. HELP, STOP* & Other details (can be "true" | "false")
  helpMessageContainsOptOutInstructions: TrueFalseDropdownItemValueType;
  stopKeywordIsSupportedAsAnOptOutMethod: TrueFalseDropdownItemValueType;

  // 20. traffic volume
  estimatedMonthlyMessageVolume: EstimatedMonthlyMessageVolumeItemType;

  // valid values "true", "false", "unsure"
  // 21. Will this program deliver messages to canadian end-users?
  canadianTrafficMostly: YesNoUnsureDropdownItemValueType;
  // 22. is this program's intent or subject matter related to COVID-19?
  covid19Related: YesNoUnsureDropdownItemValueType;
};

type SubmitValues = Omit<
  FormValues,
  | "commercialMessages"
  | "estimatedMonthlyMessageVolume"
  | "numbers"
  | "previouslyAnotherMessagingServiceNumbers"
  | "previouslyAnotherMessagingServiceTraffic"
  | "sampleMessages"
  | "fortune500Or1000Company"
> & {
  commercialMessages: YesNoUnsureDropdownItemValueType;
  estimatedMonthlyMessageVolume: string;
  numbers: { number: string }[];
  previouslyAnotherMessagingServiceNumbers: string[];
  previouslyAnotherMessagingServiceTraffic: AnotherMessagingServiceTrafficValueType;
  sampleMessages: string[];
  attachments: string[];
  fortune500Or1000Company: string;
};

const verifiedFormDefaultValues: FormValues = {
  type: "verified_sender_form",
  messagingProvider: "TSG Global",
  businessName: "",
  businessRegisteredAddress: "",
  businessRegisteredCity: "",
  businessRegisteredState: "",
  businessRegisteredZip: "",
  businessContactFirstName: "",
  businessContactLastName: "",
  businessContactPhone: "",
  businessContactEmail: "",
  numbers: "",
  numbersExplanation: "",
  useCase: "",
  howDoConsumersOptIn: "",
  commercialMessages: yesNoUnsureDropdownItems[0],
  previouslyAnotherMessagingServiceTraffic:
    anotherMessagingServiceTrafficItems[0],
  previouslyAnotherMessagingServiceContent: "",
  previouslyAnotherMessagingServiceNumbers: "",
  numberPublishedLocation: "",
  fortune500Or1000Company: yesNoDropdownItems[1].value,
  messageSenderWebsite: "",
  termsAndPrivacy: "",
  callToAction: "",
  sampleMessages: "",
  helpMessageContainsOptOutInstructions: trueFalseDropdownItems[0].value,
  stopKeywordIsSupportedAsAnOptOutMethod: trueFalseDropdownItems[0].value,
  estimatedMonthlyMessageVolume: estimatedMonthlyMessageVolume[0],
  canadianTrafficMostly: yesNoUnsureDropdownItems[2].value,
  covid19Related: yesNoUnsureDropdownItems[2].value,
};

export function TollFreeVerificationForm() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const phoneNumbers = searchParams.get("numbers");

  const [defaultValues] = useState(() => {
    return { ...verifiedFormDefaultValues, numbers: phoneNumbers || "" };
  });

  const queryClient = useQueryClient();
  const methods = useForm<FormValues>({ mode: "onSubmit", defaultValues });
  const numbersWatch = useWatch({ name: "numbers", control: methods.control });
  const previouslyUsedTrafficOnAnotherMessagingServiceWatch = useWatch({
    name: "previouslyAnotherMessagingServiceTraffic",
    control: methods.control,
  });
  const formRef = useRef<HTMLFormElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const mutation = useMutation<Order, Error, SubmitValues>(
    (formData) => createOrder(formData),
    {
      onError: (error, variables, _ctx) => {
        console.error("form submission error", error);

        if (error instanceof ServerValidationError) {
          // A workaround for dynamic mapping of errors since this is a dynamic array of nested objects.
          // Not the cleanest approach but it works.
          const errorMapping = variables.numbers.reduce(
            (acc, val: any, idx: number) => {
              console.log("val", val, "type", typeof val);
              acc[`numbers[${idx}].number`] = "numbers";
              return acc;
            },
            {} as Record<string, string>
          );

          setErrorsOnForm(error.errors, methods, errorMapping);
        }

        Toast({
          type: "Error",
          title: "An error occured, please check your inputs and try again...",
        });
      },
      onSuccess: (data, _variables, _ctx) => {
        Toast({
          type: "Success",
          title: "You submitted your numbers verification form.",
        });

        queryClient.invalidateQueries(["orders"]);
        queryClient.invalidateQueries(["ordersCount"]);

        navigate(`/orders?created_order=${data.id}`);
      },
    }
  );

  const submitForm: SubmitHandler<FormValues> = async (data) => {
    const numbers = cleanOutArrayData(
      data.numbers.split(telephoneSeparatorRegEx)
    ).map((number) => ({ number }));

    // don't split them out for now, just store as a single message and wrap into an array
    // const sampleMessages = cleanOutArrayData(data.sampleMessages.split("\n"));
    const sampleMessages = [data.sampleMessages.trim()];

    const previouslyUsedNumbers = cleanOutArrayData(
      data.previouslyAnotherMessagingServiceNumbers.split(
        telephoneSeparatorRegEx
      )
    );

    const attachments = await readFileInputAsBase64(fileInputRef);

    const submitData: SubmitValues = {
      ...data,
      numbers: numbers,
      sampleMessages: sampleMessages,
      commercialMessages: data.commercialMessages.value,
      estimatedMonthlyMessageVolume: data.estimatedMonthlyMessageVolume.value,
      previouslyAnotherMessagingServiceNumbers: previouslyUsedNumbers,
      previouslyAnotherMessagingServiceTraffic:
        data.previouslyAnotherMessagingServiceTraffic.value,
      attachments: attachments,
      fortune500Or1000Company:
        data.fortune500Or1000Company === "true" ? "Yes" : "No",
    };

    mutation.mutate(submitData);
  };

  // const showResults = methods.formState.isSubmitted && !mutation.isLoading;
  const previouslyUsedTrafficOnAnotherMessagingService =
    previouslyUsedTrafficOnAnotherMessagingServiceWatch?.value !== "new";

  const isUsingMultipleNumbers =
    numbersWatch.split(/\n|\.|,/).filter((v) => v.trim()).length > 1;

  return (
    <Layout className="Phone-Numbers">
      <h2 className="text-2xl lg:text-3xl font-bold mt-2">
        Toll-Free Verified Sender Form
      </h2>
      <div className="mb-6">
        Use this form to submit your toll-free campaign information. More
        information can be found here:{" "}
        <a
          className="link"
          href="https://support.tsgglobal.com/hc/en-us/articles/5872363711387-New-Toll-Free-SMS-MMS-Verified-Sender-Requirements"
          target="_blank"
          rel="noreferrer noopener"
        >
          New Toll-Free SMS/MMS Verified Sender Requirements
        </a>
      </div>

      <div className="max-w-2xl mt-12">
        <ErrorComponent error={mutation.error as Error} className="pb-4" />

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(submitForm)} autoComplete="off">
            <Input
              validation={requiredValidation}
              name="businessName"
              label="Business name"
              wrapperClassName="mb-6"
              required
              helperMessage="The name of the business/organization the end user believes is sending the message."
            />

            <div className="relative mb-8 mt-8">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center">
                <span className="px-2 bg-gray bg-gray-100 text-lg font-bold text-slate-600">
                  Compliance point of contact info
                </span>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4 mb-4 mt-6">
              <Input
                validation={requiredValidation}
                name="businessContactFirstName"
                label="Business contact first name"
                required
              />
              <Input
                validation={requiredValidation}
                name="businessContactLastName"
                label="Business contact last name"
                required
              />
            </div>

            <Input
              validation={requiredValidation}
              name="businessContactPhone"
              label="Business contact phone"
              wrapperClassName="mb-4"
              required
            />

            <Input
              validation={emailValidation}
              name="businessContactEmail"
              label="Business contact email"
              wrapperClassName="mb-4"
              required
            />

            {/* Address */}

            <Input
              validation={requiredValidation}
              name="businessRegisteredAddress"
              label="Business contact address"
              wrapperClassName="mb-4"
              required
            />

            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4  md:grid-cols-3 mb-4">
              <Input
                validation={requiredValidation}
                name="businessRegisteredCity"
                label="business contact city"
                required
              />
              <Input
                validation={requiredValidation}
                name="businessRegisteredState"
                label="State/province"
                required
              />

              <Input
                validation={requiredValidation}
                name="businessRegisteredZip"
                label="ZIP/Postal"
                required
              />
            </div>

            <div className="relative mb-8 mt-8">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center">
                <span className="px-2 bg-gray bg-gray-100 text-lg font-bold text-slate-600">
                  Campaign Information
                </span>
              </div>
            </div>

            <TextArea
              required
              validation={multipleTollFreePhoneNumbersValidation}
              label="Telephone number(s)"
              name="numbers"
              wrapperClassName="mb-4"
              inputClassName="min-h-[100px]"
              helperMessage="Input your numbers as 11-digit, comma or new-line separated values , e.g. '18004561234, 18334561235, 18444561236'."
            />

            {isUsingMultipleNumbers && (
              <TextArea
                required
                validation={requiredValidation}
                label="Multiple numbers explanation"
                name="numbersExplanation"
                wrapperClassName="mb-4"
                inputClassName="min-h-[100px]"
                helperMessage="Explain why you're using multiple numbers."
              />
            )}

            <TextArea
              validation={requiredValidation}
              name="useCase"
              label="Use case"
              required
              wrapperClassName="mb-4"
              inputClassName="min-h-[100px]"
              helperMessage="Please describe your use case and the purpose of your messages (i.e. appointment reminders, receipts, etc.)"
            />

            <TextArea
              validation={requiredValidation}
              name="howDoConsumersOptIn"
              label="How do consumers opt in?"
              required
              wrapperClassName="mb-4"
              inputClassName="min-h-[100px]"
              helperMessage="Please describe how the users opt-in to receive your messages. NOTE: you will need to include a screenshot below."
            />

            <FormSelect
              required
              validation={requiredValidation}
              name="commercialMessages"
              label="Are the messages of commercial nature?"
              // defaultValue={yesNoUnsureDropdownItems[2]}
              options={yesNoUnsureDropdownItems}
              wrapperClassName="mb-4"
            />

            <FormSelect
              required
              validation={requiredValidation}
              name="previouslyAnotherMessagingServiceTraffic"
              label="Was this traffic previously used on another messaging service?"
              defaultValue={null}
              options={anotherMessagingServiceTrafficItems}
              wrapperClassName="mb-4"
            />

            {previouslyUsedTrafficOnAnotherMessagingService && (
              <>
                <TextArea
                  validation={requiredValidation}
                  name="previouslyAnotherMessagingServiceContent"
                  label="Messages previously used for this traffic."
                  required
                  wrapperClassName="mb-4"
                  inputClassName="min-h-[100px]"
                  helperMessage="If previously on an another messaging service, please provide a sample content and numbers."
                />

                <TextArea
                  validation={requiredValidation}
                  name="previouslyAnotherMessagingServiceNumbers"
                  label="Numbers previously used for this traffic."
                  required
                  wrapperClassName="mb-4"
                  inputClassName="min-h-[100px]"
                  helperMessage="Please list the numbers that were previously used for this traffic."
                />
              </>
            )}

            <Input
              validation={requiredValidation}
              name="numberPublishedLocation"
              label="Where are the numbers published?"
              placeholder="Is there a location where the numbers are published?"
              required
              wrapperClassName="mb-4"
            />

            <RadioInputGroup
              required
              validation={requiredValidation}
              label="Are you a fortune 500 or 1000 company?"
              name="fortune500Or1000Company"
              options={yesNoDropdownItems}
            />

            <Input
              validation={requiredValidation}
              name="messageSenderWebsite"
              label="Message sender website"
              required
              wrapperClassName="mb-4"
            />

            <Input
              validation={requiredValidation}
              name="termsAndPrivacy"
              label="Terms and privacy location"
              required
              wrapperClassName="mb-4"
            />

            <Input
              validation={requiredValidation}
              name="callToAction"
              label="Call to Action"
              required
              wrapperClassName="mb-4"
              helperMessage="Please write what your exact call-to-action is (e.g. “sign up to receive promotional text messages”)"
            />

            <TextArea
              validation={requiredValidation}
              name="sampleMessages"
              label="Message to Be Sent"
              required
              wrapperClassName="mb-4"
              inputClassName="min-h-[100px]"
              helperMessage="Please include the message you will send to recipients. Be sure to include opt-in and opt-out language (e.g. “REPLY STOP to end marketing”) or help language (“REPLY HELP to get assistance”)"
            />

            <div className="mb-4">
              <RadioInputGroup
                required
                validation={requiredValidation}
                label="Does HELP message contain opt-out instructions?"
                name="helpMessageContainsOptOutInstructions"
                options={yesNoDropdownItems}
              />
            </div>

            <div className="mb-4">
              <RadioInputGroup
                required
                validation={requiredValidation}
                label="Is STOP keyword supported as an opt-out method?"
                name="stopKeywordIsSupportedAsAnOptOutMethod"
                options={yesNoDropdownItems}
              />
            </div>

            <FormSelect
              required
              validation={requiredValidation}
              name="estimatedMonthlyMessageVolume"
              label="Estimated Monthly Message Volume"
              options={estimatedMonthlyMessageVolume}
              wrapperClassName="mb-4"
            />

            <div className="mb-4">
              <RadioInputGroup
                required
                validation={requiredValidation}
                label="Canadian Traffic"
                name="canadianTrafficMostly"
                options={yesNoUnsureDropdownItems}
                helperMessage="Is the majority of traffic expected to terminate to Canadian handsets?"
              />
            </div>

            <div className="mb-4">
              <RadioInputGroup
                required
                validation={requiredValidation}
                label="Is this program's intent or subject matter related to COVID-19?"
                name="covid19Related"
                options={yesNoUnsureDropdownItems}
              />
            </div>

            <div className="mb-8">
              <FormFileInput
                label="Supporting documentation"
                name="attachments[]"
                multiple
                required
                validation={requiredValidation}
                ref={fileInputRef}
                helperMessage="Supporting documentation like screenshots of opt-ins should be included. You can upload multiple files at once."
              />
            </div>

            <PrimaryButton
              type="submit"
              className="!w-[15rem] min-w-0 mt-10 mb-10"
              disabled={mutation.isLoading}
            >
              {mutation.isLoading ? <Loader small /> : "Submit"}
            </PrimaryButton>
          </form>
        </FormProvider>
      </div>
    </Layout>
  );
}

function cleanOutArrayData(arrayData: string[]) {
  return arrayData.map((val) => val.trim()).filter((val) => val);
}
