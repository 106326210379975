import { login } from "~/api/auth";
import { persist } from "zustand/middleware";
import { isJwtStillValid } from "~/utils/jwt";
import { User } from "~/types";
import { createStore } from "~/stores/store";
import { getUserInfo } from "~/api/portalLogin";
import { buildAuthorizationHeader } from "~/api/httpClient";

export type AuthenticatedState = {
  user: User;
  token: string;
  isAuthenticated: true;
  isImpersonated: boolean;
  original?: {
    user: User;
    token: string;
  };
};

type UnathenticatedState = {
  isAuthenticated: false;
};

type State = {
  state: AuthenticatedState | UnathenticatedState;
  error?: string;
  isWorking: boolean;
};

type Actions = {
  // regular login
  login: (email: string, password: string) => Promise<boolean>;
  // login via a token, usually based on redirects
  loginViaToken: (token: string) => Promise<boolean>;
  // admins can impersonate users, they select a user
  // to impersonate and "log in" as them
  impersonateUser: (token: string) => Promise<boolean>;
  // resets are called when logging out
  resetStore: () => void;
  // check to see if user logged in and if token is still valid, called on app start
  checkAuth: () => void;
  // after changing data on the user object we need to refresh the logged in user to keep everything in sync
  refreshUser: () => Promise<void>;
  isAdmin: () => boolean;
  // gets the current user's auth token
  getToken: () => string | undefined;
  // gets the original user's auth token,
  // original meaning, if the user is impersonating someone
  // it'll return the original token, not the impersonated one
  getOriginalToken: () => string | undefined;
};

type StoreState = State & Actions;

const initialState: State = {
  state: { isAuthenticated: false },
  isWorking: false,
  error: undefined,
};

export const useAuthStore = createStore<
  StoreState,
  [["zustand/persist", StoreState]]
>(
  persist(
    (set, get) => ({
      ...initialState,
      login: async (email, password) => {
        set({ isWorking: true, error: undefined });

        try {
          const response = await login(email, password);
          const { user: _user, token } = response;

          const user = await getUserInfo({
            headers: buildAuthorizationHeader(token),
          });

          const newState = {
            user,
            token,
            isAuthenticated: true,
            isImpersonated: false,
            original: undefined,
          };

          set({ state: newState, isWorking: false, error: undefined });

          return true;
        } catch (error) {
          console.error("Login error:", error);

          let errorMessage =
            (error as Error)?.message || "Login failed, please try again...";

          set({ isWorking: false, error: errorMessage });
          return false;
        }
      },
      loginViaToken: async (token: string) => {
        set({ isWorking: true, error: undefined });

        const isStillValid = isJwtStillValid(token);

        if (!isStillValid) {
          get().resetStore();
          return false;
        }

        try {
          const user = await getUserInfo({
            headers: buildAuthorizationHeader(token),
          });

          const newState = {
            user,
            token,
            isAuthenticated: true,
            isImpersonated: false,
            original: undefined,
          };

          set({ state: newState, isWorking: false, error: undefined });

          return true;
        } catch (error) {
          console.error("Login via token error:", error);

          let errorMessage = "Login failed, please try again...";
          // if (error instanceof NotFoundError) {
          //   errorMessage = "Invalid login credentials.";
          // }

          set({ isWorking: false, error: errorMessage });

          return false;
        }
      },
      impersonateUser: async (token: string) => {
        set({ isWorking: true, error: undefined });

        const isStillValid = isJwtStillValid(token);

        if (!isStillValid) {
          get().resetStore();
          return false;
        }

        try {
          const user = await getUserInfo({
            headers: buildAuthorizationHeader(token),
          });

          const state = get().state as AuthenticatedState;

          const original = {
            user: state.original?.user || state.user,
            token: state.original?.token || state.token,
          };

          const newState = {
            user,
            token,
            isAuthenticated: true,
            isImpersonated: true,
            original,
          };

          set({ state: newState, isWorking: false, error: undefined });

          return true;
        } catch (error) {
          console.error("Login via token error:", error);

          let errorMessage =
            "Login as impersonated user failed, please try again...";

          set({ isWorking: false, error: errorMessage });

          return false;
        }
      },
      // checks whether the token is expired and resets the auth state
      checkAuth: () => {
        const state = get().state;

        if (state.isAuthenticated === false) return;

        const token = state.token;
        const isStillValid = isJwtStillValid(token);

        if (!isStillValid) {
          get().resetStore();
        }
      },
      resetStore: () => set(initialState),
      refreshUser: async () => {
        const state = get().state;

        if (state.isAuthenticated === false) return;

        const token = state.token;

        const authHeaderConfig = {
          headers: buildAuthorizationHeader(token),
        };
        const user = await getUserInfo(authHeaderConfig);

        const newState = {
          ...state,
          user,
        };

        set({ state: newState, isWorking: false, error: undefined });
      },
      // returns true if logged in user is admin
      isAdmin: () => {
        const state = get().state;
        if (state.isAuthenticated === false) return false;
        return state.user.level === "admin";
      },
      getToken: () => {
        const state = get().state;
        return state.isAuthenticated ? state.token : undefined;
      },
      // ignores the impersonated user, if any,
      // and returns the user's original token
      getOriginalToken: () => {
        const state = get().state;

        if (state.isAuthenticated) {
          return state.original?.token || state.token;
        }

        return undefined;
      },
    }),
    {
      name: "customer_portal_auth_storage",
    }
  )
);
