import { useRef, useState } from "react";
import { Form } from "../../components/Form";
import { FormFileInput } from "../../components/Input";
import { PrimaryButton } from "../../components/PrimaryButton";
import { createTicket } from "../../api/ticket";
import { useNavigate } from "react-router-dom";
import { requiredValidation } from "../../services/validation";
import { Loader } from "../../components/Loader";
import { ticketTypeToTag } from "./ticket";
import { AuthenticatedState, useAuthStore } from "~/stores/authStore";

export function LocalIncomingBulkPortIn() {
  const user = useAuthStore(
    (store) => (store.state as AuthenticatedState).user
  );
  const [isWorking, setIsWorking] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const account = user.company.internal_id;
  const companyName = user.company.name;

  async function submitForm() {
    const subject = "Local Incoming bulk Port-In";
    const body = `
      <div>
        <b>Local Incoming bulk Port-In Request</b>
        Account: ${account} <br/>
        Company: ${companyName} <br/>
        <br/>
        <br/>
        Attached are both the Request and the LOA/COB files.
      </div>
    `;

    const formData = new FormData();

    // workaround for multiple attachments - FormData for some reason doesn't support a FileList object (which you get with <input type="file" multiple ... />)
    formData.delete("attachments[]");

    const files = (fileInputRef.current as any).files;
    [...files].forEach((file: File) => formData.append("attachments[]", file));

    formData.append("subject", subject);
    formData.append("body", body);
    formData.append("tags[]", "customer_portal");
    formData.append("tags[]", ticketTypeToTag("Local incoming bulk port-in"));

    setIsWorking(true);

    try {
      setError(null);
      await createTicket(formData);
      navigate("/support/ticket-created");
    } catch (error: any) {
      setError("An error occurred... please try again");
      setIsWorking(false);
    }
  }

  return (
    <div className="max-w-xl mt-6">
      <div>
        <div>
          Bulk load requests are for 50+ TNs that are not under the same
          address, carrier and/or BTN.
        </div>

        <div className="mt-6">
          To submit bulk load request please{" "}
          <a
            href="https://support.tsgglobal.com/hc/en-us/article_attachments/15718353511963/CUSTOMER_NAME_Bulk_Load_Port-In_Request-MM-DD-YYYY.xls"
            download
            className="underline"
            target="_blank"
          >
            click here
          </a>
          . Please save this file each time you are submitting a request.
        </div>

        <div className="mt-10">
          Also{" "}
          <a
            href="https://support.tsgglobal.com/hc/en-us/article_attachments/15718347138331/TSG_Global_LOA.pdf"
            download="TSG_Global_LOA.pdf"
            className="underline"
            target="_blank"
          >
            Download TSG Global LOA
          </a>{" "}
          file found here.
        </div>

        <div className="mt-10">
          Then upload both files through the "Browse..." input below.
        </div>

        <Form ref={formRef} onSubmit={submitForm} className="">
          <div className="flex flex-wrap mt-8 mb-4 max-w-3xl">
            <div className="w-full">
              <div className="">
                <FormFileInput
                  label="Upload LOA and COB Files here"
                  name="attachments"
                  multiple
                  validation={requiredValidation}
                  ref={fileInputRef}
                />
              </div>
              <div className="mt-1 text-sm">
                Please follow the proper naming convention:
                <br />
                CUSTOMER_NAME_Bulk_Load_Port-In_Request-MM-DD-YYYY.xls
              </div>
            </div>
          </div>

          <PrimaryButton
            type="submit"
            className="h-[42px] !w-fit flex align-center gap-2 mt-12 block ml-auto mr-auto"
            disabled={isWorking}
          >
            {isWorking ? <Loader small /> : "Create request"}
          </PrimaryButton>
        </Form>
      </div>
    </div>
  );
}
